<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM 兼容链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。体验更多元化的以太坊生态</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuEvmPc/evm_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM兼容链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goBscWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BSC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goHecoWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">HECO</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goFtmWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FTM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goPolygonWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Polygon</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goGnosisWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Gnosis</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 290px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 EVM 兼容链 Gnosis Chain</a>
        <van-image style="margin-top: 40px;"
                   src="img/pc/walletMenuEvmPc/gnosis-wallet_dt2.svg"></van-image>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Gnosis Chain 简介</a>
          <div style="margin-top: 14px;">
            <a>Gnosis Chain 是一条基于 POSDAO 共识机制开发的以太坊侧链，于 2018 年 10 月正式上线。Gnosis Chain 原名为 xDai Chain，后与基于以太坊的市场预测平台 Gnosis 合并，
              在<a style="color: #1B8FFF;cursor: pointer"
                 href="https://forum.gnosis.io/t/gip-16-gnosis-chain-xdai-gnosis-merge/1904">GIP16</a>提案通过后于 2021 年 12 月正式更名为 Gnosis Chain。</a>
          </div>
          <a style="margin-top: 10px;">Gnosis Chain 是 EVM 兼容链，以太坊开发者可以以极低的成本将智能合约迁移部署至 Gnosis Chain。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">工作原理</a>
          <a style="margin-top: 10px;">Gnosis Chain 采用独特的双代币模型：xDai 是与美元 1:1 锚定的稳定币，用于交易和支付手续费；STAKE 是 Gnosis Chain 上的治理代币（将逐渐被 Gnosis 的原生代币 GNO 取代），用于支持底层的 POSDAO 共识机制。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">稳定币 xDai</a>
          <a style="margin-top: 10px;">代币 xDai 是基于 MakerDAO 发行的 Dai 创建的稳定币，也是 Gnosis Chain 的原生代币，用于支付 Gnosis Chain 上的交易手续费。</a>
          <a style="margin-top: 10px;">当用户通过跨链桥将 Dai 转换为 xDai 时，Dai 会被锁定在以太坊的一个智能合约中，同时用户指定的 Gnosis Chain 上的对应地址会生成等值的 xDai 代币。</a>
          <a style="margin-top: 10px;">当用户通过跨链桥将 xDai 转换回 Dai 时，xDai 会被 Gnosis Chain 的智能合约销毁，同时以太坊智能合约中锁定的 Dai 会被返回至用户的以太坊钱包地址。</a>
          <a style="margin-top: 10px;">Gnosis Chain 目前支持两种跨链桥接方式：xDai 桥和 Omni 桥。</a>
          <a style="margin-top: 10px;">xDai 桥</a>
          <a style="margin-top: 10px;">xDai 桥是为 Gnosis Chain 的原生代币设计的跨链桥，支持用户：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">将 Dai 从以太坊发送至 Gnosis Chain 并转换为 xDai</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">将 xDai 从 Gnosis Chain 发送至以太坊并转换为 Dai</a>
          </div>
          <a style="margin-top: 10px;">Omni 桥</a>
          <div style="margin-top: 14px;">
            <a>Omni 桥支持用户在以太坊、Gnosis Chain、<a style="color: #1B8FFF;cursor: pointer"
                 href="https://forum.gnosis.io/t/gip-16-gnosis-chain-xdai-gnosis-merge/1904">BSC</a> 和 POA 链之间转移代币。</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">治理代币 STAKE</a>
          <a style="margin-top: 10px;">代币 STAKE 是 Gnosis Chain 的治理代币（将逐渐被 Gnosis 的原生代币 GNO 取代），持有 STAKE 的用户可以发起有关 Gnosis Chain 的提案并投票。同时，用户可以通过运行全节点，质押 STAKE 成为 Gnosis Chain 上的验证者参与区块生产和维护链上安全，并获得 STAKE 和 xDai 作为奖励。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">共识机制 POSDAO</a>
          <a style="margin-top: 10px;">POSDAO 是 Proof of Stake Decentralized Autonomous Organization（去中心化自治组织实现的权益证明）的简称。它采用通用的 BFT 共识协议来实现，旨在为 Gnosis Chain 提供一个去中心化、公平、节能的共识算法。该算法于 2020 年 4 月 1 日生效，并将持续至以太坊合并前一周。届时，开发团队将把 Gnosis Chain（GC）和 Gnosis Beacon Chain（GSC）合并，使 GC 成为 GSC 的一个分片。 </a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">双代币模型：稳定币 xDai 和治理代币 STAKE</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">EVM 兼容</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">手续费低</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">交易确认速度快</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Gnosis Chain 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包。</a>
          <a style="margin-top: 10px;">安全小贴士：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何安全下载 imToken</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何验证 imToken App 的真伪</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">切换节点进入 Gnosis Chain 钱包</a>
          <a style="margin-top: 10px;">进入 ETH 钱包的钱包首页，点击页面上方的 「Ethereum」，在弹窗页面选择 「Gnosis Chain」即可切换至 Gnosis Chain 钱包，进行转账收款等操作。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuEvmPc/gnosis-wallet_dt3.svg"></van-image>
          <a style="margin-top: 10px;">注：转账前请确保钱包中有充足的 xDAI 代币支付矿工费。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">切回 ETH 钱包</a>
          <a style="margin-top: 10px;">点击页面上方的「Gnosis Chain」，在弹窗页面中选择「Ethereum」即可。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">其他支持 Gnosis Chain 的钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">MetaMask</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">TokenPocket</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Coinbase Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Nifty Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Gnosis Beacon Chain</a>
          <a style="margin-top: 10px;">xDai Chain 和 Gnosis 合并后的新团队除了继续维护 Gnosis Chain（GC），还开发了另一条链 Gnosis Beacon Chain（GBC），用户可以通过 GNO 代币参与 GBC 质押和链上治理。</a>
          <a style="margin-top: 10px;">GBC 和 GC 的关系类似于目前的以太坊信标链和以太坊主网的关系。团队计划在以太坊合并发生前一周完成 GBC 和 GC 的合并，合并为 GBC（GC 成为其中的一个分片），为以太坊协议层升级提供了除测试网合并之外的真实线上环境。</a>
          <div style="margin-top: 14px;">
            <a>在 2022 年 5 月 31 日之前，STAKE 持有者可以通过兑换功能在以太坊和 Gnosis Chain 上将 STAKE 按照 1 STAKE = 0.0326292707 GNO 的固定比例兑换为 GNO。<a style="color: #1B8FFF;cursor: pointer"
                 href="https://www.xdaichain.com/for-stakers/stake-gno-swap">相关信息</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">EVM 兼容</a>
          <a style="margin-top: 10px;">EVM 是 Ethereum Virtual Machine 以太坊虚拟机的简称，EVM 之于以太坊，就像 CPU 之于计算机。EVM 允许在区块链上验证和执行代码，为代码在每台设备上以相同方式运行提供保障。</a>
          <a style="margin-top: 10px;">EVM 兼容指创造了一个类似 EVM 的代码执行环境，便于以太坊开发者将智能合约迁移至兼容链，而不必为其再从头开始编写智能合约的代码。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">STAKE 基本信息</a>
          <a style="margin-top: 10px;">发行时间：2020.04</a>
          <a style="margin-top: 10px;">共识机制：POSDAO</a>
          <div style="margin-top: 10px;">
            <a>发行 / 流通总量：<a href="https://coinmarketcap.com/currencies/xdai/ "
                 style="color: #1B8FFF;">https://coinmarketcap.com/currencies/xdai/ </a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">STAKE 价格</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://coinmarketcap.com/currencies/binance-coin/">CoinMarketCap: Cryptocurrency Market Capitalizations</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.blockchain.com/prices/BNB?timeSpan=1&scale=0&style=line">Blockchain.com</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.feixiaohao.co/currencies/binance-coin/">非小号｜专注数字货币行业大数据分析</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Gnosis Chain 区块浏览器</a>
          <a style="margin-top: 10px;">Gnosis Chain 区块浏览器是一个查询 Gnosis Chain 上区块、交易、代币、地址等信息的网站，实时同步更新 Gnosis Chain 所有节点信息。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://explorer.anyblock.tools/ethereum/poa/xdai/">AnyBlock Analytics</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://blockscout.com/xdai/mainnet/">Blockscout</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">已加入 Gnosis Chain 生态的团队</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">POAP</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Ankr</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Tornada Cash</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Chainlink</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">SushiSwap</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">PoolTogether</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Gnosis Chain 官网：<a style="color: #1B8FFF;"
                 href="https://www.xdaichain.com/">https://www.xdaichain.com/</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Gnosis Chain 论坛：<a style="color: #1B8FFF;"
                 href="https://forum.poa.network/c/xdai-chain/17">https://forum.poa.network/c/xdai-chain/17</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">POSDAO 白皮书：<a style="color: #1B8FFF;"
                 href="https://forum.poa.network/t/posdao-white-paper/2208">https://forum.poa.network/t/posdao-white-paper/2208</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Gnosis 白皮书：<a style="color: #1B8FFF;"
                 href="https://blockchainlab.com/pdf/gnosis_whitepaper.pdf">https://blockchainlab.com/pdf/gnosis_whitepaper.pdf</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">GIP16 提案：<a style="color: #1B8FFF;"
                 href="https://forum.gnosis.io/t/gip-16-gnosis-chain-xdai-gnosis-merge/1904">https://forum.gnosis.io/t/gip-16-gnosis-chain-xdai-gnosis-merge/1904</a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "gnosisWallet",
  methods: {
    goBscWallet () {
      this.$router.push({ path: '/bsc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goHecoWallet () {
      this.$router.push({ path: '/heco-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goFtmWallet () {
      this.$router.push({ path: '/ftm-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goPolygonWallet () {
      this.$router.push({ path: '/polygon-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goGnosisWallet () {
      this.$router.push({ path: '/gnosis-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>