<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex; flex-direction: column;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #3A414B;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">常见问题</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A404A;
      line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">ETH、TRX、BTC 等钱包常见问题</a>
      <div style="display: flex;align-items: center;margin-top: 30px;">
        <div style="display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 18px;color: #3A414B;
          line-height: 21px;text-align: left;font-style: normal;text-transform: none;">TRX钱包</a>
          <div style="margin-top: 17px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">
            <a>TRX 钱包转账失败或提示没有带宽怎么办 ？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>如何激活TRC钱包地址？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>为什么无法在TRX钱包首页添加和隐藏代币？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>如何获得宽带与能量？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>为什么我的宽带一直都是0？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>冻结TRX获取了宽带和能量，为什么还会转账失败？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
          </div>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 100px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 18px;color: #3A414B;
          line-height: 21px;text-align: left;font-style: normal;text-transform: none;">热点问题</a>
          <div style="margin-top: 17px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">
            <a>如何在imTkoen获得测试币？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>如何从火币提币到钱包地址？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>从交易所提币到钱包，提示“地址无效”怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>导入助记词，提示不正确或无效怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>转账成功但对方没收到怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>为什么有些代币没有价格？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
          </div>
        </div>
      </div>
      <div style="display: flex;margin-top: 75px;">
        <div style="display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 18px;color: #3A414B;
          line-height: 21px;text-align: left;font-style: normal;text-transform: none;">ETH 钱包</a>
          <div style="margin-top: 17px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">
            <a>有收款通知但币不显示怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>导入钱包后，币不见了怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>为什么无法在TRX钱包首页添加和隐藏代币？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>转账显示「未发送」怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>转账显示「失败」怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>转账提示「ETH 余额不足……」怎么办？</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>交易未发送原因</a>
            <hr style="width: 416px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
          </div>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 100px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 18px;color: #3A414B;
          line-height: 21px;text-align: left;font-style: normal;text-transform: none;">FIL / ATOM 钱包</a>
          <div style="margin-top: 17px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">
            <a>FIL 钱包转账显示「失败」怎么办？</a>
            <hr style="width: 450px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>FIL 钱包转账显示「等待确认」怎么办？</a>
            <hr style="width: 450px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>从交易所转 ATOM 到 imToken，备注（MEMO）填写什么？</a>
            <hr style="width: 450px;height: 1px;border: 1px solid #E3E2E1;margin-top: 15px;margin-bottom: 15px;">
            <a>为什么 ATOM 钱包无法转账？</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 65px;">
    <div style="width: 1100px;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
        </div>
        <van-button type="primary"
                    color="#468BE0"
                    style="width: 137px;height: 47px;margin-top: 20px;"
                    to="download">
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
        </van-button>
      </div>
      <div style="margin-right: 10px">
        <van-image src="img/pc/home/pc_dt4.svg"></van-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "faqPcIndex",
}
</script>

<style>
</style>