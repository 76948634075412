import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import {
  Button,
  Row,
  Col,
  Image,
  Divider,
  Swipe,
  SwipeItem,
  Tag,
  Rate,
  Progress,
  Icon,
  Collapse,
  CollapseItem,
} from 'vant'
import 'vant/lib/index'

const app = createApp(App)

app.use(Button)
app.use(Row).use(Col)
app.use(Image)
app.use(Divider)
app.use(Swipe).use(SwipeItem)
app.use(Tag)
app.use(Rate)
app.use(Progress)
app.use(Icon)
app.use(router)
app.use(Collapse).use(CollapseItem)
app.mount('#app')
