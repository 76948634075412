<template>
  <div style="max-width: 100%;display: flex; justify-content: center; flex-direction: column;
  height: 197px;background: linear-gradient( 180deg, #038DC7 0%, #0870BA 100%);align-items: center;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 28px;color: #FFFFFF;line-height: 33px;
    text-align: left;font-style: normal;text-transform: none;">我们能帮您什么 ？</a>
    <div style="margin-top: 28px;width: 610px;background: #FFFFFF;height: 39px;display: flex; align-items: center;border-radius: 40px 40px 40px 40px;">
        <van-image style="padding: 0 10px;" src="img/support/supportss.svg"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #7F7E7E;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">搜索</a>
    </div>
  </div>
  <div style="width: 100%;display: flex; justify-content: center; flex-direction: column;align-items: center;">
    <div style="width: 1159px;padding: 43px 0px;">
      <div style="display: flex;align-items: center;">
        <div style="width: 366px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">新手指南</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">快速入门/初阶教程/视频教程</a>
        </div>
        <div style="width: 366px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">常见问题</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">ETH、TRX、BTC等钱包常见问题</a>
        </div>
        <div style="width: 366px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">imToken Card</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">产品介绍/预注册/常见问题</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 30px;">
        <div style="width: 366px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">Layer2&EVM</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">概念介绍/使用教程</a>
        </div>
        <div style="width: 366px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">以太坊质押</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">imToken以太坊质押/非托管/验证器</a>
        </div>
        <div style="width: 366px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">区块链知识</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">名词解释/区块浏览器/DApp/NFT</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 30px;">
        <div style="width: 564px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">质押挖矿</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">质押挖矿/持比生息/PoS</a>
        </div>
        <div style="width: 564px;height: 99px;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;
        display: flex; justify-content: center; flex-direction: column;align-items: center;margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">公告中心</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
        line-height: 19px;text-align: left;font-style: normal;text-transform: none;">最新动态/官方发声/近期热点</a>
        </div>
      </div>
      <div style="margin-top: 92px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 17px;color: #3A414B;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;">被推荐的文章</a>
      </div>
      <div style="display: flex;align-items: center;margin-top: 30px;">
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">如何确保代币安全？</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
        <div style="margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">如何安全下载imToken</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
        <div style="margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">想要低成本转账Tron USDT？别错过这份能量指南！</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 16px;">
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">imToken年度报告| 致2023的每一次连接</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
        <div style="margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">imToken推出信用卡服务，预注册已开启！</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
        <div style="margin-left: 30px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">探索无界互联：imToken的强大连接能力</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 16px;">
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">imToken2.14.0，轻松连接更多插件&硬件钱包，交</a>
            <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
            line-height: 16px;text-align: left;font-style: normal;text-transform: none;">易更安全便捷</a>
          <hr style="width: 335px;height: 1px;border: 1px solid #E3E2E1;margin-left: 0px;margin-top: 16px;">
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 60px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #3A414B;
        line-height: 28px;text-align: left;font-style: normal;text-transform: none;">最近的活动</a>
      </div>
      <div style="margin-top: 31px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 17px;color: #3A414B;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;">通告</a>
      </div>
      <div style="margin-top: 22px;display: flex;align-items: center;justify-content: space-between;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">imToken的多元化账户管理</a>
        <div style="display: flex;align-items: center;margin-right: 0px;font-family: PingFang HK, PingFang HK;font-weight: 400;
        font-size: 14px;color: #3A414A;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">
          <a>文章创建于4天前</a>
        <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
        </div>
      </div>
      <hr style="width: 1161px;height: 0px;border: 1px solid #E3E2E1;">
      <div style="margin-top: 31px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 17px;color: #3A414B;
        line-height: 20px;text-align: left;font-style: normal;text-transform: none;">官方博客</a>
      </div>
      <div style="margin-top: 22px;display: flex;align-items: center;justify-content: space-between;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">质押平台走入分水岭：下一步是去中心化？</a>
        <div style="display: flex;align-items: center;margin-right: 0px;font-family: PingFang HK, PingFang HK;font-weight: 400;
        font-size: 14px;color: #3A414A;line-height: 16px;text-align: left;font-style: normal;text-transform: none;">
          <a>文章创建于 11 天前</a>
        <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
        </div>
      </div>
      <hr style="width: 1161px;height: 0px;border: 1px solid #E3E2E1;">
    </div>
  </div>
</template>

<script>
export default {
  name: "supportPcIndex",
}
</script>

<style>

</style>