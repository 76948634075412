<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 100%;height: 430px;background: linear-gradient( 88deg, #1885CF 0%, #3EA6DC 100%);
    display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">imToken API文档</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #FFFFFF;
        line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-top: 50px;">欢迎来到imToken开发者文档</a>
      </div>
      <van-image style="margin-left: 200px;"
                 src="img/pc/docs/docs_dt.png"></van-image>
    </div>
    <div style="display: flex; align-items: center;margin-top: 60px;">
      <a href="https://imtoken.gitbook.io/developers/v/zh/products/webview">
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;width: 500px;
        height: 237px;display: flex;flex-direction: column;padding: 45px;">
          <van-image style="align-self: flex-start;width: auto;height: auto;" src="img/pc/docs/docs_xt1.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">DApp</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 22px;">帮助DApp开发者接入imToken DApp</a>
        </div>
      </a>
      <a href="https://docs.token.im/tokenlon-onboarding/">
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;width: 500px;
        height: 237px;display: flex;flex-direction: column;padding: 45px;margin-left: 35px;">
          <van-image style="align-self: flex-start;width: auto;height: auto;" src="img/pc/docs/docs_xt2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">Tokenlon-Onboarding</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 22px;">Tokenlon 做市商对接参考文档</a>
        </div>
      </a>
    </div>
    <div style="display: flex; align-items: center;margin-top: 60px;margin-top: 35px;">
      <a href="https://docs.token.im/tokenlon-mmsk/">
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;width: 500px;
        height: 237px;display: flex;flex-direction: column;padding: 45px;">
          <van-image style="align-self: flex-start;width: auto;height: auto;" src="img/pc/docs/docs_xt2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">Tokenlon-MMSK</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 22px;">一个连接 imToken Server 和 做市商服务的 中转工具，用于降低两端接口对接成本</a>
        </div>
      </a>
      <a href="https://docs.token.im/tokenlon-mmproxy/">
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;width: 500px;
        height: 237px;display: flex;flex-direction: column;padding: 45px;margin-left: 35px;">
          <van-image style="align-self: flex-start;width: auto;height: auto;" src="img/pc/docs/docs_xt2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">Tokenlon-MMProxy</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 22px;">做市商的链上代币储备库，一个使用 Solidity 编写的以太坊智能合约</a>
        </div>
      </a>
    </div>
    <div style="display: flex; align-items: center;margin-top: 60px;margin-top: 35px;">
      <a href="https://docs.token.im/tokenlon-jssdk/">
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;width: 500px;
        height: 237px;display: flex;flex-direction: column;padding: 45px;">
          <van-image style="align-self: flex-start;width: auto;height: auto;" src="img/pc/docs/docs_xt2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">Tokenlon-JSSDK</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 22px;">对接 Tokenlon Trading API的 JavaScript 工具</a>
        </div>
      </a>
      <a href="https://docs.token.im/tokenlon-open-api/">
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;width: 500px;
        height: 237px;display: flex;flex-direction: column;padding: 45px;margin-left: 35px;">
          <van-image style="align-self: flex-start;width: auto;height: auto;" src="img/pc/docs/docs_xt2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 20px;">Tokenlon-Open-API</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
          line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 22px;">Tokenlon 开放的接口，支持 jsonrpc 和 restful 请求</a>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "docsPcIndex",
}
</script>

<style>
</style>