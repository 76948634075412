<template>
  <div style="max-width: 100%;">
    <div style="width: 90%;height: 480px;margin: auto;">
      <div style="margin-top: 130px;width: 412;height: 292px;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">质押</a>
        <div style="margin-top: 10px;"><a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #787A8B;line-height: 30px;text-align: center;font-style: normal;text-transform: none;">一键委托代币，享有链上协议激励</a></div>
        <div style="margin-top: 24px;"><a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;
        color: #777B8B;line-height: 15px;text-align: left;font-style: normal;text-transform: none;">使用imToken委托 ></a></div>
        <van-image style="margin-top: 50px;"
                   src="img/staking/stakingdt.png"></van-image>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 430px;background: #5E78E5;margin-top: 44px;display: flex; flex-direction: column; align-items: center;">
    <div style="margin: 50px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #FFFFFF;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;">现已支持</a>
    </div>
    <div style="height: 273px;width: 239px;">
      <van-swipe :autoplay="3000"
                 :height="273"
                 :width="239">
        <van-swipe-item><van-image style="width: 239px;height: 273px;"
                     src="img/staking/stakinglb1.png"></van-image></van-swipe-item>
        <van-swipe-item><van-image style="width: 239px;height: 273px;"
                     src="img/staking/stakinglb2.png"></van-image></van-swipe-item>
        <van-swipe-item><van-image style="width: 239px;height: 273px;"
                     src="img/staking/stakinglb3.png"></van-image></van-swipe-item>
        <van-swipe-item><van-image style="width: 239px;height: 273px;"
                     src="img/staking/stakinglb4.png"></van-image></van-swipe-item>
        <van-swipe-item><van-image style="width: 239px;height: 273px;"
                     src="img/staking/stakinglb5.png"></van-image></van-swipe-item>
      </van-swipe>
    </div>
  </div>
  <div style="width: 100%;display: flex; justify-content: center;">
    <div style="width: 90%;height: 780px;margin: 70px 0;display: flex; flex-direction: column;justify-content: center; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Staking资料</a>
      <div style="margin-top: 39px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">10,426,275</a>
      </div>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken累计委托ATOMs</a>
      <van-image style="width: 218px;height: 218px;margin-top: 32px;"
                 src="img/staking/stakingdt2.png"></van-image>
      <div style="margin-top: 56px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">0.35%</a>
      </div>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken累计启用Cosmos钱包全网占比</a>
      <van-image style="width: 218px;height: 218px;margin-top: 32px;"
                 src="img/staking/stakingdt3.png"></van-image>
    </div>
  </div>
  <div style="width: 100%;background: #F6F8FC;display: flex; justify-content: center;">
    <div style="width: 88%;height: 370px;margin-top: 55px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">如何使用imToken参与Staking</a>
      <div style="margin-top: 16px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 25px;
        text-align: left;font-style: normal;text-transform: none;">Staking 是PoS 共识机制的重要组成元件，允许用户透过质押代币参与挖矿与投票，并获得链上激励。</a>
      </div>
      <div style="height: 30px;display: flex; align-items: center; margin-top: 49px;">
        <van-image style="width: 28px;height: 28px;"
                   src="img/staking/staking_db1.png"></van-image>
        <div style="margin-left: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">兑换代币</a>
        </div>
      </div>
      <div style="height: 30px;display: flex; align-items: center; margin-top: 40px;">
        <van-image style="width: 28px;height: 28px;"
                   src="img/staking/staking_db2.png"></van-image>
        <div style="margin-left: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">快速委托</a>
        </div>
      </div>
      <div style="height: 30px;display: flex; align-items: center; margin-top: 40px;">
        <van-image style="width: 28px;height: 28px;"
                   src="img/staking/staking_db3.png"></van-image>
        <div style="margin-left: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">获取收益</a>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; justify-content: center;">
    <div style="width: 88%;margin-top: 49px;height: 400px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">为什么选择imToken</a>
      <div style="display: flex;align-items: flex-start;margin-top: 53px;">
        <van-image src="img/staking/staking_db4.png"></van-image>
        <div style="margin-left: 12px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">方便</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">一键质押，轻松透过imToken 查看你的质押状态</a>
        </div>
      </div>
      <div style="display: flex;align-items: flex-start;margin-top: 21px;">
        <van-image src="img/staking/staking_db5.png"></van-image>
        <div style="margin-left: 12px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">安全</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">支持硬体钱包imKey，为资产安全保驾护航</a>
        </div>
      </div>
      <div style="display: flex;align-items: flex-start;margin-top: 21px;">
        <van-image src="img/staking/staking_db6.png"></van-image>
        <div style="margin-left: 12px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">更好的Staking</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">参与公链治理，为社区发展添砖加瓦</a>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 398px;background: #F6F8FC;display: flex; flex-direction: column; align-items: center;">
    <div style="margin-top: 57px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">合作伙伴</a>
    </div>
    <van-image style="margin-top: 35px;"
               src="img/staking/staking_db7.png"></van-image>
    <van-image style="margin-top: 35px;"
               src="img/staking/staking_db8.png"></van-image>
  </div>
</template>

<script>
export default {
  name: "stakingIndex",
}
</script>

<style>
</style>