<template>
  <div style="width: 100%;display: flex; justify-content: center; align-items: center;">
    <div style="width: 1100px;margin-top: 30px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;">博客</a>
      <div style="display: flex;align-items: center;margin-top: 42px;">
        <div style="margin-top: 15px;height: 420px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
    display: flex; flex-direction: column;align-items: center;width: 345px;">
          <van-image src="img/blog/blog_dt1.png"></van-image>
          <div style="width: 345px;padding: 20px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #1A1A1A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">imToken年度报告|致2023的每一次连接</a>
            <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #1A1A1A;line-height: 22px;text-align: left;
          font-style: normal;text-transform: none;">
              2023，加密市场瞬息万变，新老叙事交织不断。imToken 始终希望在混乱中保持清醒，喧嚣中保持初心：在保证安全的同时，交付经得起考验的、用户友好、开发者友好的产品。秉持这一理念，...</p>
            <p style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #787A8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">2024-04-07</p>
          </div>
        </div>
        <div style="margin-top: 15px;height: 420px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
    display: flex; flex-direction: column;align-items: center;width: 345px;margin-left: 26px;">
          <van-image src="img/blog/blog_dt2.png"></van-image>
          <div style="width: 345px;padding: 20px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #1A1A1A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">imToken 推出信用卡服务，预注册已开启！</a>
            <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #1A1A1A;line-height: 22px;text-align: left;
          font-style: normal;text-transform: none;">
              imToken 即将推出一张基于链上信用发行的信用卡——imToken Card，旨在无缝连接链上数据和日常支付。现在，imToken Card 预注册已开启，我们诚邀你成为首批体验者，享受无需预存的信用额度和低手续费，...</p>
            <p style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #787A8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">2024-03-25</p>
          </div>
        </div>
        <div style="margin-top: 15px;height: 420px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
    display: flex; flex-direction: column;align-items: center;width: 345px;margin-left: 26px;">
          <van-image src="img/blog/blog_dt3.png"></van-image>
          <div style="width: 345px;padding: 20px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #1A1A1A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">探索无界互联：imToken 的强大连接能力</a>
            <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #1A1A1A;line-height: 22px;text-align: left;
          font-style: normal;text-transform: none;">
              作为一款领先的多链钱包，imToken 不仅提供安全可靠的非托管钱包服务，更通过其强大的连接功能，让用户能够在同一个平台上畅游各类去中心化应用（DApp）并轻松连接其他类型的钱包。多网络、...</p>
            <p style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #787A8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">2024-03-26</p>
          </div>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 40px;">
        <div style="margin-top: 15px;height: 420px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
    display: flex; flex-direction: column;align-items: center;width: 345px;">
          <van-image src="img/blog/blog_dt4.png"></van-image>
          <div style="width: 345px;padding: 20px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #1A1A1A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">imToken 2.14.0，轻松连接更多插件&硬件钱包，交易更安全便捷</a>
            <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #1A1A1A;line-height: 22px;text-align: left;
          font-style: normal;text-transform: none;">
              imToken 2.14.0 引入 ERC-4527 协议，支持通过二维码连接插件钱包或硬件钱包，实现更安全便捷的交易体验。同时，imToken 内置 Swap 功能新增 Polygon 网络兑换，为你提供了更丰富的数字代币兑换体验。</p>
            <p style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #787A8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">2024-04-07</p>
          </div>
        </div>
        <div style="margin-top: 15px;height: 420px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
    display: flex; flex-direction: column;align-items: center;width: 345px;margin-left: 26px;">
          <van-image src="img/blog/blog_dt5.png"></van-image>
          <div style="width: 345px;padding: 20px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #1A1A1A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">质押平台走入分水岭：下一步是去中心化？</a>
            <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #1A1A1A;line-height: 22px;text-align: left;
          font-style: normal;text-transform: none;">
              质押平台（Staking Platform）是为用户提供参与数字资产质押的入口和渠道。自以太坊从 PoW 共识机制升级为 PoS 共识机制后，质押平台也收获了一波新的关注度，并吸引了大量新用户的参与。</p>
            <p style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #787A8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">2024-03-25</p>
          </div>
        </div>
        <div style="margin-top: 15px;height: 420px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
    display: flex; flex-direction: column;align-items: center;width: 345px;margin-left: 26px;">
          <van-image src="img/blog/blog_dt6.png"></van-image>
          <div style="width: 345px;padding: 20px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 20px;color: #1A1A1A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">TVL已超百亿美元！以太坊再质押赛道会成为2024年最强叙事吗？</a>
            <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #1A1A1A;line-height: 22px;text-align: left;
          font-style: normal;text-transform: none;">
              以太坊质押（Staking）是实现以太坊共识机制升级和网络安全性提升的一种重要机制，还能够在一定程度上促进社区成员积极参与网络治理和决策。通过质押机制，以太坊实现了对 ETH 的市场调节作用...</p>
            <p style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 10px;color: #787A8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">2024-03-26</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blogPcIndex",
}
</script>

<style>
</style>