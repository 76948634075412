<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1100px;margin-top: 50px;height: 438px;background: #F3FAFC;;border-radius: 24px 24px 24px 24px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">探索 DApp 从这里开始</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">DApp 是一种基于智能合约的去中心化应用</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;">程序 (Decentralized App)</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #777B8B;
        line-height: 15px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">了解更多 ></a>
      </div>
      <van-image style="margin-top: 35px;margin-left: 125px;"
                 src="img/pc/dapp/dapp_dt.png"></van-image>
    </div>
    <div style="margin-top: 50px;background: #0293C3;height: 542px;width: 100%;display: flex; justify-content: center; align-items: center;">
      <van-image style="margin-top: 120px;"
                 src="img/pc/dapp/dapp_dt2.png"></van-image>
      <div style="display: flex;flex-direction: column;margin-left: 216px;margin-top: 120px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">开放的DApp浏览器</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #94C8E2;
        line-height: 42px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">支援 ETH, EOS, TRON DApp</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #94C8E2;
        line-height: 42px;text-align: left;font-style: normal;text-transform: none;">输入DApp网址可体验任意DApp</a>
      </div>
    </div>
    <div style="width: 100%;height: 453px;background: #F6F8FB;display: flex; justify-content: center; align-items: center;flex-direction: column;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">第三方合作 DApps</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
      line-height: 25px;text-align: center;font-style: normal;text-transform: none;margin-top: 10px;">imToken 已上架 150+ 优质 DApp</a>
      <div style="width: 1100px;margin-top: 70px;display: flex; align-items: center; justify-content: center;">
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb1.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">Maker</a>
        </div>
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb2.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">Compound</a>
        </div>
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb3.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">HyperDragons</a>
        </div>
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb4.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">HyperSnakes</a>
        </div>
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb5.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">LocalCryptos</a>
        </div>
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb6.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">CoinSwitch</a>
        </div>
        <div style="width: 187.5px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <van-image src="img/pc/dapp/dapp_tb7.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">Gods Unchained</a>
        </div>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 50px;">联系我们</a>
    <div style="display: flex; align-items: center;justify-content: center;margin-top: 50px;">
      <div style="width: 311px;display: flex; align-items: center; flex-direction: column;">
        <van-image src="img/pc/dapp/dapp_xt1.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 34px;">整合DApp</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
        line-height: 16px; text-align: left;font-style: normal;text-transform: none;">根据文件开始建构你的应用吧</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 23px;">查看文件 ></a>
      </div>
      <div style="width: 311px;display: flex; align-items: center; flex-direction: column;margin-left: 50px;">
        <van-image src="img/pc/dapp/dapp_xt2.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 34px;">上线DApp</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
        line-height: 16px; text-align: left;font-style: normal;text-transform: none;">提交表单进行申请吧</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 23px;">填写表单 ></a>
      </div>
      <div style="width: 311px;display: flex; align-items: center; flex-direction: column;margin-left: 50px;">
        <van-image src="img/pc/dapp/dapp_xt3.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 34px;">商业合作</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
        line-height: 16px; text-align: left;font-style: normal;text-transform: none;">欢迎透过信箱与我们取得联系</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
        line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 23px;">bd@token.im ></a>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 50px;">
        <div style="width: 1150px;display: flex;align-items: center;justify-content: space-between;">
          <div>
            <div>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
            </div>
            <van-button type="primary" color="#468BE0" style="width: 137px;height: 47px;margin-top: 20px;" to="download">
                <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
            </van-button>
          </div>
          <div style="margin-right: 10px">
            <van-image src="img/pc/home/pc_dt4.svg"></van-image>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  name: "dappPcIndex",
}
</script>

<style>
</style>