<template>
  <div style="max-width: 100%;">
    <div style="width: 90%;height: 421px;margin: auto;">
      <div style="margin-top: 150px;width: 412;height: 292px;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #000000;line-height: 28px;
        text-align: left;font-style: normal;text-transform: none;">多链多网络</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
        text-align: left;font-style: normal;text-transform: none;margin-top: 5px;">安全使用你的链上代币</a>
        <van-image style="margin-top: 39px;"
                   src="img/wallet/walletdt.png"></van-image>
      </div>
    </div>
  </div>
  <hr style="border-top: 1px solid #F7F5F5;">
  <div style="padding: 0 20px;">
    <br>
    <br>
    <br>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 25px;color: #181D1E;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;">多链多网络</a>
    <br>
    <br>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
    line-height: 27px;text-align: left;font-style: normal;text-transform: none;">轻松访问BTC、ETH、BNB、TRX、DOT、MATIC、
      LTC、BCH、ATOM、OP、FIL、ARB、EOS、KSM、XTZ、CKB</a>
  </div>
  <div style="width: calc(100% - 40px); margin: 44px auto;margin-top: 44px;height: 1010px;box-shadow: 0px 8px 26px 0px rgba(0,0,0,0.09);border-radius: 8px 8px 8px 8px;
  display: flex; justify-content: center; flex-direction: column;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_ytf.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">以太坊</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援所有以太坊代币(ERC20,ERC721代币)</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_btb.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">比特币</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援USDT OMNI，以及HD钱包和隔离见证</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_eos.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">EOS</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援EOS 代币、资源管理、以及投票</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_yz.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">原子</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援ATOM 代币，以及质押挖矿和投票</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_trx.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">TRX</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援TRX, TRC10, TRC20 代币，以及资源管理</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_ltc.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">LTC</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援LTC, 以及转账收款</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_sw.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">生物安全信息交换所</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援BCH，以及转帐收款</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_ckb.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">CKB</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支援CKB，以及Nervos DAO（即将上线）</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_d.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">点</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支持DOT 代币、质押和投票</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_ksm.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">凯斯曼</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支持 KSM 代币、质押和投票</a>
      </div>
    </div>
    <hr style="margin-top:20px;width: 100%;border-top: 1px solid #F3F6FB;">
    <div style="padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_z.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">在</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支持发送/接收FIL</a>
      </div>
    </div>
    <div style="margin-top:20px;padding: 0 15px;;display: flex; align-items: center; ">
      <van-image src="img/wallet/wallet_xtz.png"></van-image>
      <div style="margin-left: 13px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">XTZ</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
        text-align: center;font-style: normal;text-transform: none;">支持XTZ代币、质押和投票</a>
      </div>
    </div>
  </div>
  <div style="padding: 0 20px;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 24px;
  text-align: left;font-style: normal;text-transform: none;">支援主流稳定币USDT, USDC, DAI, PAX, TUSD, USDX</a>
  </div>
  <div style="margin-top:50px;padding: 0 20px;display: flex; justify-content: center; flex-direction: column;height: 744px;">
    <div style="display: flex; align-items: center; justify-content: center;">
      <div style="width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_tdb.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">泰达币</p>
      </div>
      <div style="margin-left: 16px;width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_mgny.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">美国农业部</p>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;margin-top: 16px;">
      <div style="width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_kd.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">快点</p>
      </div>
      <div style="margin-left: 16px;width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_usdp.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">USDP</p>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;margin-top: 16px;">
      <div style="width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_tusd.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">TUSD</p>
      </div>
      <div style="margin-left: 16px;width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_myzs.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">美元指数</p>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;margin-top: 16px;">
      <div style="width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_bszz.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">巴士总站</p>
      </div>
      <div style="margin-left: 16px;width: 132px;height: 161px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;
      display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <van-image src="img/wallet/wallet_hsd.png"></van-image>
        <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
        color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">哈士德</p>
      </div>
    </div>
  </div>
  <div style="padding: 0 20px;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 24px;
    text-align: left;font-style: normal;text-transform: none;">不止当前，我们仍在探索更多...</a>
  </div>
  <div style="margin-top: 69px;background: #F6F8FB;height: 469px;display: flex; justify-content: center; flex-direction: column;padding: 0 20px;">
    <div style="display: flex; align-items: center; ">
      <p style="margin-left: 10px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;line-height: 38px;
    text-align: center;font-style: normal;text-transform: none;">安全使用</p>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 32px;height: 32px;"
                 src="img/wallet/wallet_db1.png"></van-image>
      <div style="margin-top: -10px;margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">用户安全至上</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">建构全方位风险控制系统</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">强化用户钱包的安全性</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 32px;height: 32px;"
                 src="img/wallet/wallet_db2.png"></van-image>
      <div style="margin-top: -10px;margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">离线签名</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">支援硬体钱包& 冷钱包</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">避免私钥触网，降低被盗风险</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 32px;height: 32px;"
                 src="img/wallet/wallet_db3.png"></van-image>
      <div style="margin-top: -10px;margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">第三方安全审计</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">与慢雾、PeckShield、Cure53、知道创宇等知名安</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">全公司合作</a>
      </div>
    </div>
  </div>
  <div style="margin-top: 60px;display: flex; justify-content: center; flex-direction: column;padding: 0 20px;">
    <div style="display: flex; align-items: center; ">
      <p style="margin-left: 10px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;line-height: 38px;
    text-align: center;font-style: normal;text-transform: none;">轻松转账</p>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 32px;height: 32px;"
                 src="img/wallet/wallet_db4.png"></van-image>
      <div style="margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">生物辨识快速验证</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">享受更安全方便的转帐体验</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 32px;height: 32px;"
                 src="img/wallet/wallet_db5.png"></van-image>
      <div style="margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">一键转账加速</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">自动计算最优惠的矿工费</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 32px;height: 32px;"
                 src="img/wallet/wallet_db6.png"></van-image>
      <div style="margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">多链域名</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">支持.eth、.bit等多链域名</a>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
</template>

<script>
export default {
  name: "walletIndex",
}
</script>

<style>
</style>