<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">公链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。多链多网络，访问你的链上代币</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenupc/public_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">公链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goEthereumWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">ETH</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goBitcoinWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BTC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goEosWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">EOS</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goCosmosWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">ATOM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goTrxWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">TRX</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goLtcWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt6.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">LTC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goBchWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt7.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BCH</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goCkbWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt8.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">CKB</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goPolkadotWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt9.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">DOT</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="gokusamaWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt10.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">KSM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer"
               @click="goFilWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt11.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FIL</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goXtzWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt12.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">XTZ</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 620px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">ETH（Ethereum）钱包</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">入门</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊简介</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;margin-top: 15px;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a>以太坊是一个为去中心化应用（DApp）而生的全球开源平台。</a>
          <div style="margin-top: 14px;">
            <a>以太坊主网于 2015 年上线，是世界领先的可编程区块链。和其它区块链一样，以太坊也拥有原生加密货币，叫作 Ether (ETH)。 ETH 是一种数字货币，
              和比特币有许多相同的功能。 它是一种纯数字货币，可以即时发送给世界上任何地方的任何人。 ETH 的供应不受任何政府或组织控制，它是去中心化且具稀缺性的。
              全世界的人们都在使用 ETH 进行支付，或将其作为价值存储和抵押品。<a style="color: #1B8FFF;cursor: pointer"
                 href="https://ethereum.org/">了解更多</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">以太坊钱包</a>
          <a style="margin-top: 9px;">钱包是一种应用程序，可以便捷地保存和传送 ETH，同时可以通过钱包与以太坊上构建的应用程序进行交互。</a>
          <a style="margin-top: 9px;">🔸 钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">
              <a style="color: #1B8FFF;cursor: pointer"
                 @click="goToHome">移动端钱包 imToken</a> - 为全球千万用户提供非托管钱包服务</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">
              <a style="color: #1B8FFF;cursor: pointer"
                 href="https://imkey.im/">硬件钱包 imKey</a> - 安全好用，从此告别代币丢失</a>
          </div>
          <a style="margin-top: 9px;">🔸 创建钱包之前，请学习如何备份钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">什么是助记词？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">什么是明文私钥？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何备份 ETH 钱包？</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">以太坊买卖</a>
          <a style="margin-top: 9px;">获取 ETH 最简单的方式就是购买。市面上有许多可以购买 ETH 的数字货币交易所，但用户需要根据所处地址和付款方式选择合适的交易所。</a>
          <div style="margin-top: 9px;">
            <a>在购买 ETH 之前，尝试在<a style="color: #1B8FFF;cursor: pointer"
                 href="https://www.zhihu.com/question/267270670">这里</a>了解购买方式 & 风险等信息，便于你更安全快捷的完成购买。</a>
          </div>
          <a style="margin-top: 9px;">🔸 支持购买 ETH 的交易所</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Coinbase</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Binance</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Huobi</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">...</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">使用</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊交易</a>
          <a style="margin-top: 9px;">你也许希望通过代币兑换获得 ETH。市面上大多交易所都支持 ETH 交易，你可以根据需要选择去中心化交易所或中心化交易所。</a>
          <div style="margin-top: 9px;"><a>🔸 去中心化交易所 <a style="color: #1B8FFF;cursor: pointer"
                 href="https://tokenlon.im/">Tokenlon</a></a></div>
          <a style="margin-top: 9px;">Tokenlon 是基于 0x 协议的去中心化交易所，旨在为用户提供「速度快」「价格优」「币种多」的交易体验。交易代币完全由用户掌握，
            无需充值、提现，即可借助 Tokenlon 快速完成币币兑换。</a>
          <div style="margin-top: 9px;"><a>🔸 中心化交易所</a></div>
          <a style="margin-top: 9px;">由交易所托管用户代币 ，提供数字货币流动性供应，具有学习成本低、撮合效率高等特点。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Binance</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Huobi</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">...</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊转账</a>
          <a style="margin-top: 9px;">以太坊转账与中心化世界的银行转账有很大区别，有时会遇到「转账失败」或「转错地址」等情况，这时你需要了解以太坊转账机制以及转账会遇到的情况。</a>
          <div style="margin-top: 9px;"><a>🔸 以太坊转账机制</a></div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">以太坊交易的生命周期</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">以太坊中的账户、交易、Gas 和区块 Gas Limit</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">什么是以太坊上的矿工费 ？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">理解ERC-20 token合约</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">
              什么是收藏（ERC-721 Token）？</a>
          </div>
          <div style="margin-top: 9px;"><a>🔸 以太坊转账会遇到以下情况</a></div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">区块链转账与银行转账的不同</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">交易失败是什么原因</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">转错地址怎么办？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何设置矿工费？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">为什么转账失败会收矿工费？</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊挖矿</a>
          <a style="margin-top: 9px;">以太坊目前使用的是权益证明共识机制。 这种机制使得以太坊网络能够就以太坊区块链上所记录的信息状态达成一致，并使其免受某些经济攻击。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊创始人</a>
          <div>
            <a style="margin-top: 14px;">V 神全名维塔利克˙布特林（Vitalik Buterin），区块链平台「以太坊」创办人兼首席科学家，一个立志用区块链技术颠覆实体经济的
              90 后小伙子。2013 年，他着手开发一个去中心化、基于区块链技术的计算平台，取名 Ethereum。2015年，以太坊正式上线，在区块链世界掀起层层波浪。2016 年 Vitalik
              Buterin 被美国《财星》杂志评为 2016 年 40 岁以下的 40 大杰出人物之一。<a style="color: #1B8FFF;cursor: pointer"
                 href="https://en.wikipedia.org/wiki/Vitalik_Buterin">了解更多</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊价格</a>
          <a style="margin-top: 9px;">以太坊区块浏览器是一个查询以太坊区块、交易、ETH 代币、钱包等信息的网站，实时同步更新 ETH 所有节点信息。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://coinmarketcap.com/">CoinMarketCap: Cryptocurrency Market Capitalizations</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.coingecko.com/">CoinGecko: 360° Market Overview of Coins & Cryptocurrencies</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.feixiaohao.com/">非小号｜ 专注数字货币行业大数据分析</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊浏览器</a>
          <a style="margin-top: 9px;">以太坊区块浏览器是一个查询以太坊区块、交易、ETH 代币、钱包等信息的网站，实时同步更新 ETH 所有节点信息。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://etherscan.io">Etherscan | 最受欢迎的以太坊浏览器</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://blockchair.com/zh/ethereum">Blockchair｜以太坊搜索引擎</a>
          </div>
          <div style="margin-top: 9px;"><a>🔸 你也许想知道该如何使用：</a></div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何使用 Etherscan？</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">以太坊新闻</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">以太坊爱好者</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.8btc.com/">巴比特</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.odaily.news/"
               target="_blank">星球日报</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ethereumWallet",
  methods: {
    goEthereumWallet () {
      this.$router.push({ path: '/ethereum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBitcoinWallet () {
      this.$router.push({ path: '/bitcoin-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goEosWallet () {
      this.$router.push({ path: '/eos-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goCosmosWallet () {
      this.$router.push({ path: '/cosmos-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goTrxWallet () {
      this.$router.push({ path: '/trx-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goLtcWallet () {
      this.$router.push({ path: '/ltc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBchWallet () {
      this.$router.push({ path: '/bch-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goCkbWallet () {
      this.$router.push({ path: '/ckb-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goPolkadotWallet () {
      this.$router.push({ path: '/polkadot-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    gokusamaWallet () {
      this.$router.push({ path: '/kusama-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goFilWallet () {
      this.$router.push({ path: '/fil-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goXtzWallet () {
      this.$router.push({ path: '/xtz-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goToHome () {
      this.$router.push({ path: '/' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>