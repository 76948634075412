<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM 兼容链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。体验更多元化的以太坊生态</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuEvmPc/evm_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM兼容链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goBscWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BSC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goHecoWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">HECO</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goFtmWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FTM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goPolygonWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Polygon</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goGnosisWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Gnosis</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 290px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 EVM 兼容链 Polygon PoS</a>
        <van-image style="margin-top: 40px;"
                   src="img/pc/walletMenuEvmPc/polygon-wallet_dt2.svg"></van-image>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Polygon 简介</a>
          <a style="margin-top: 10px;">Polygon，原名 Matic，于 2020 年 6 月上线主网。2021 年初，开发团队决定扩大项目范围并将项目更名为 Polygon。</a>
          <a style="margin-top: 10px;">Polygon 是一个结构化、便于使用的以太坊扩容及基础设施开发平台。它的目标是成为以太坊上首个扩容解决方案聚合器，连接多个不同的扩展解决方案，从采用不同共识机制的侧链到基于 Plasma、Optimistic Rollup 和 ZK Rollup 等技术的二层网络，为以太坊建设一个模块化、通用、灵活的扩容框架。</a>
          <a style="margin-top: 10px;">目前 Polygon 生态系统支持 Polygon PoS，Polygon Hermez，Polygon Edge 等多种扩容方案，其中 Polygon PoS 链支持智能合约，并兼容 EVM，这个特性使开发者可以方便快捷地在 Polygon PoS 链上部署智能合约和构建 DApp。与以太坊相比，Polygon PoS 链的矿工费更低，交易结算速度更快。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Polygon PoS 工作原理</a>
          <a style="margin-top: 10px;">Polygon PoS 链的架构大致可分为三层：以太坊层、Heimdall 层和 Bor 层。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">以太坊层：Polygon PoS 链采用权益证明（PoS）机制保证资产安全，其质押合约部署在以太坊上...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Heimdall 层：Heimdall 是 PoS 验证者层。Heimdall 层中的验证者和以太坊主网保持平行运行...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Bor 层：Bor 是区块生产者层。区块生产者由 Heimdall 层筛选确定，负责打包交易并生成区块。</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Polygon PoS 链的特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">EVM 兼容</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">交易结算速度快，手续费低</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">可扩展性强</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Polygon 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包。</a>
          <a style="margin-top: 10px;">安全小贴士：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何安全下载 imToken</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何验证 imToken App 的真伪</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">使用</a>
          <a style="margin-top: 10px;">进入 ETH 钱包的钱包首页，点击页面上方的 「Ethereum」，在弹窗页面选择 「Polygon」即可切换至 Polygon 钱包进行转账、收款等操作并体验它的生态应用。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuEvmPc/polygon-wallet_dt3.svg"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">其他支持 Polygon 的钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">MetaMask</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Math Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">TokenPocket</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Coinbase Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Polygon 创始人</a>
          <a style="margin-top: 10px;">Jaynti Kanani 是 Polygon 的联合创始人兼 CEO，在 2017 年看到了因加密猫游戏造成的以太坊网络严重拥堵，从而萌生了开发可扩展性方案的想法，于 2017 年末着手开发 Polygon。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">EVM 兼容</a>
          <a style="margin-top: 10px;">EVM 是 Ethereum Virtual Machine 以太坊虚拟机的简称，EVM 之于以太坊，就像 CPU 之于计算机。EVM 允许在区块链上验证和执行代码，为代码在每台设备上以相同方式运行提供保障。</a>
          <a style="margin-top: 10px;">EVM 兼容指创造了一个类似 EVM 的代码执行环境，便于以太坊开发者将智能合约迁移至兼容链，而不必为其再从头开始编写智能合约的代码。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">MATIC 基本信息</a>
          <a style="margin-top: 10px;">发行时间：2019.04</a>
          <a style="margin-top: 10px;">共识机制：PoS</a>
          <div style="margin-top: 10px;">
            <a>发行 / 流通总量：<a href="https://coinmarketcap.com/currencies/polygon/"
                 style="color: #1B8FFF;">https://coinmarketcap.com/currencies/polygon/</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">MATIC 价格</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://coinmarketcap.com/currencies/binance-coin/">CoinMarketCap: Cryptocurrency Market Capitalizations</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.blockchain.com/prices/BNB?timeSpan=1&scale=0&style=line">Blockchain.com</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.feixiaohao.co/currencies/binance-coin/">非小号｜专注数字货币行业大数据分析</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Polygon 区块浏览器</a>
          <a style="margin-top: 10px;">Polygon 区块浏览器是一个查询 Polygon 区块、交易、代币、地址等信息的网站，实时同步更新 Polygon 所有节点信息。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://polygonscan.com/">PolygonScan</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">已加入 Polygon 生态的团队</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Curve</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Bancor</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Aave</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Balancer</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Aavegotchi</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Decentraland</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Ledger</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Polygon 官网：<a style="color: #1B8FFF;"
                 href="https://polygon.technology/">https://polygon.technology/</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">白皮书：</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 60px;">
            <div style="width: 5px;height: 5px;border-radius: 50%;border: 1px solid #BFC5CD;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://polygon.technology/lightpaper-polygon.pdf">https://polygon.technology/lightpaper-polygon.pdf </a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 60px;">
            <div style="width: 5px;height: 5px;border-radius: 50%;border: 1px solid #BFC5CD;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://github.com/maticnetwork/whitepaper">https://github.com/maticnetwork/whitepaper</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "polygonWallet",
  methods: {
    goBscWallet () {
      this.$router.push({ path: '/bsc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goHecoWallet () {
      this.$router.push({ path: '/heco-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goFtmWallet () {
      this.$router.push({ path: '/ftm-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goPolygonWallet () {
      this.$router.push({ path: '/polygon-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goGnosisWallet () {
      this.$router.push({ path: '/gnosis-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>