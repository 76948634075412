<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载imToken 钱包。体验快速、便宜且安全的以太坊</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">L2网路</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuLayer2Pc/layer2_dt.png"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goZksyncWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">zkSync</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goArbitrumWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Arbitrum</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goOptimismWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Optimism</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 198px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 Layer2 扩容方案 zkSync</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="margin-top: 40px;">为了更好地阅读本文，你需要先了解以太坊及其转账基本概念。</a>
          <div style="margin-top: 14px;">
            <a>阅读<a style="color: #1B8FFF;cursor: pointer"
                 @click="goEthereumWallet">这篇文章</a>的「以太坊简介」和「以太坊转账」了解。</a>
          </div>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuLayer2Pc/zkSync_pc_dt1.svg"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">zkSync 简介</a>
          <a style="margin-top: 25px;">由于以太坊主网（即 Layer1）上交易严重拥堵，交易费用居高不下，开发者提出了 Layer2 以改善以太坊网络性能，提升交易处理速度。</a>
          <a style="margin-top: 10px;">zkSync 是欧洲团队 Matter Labs 推出的一款 Layer2 扩容方案，通过采用基于零知识证明的 Rollup（主流 Layer2 方案之一） 提升以太坊网络的可拓展性。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">工作原理</a>
          <a style="margin-top: 10px;">zkSync 基于 ZK Rollup 打造。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">ZK：零知识证明（Zero Knowledge）的缩写。</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Rollup：Layer2 方案之一，也叫卷叠，通过将以太坊主网上交易的计算和存储转移至 Layer2 处理并...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">ZK Rollup：采用零知识证明的 Rollup 方案，和其他 Rollup 的不同之处在于采用 zkSNARK 算法...</a>
          </div>
          <a style="margin-top: 10px;">Rollup 的安全性由两个因素决定：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">数据有效性：确保状态已被验证，避免验证人修改 Layer2 数据来窃取用户代币。</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">数据可用性：记录与维持 Layer2 的可用数据，确保用户拥有数据的自主权，并能利用 Layer2 数据自...</a>
          </div>
          <a style="margin-top: 10px;">zkRollup（zkSync 将其所采用的 ZK Rollup 写作 zkRollup）通过将每笔交易压缩后的数据传到以太坊主网上，保证数据的有效性和可用性，使 zkSync 网络具备和以太坊主网同等的安全性。</a>
          <a style="margin-top: 10px;">关于 zkSync 原理，了解更多：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">为什么我们需要 zkPorter</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Rollup 未来在以太坊的角色</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">极低的交易费用</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 60px;">
            <div style="width: 5px;height: 5px;border-radius: 50%;border: 1px solid #BFC5CD;"></div><a style="margin-left: 10px;">zkSync 的转账费用将始终比主网的矿工费便宜 20 倍以上（ERC20 代币转账费用则便宜近...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">无需信任</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">通过加密技术保障资金拥有以太坊主网级别的安全性</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">用户代币自持</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">了解更多</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Layer2 能否解决以太坊拥堵问题？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Vitalik：Rollup 不完全指南（精选）</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">一文读懂 Layer 2 方案 zkSync 基本原理</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">从 zkSync 看以太坊 Layer 2 的机遇和挑战</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">zkSync 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">开启</a>
          <a style="margin-top: 10px;">打开 imToken 钱包，切换到「我」页面，点击「探索」，即可开启 zkSync 钱包。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuLayer2Pc/zkSync_pc_dt2.svg"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">使用</a>
          <a style="margin-top: 10px;">点击「充值」按钮进入充值页面，将以太坊主网地址中的代币充值至 zkSync 网络，到账后即可在 zkSync 网络中进行快速、低成本的转账。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuLayer2Pc/zkSync_pc_dt3.svg"></van-image>
          <div style="margin-top: 14px;">
            <a>详细教程：<a style="color: #1B8FFF;cursor: pointer"
                 class="scrollToTopLink">如何在 imToken 使用 zkSync 钱包？</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">其他支持 zkSync 的钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.argent.xyz/">Argent</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://mykey.org/en">MYKEY</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://loopring.org/#/">Loopring</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">常见问题</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://loopring.org/#/">zkSync 钱包常见问题</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Layer2</a>
          <a style="margin-top: 10px;">为了解决以太坊的可拓展性问题，开发者提出了两个方向的解决方案，Layer2 和分片 [ 以太坊升级（原名 ETH 2.0）的一个重要阶段 ] 。Layer2 字面意思是二层网络，相对的，当前的以太坊主网也称为一层网络（Layer1），二层网络也就是基于当前以太坊主网的一个系统。</a>
          <a style="margin-top: 10px;">Layer2 方案主要是从减轻 Layer1 负担的角度出发，将 Layer1 的大量计算需求搬到 Layer2 上。</a>
          <a style="margin-top: 10px;">主流 Layer2 方案有 ZK Rollup、Optimistic Rollup、Plasma、State Channels（状态通道）、Validium 等。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">ZK Rollup</a>
          <a style="margin-top: 10px;">ZK 是 Zero Knowledge（零知识）的缩写，零知识证明是指证明者能够在不向验证者提供任何有用信息的情况下，使验证者相信某个论断是正确的。</a>
          <a style="margin-top: 10px;">Rollup 中文叫做卷叠，是一种以太坊扩容方案的统称。Rollup 通过将链上计算转移至链下（二层网络），但同时将每笔交易的部分数据保留在链上达到提升以太坊网络性能的目的。</a>
          <a style="margin-top: 10px;">ZK Rollup = 交易压缩 + 零知识证明（zk-SNARK）</a>
          <a style="margin-top: 10px;">ZK Rollup 核心思想是在链下达成交易共识，并通过零知识证明算法压缩交易数据，保证链下交易状态的有效性，最后将数据同步至以太坊主网。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">开发团队 Matter Labs</a>
          <a style="margin-top: 10px;">zkSync 的开发团队 Mattet Labs 是一个对自由、区块链和数学充满热情的工程团队，总部位于柏林，员工遍及全球（主要是在欧洲）。</a>
          <a style="margin-top: 10px;">Matter Labs 通过零知识证明技术对以太坊进行扩展，其使命是加速这场正在进行的金融革命。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">zkSync 浏览器</a>
          <a style="margin-top: 10px;">zkSync 区块浏览器是一个查询 zkSync 区块、交易、状态根哈希、账户地址等信息的网站。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://zkscan.io/">zkScan｜zkSync 的区块浏览器和数据分析平台</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">zkSync 路线图</a>
          <a style="margin-top: 10px;">该项目目前的产品路线图规划为：可扩展支付、智能合约、隐私和抗审查。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuLayer2Pc/zkSync_pc_dt4.svg"></van-image>
          <div style="margin-top: 10px;">
            <a><a style="font-size: 18px;color:#606d83">可扩展支付</a>：zkSync 当前正处于此阶段，致力于打造一个安全可拓展的加密代币支付方案。</a>
          </div>
          <div style="margin-top: 10px;">
            <a><a style="font-size: 18px;color:#606d83">智能合约</a>：zkSync 将在 Zinc 和 Solidity 中引入高效、安全、图灵完备、多语言的智能合约。你可以在开发者文档中
              <a style="color: #1B8FFF;"
                 href="https://zksync.io/dev/contracts">了解更多</a>。</a>
          </div>
          <div style="margin-top: 10px;">
            <a><a style="font-size: 18px;color:#606d83">隐私</a>：目前，zkSync 中的交易信息就像以太坊主网一样，是公开透明的，未来 zkSync 将引入隐私功能。</a>
          </div>
          <div style="margin-top: 10px;">
            <a><a style="font-size: 18px;color:#606d83">抗审查</a>：扩展性解决方案的一个常见问题是大多数用户无法参与验证，网络会趋向中心化。zkSync 打算通过引入一个独立的共识机制来实现去中心化和抗审查，该机制包含验证者和守护者这两个不同的角色。</a>
          </div>
          <a style="margin-top: 10px;">此外，zkSync 计划在未来推出原生代币用于治理。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">已加入 zkSync 生态的团队</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Curve</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Aave</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Loopring</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Balancer</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">1inch</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Coinbase</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Huobi</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Binance</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Moonpay</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Argent</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">imToken</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">MYKEY</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">CoinGecko</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Gitcoin</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">zkSync 官网：<a style="color: #1B8FFF;"
                 href="https://zksync.io/">https://zksync.io/</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Matter Labs 官网：<a style="color: #1B8FFF;"
                 href="https://matter-labs.io/">https://matter-labs.io/ </a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zksyncWallet",
  methods: {
    goEthereumWallet () {
      this.$router.push({ path: '/ethereum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goZksyncWallet () {
      this.$router.push({ path: '/zksync-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goArbitrumWallet () {
      this.$router.push({ path: '/arbitrum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goOptimismWallet () {
      this.$router.push({ path: '/optimism-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>