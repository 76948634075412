<template>
  <div style="margin-top: 80px;max-width: 100%;">
    <div style="width: 100%;display: flex; flex-direction: column; align-items: center;padding: 45px 0;height: 476px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">imToken 品牌指南</a> 
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
        line-height: 30px;text-align: center;font-style: normal;text-transform: none;">品牌素材下载 & 使用规范</a>
        <van-image style="margin-top: 57px;" src="img/brand/brand_dt1.svg"></van-image>
    </div>
  </div>
  <div style="margin-top: 20px;max-width: 100%;background: #468BDF;height: 657px;">
    <div style="display: flex; flex-direction: column; align-items: center;">
      <van-image style="margin-top: 47px;" src="img/brand/brand_dt2.svg"></van-image>
    </div>
    <div style="margin-top: 60px;display: flex; flex-direction: column; align-items: center;">
      <div style="width: 90%;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #FFFFFF;
        line-height: 38px;font-style: normal;text-transform: none;">imToken 品牌概览</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 30px;text-align: left;
        font-style: normal;text-transform: none;">imToken，一款安全放心、简单易用的数字钱包，轻松访问和使用比特币、以太坊、波场等 35+ 条主流区块链网络，同时支持硬件钱包，币币兑换和 DApp 浏览器等功能，为千万用户提供可信赖的非托管钱包服务。</a>
        <div style="width: 138px;height: 45px;border-radius: 8px 8px 8px 8px;border: 1px solid #FFFFFF;display: flex; justify-content: center; 
        flex-direction: column;align-items: center;margin-top: 35px;">
          <a style="width: 138px;height: 45px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #FFFFFF;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;" href="img/brand/imToken-brand.zip">下载</a>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;">
    <div style="width: 90%;display: flex; flex-direction: column; align-items: center;">
      <van-image style="margin-top: 20px;" src="img/brand/brand_dt3.svg"></van-image>
    </div>
    <div style="margin-top: 20px;width: 78%;display: flex; flex-direction: column;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1B1C1F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken 品牌概览</a>
      <div style="margin-top: 9px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #767B8A;line-height: 30px;text-align: left;
      font-style: normal;text-transform: none;">imToken Fans 是 imToken 的社区品牌。该品牌旨在凝聚 imToken 旗下产品的全球用户, 建立与用户紧密联系的生态圈, 提升其对区块链以及去中心化钱包的整体认知水平。2019 年 3 月上线了 imToken Fans 论坛，为 imToken 近千万用户提供「绿色」的数字钱包交流渠道。</a>
      </div>
      <div style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #777B8B;
        line-height: 15px;text-align: left;font-style: normal;text-transform: none;">
        <a>了解更多</a><a style="margin-left: 10px;">></a>
      </div>
      <div style="width: 138px;height: 45px;border-radius: 8px 8px 8px 8px;border: 1px solid #50525C;display: flex; justify-content: center; 
        flex-direction: column;align-items: center;margin-top: 35px;">
        <a style="width: 138px;height: 45px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;" href="img/brand/imTokenFans-brand.zip">下载</a>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;">
    <div style="width: 90%;display: flex; flex-direction: column; align-items: center;">
      <van-image style="margin-top: 61px;" src="img/brand/brand_dt4.svg"></van-image>
    </div>
    <div style="margin-top: 20px;width: 78%;display: flex; flex-direction: column;">
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 24px;color: #1B1C1F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">市场运营活动素材</a>
      <a style="margin-left: 9px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #767B8A;
      line-height: 30px;text-align: left;font-style: normal;text-transform: none;">兼容 imToken 2.5 /1.0</a>
      </div>
      <div style="margin-top: 9px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #767B8A;line-height: 30px;text-align: left;
      font-style: normal;text-transform: none;">蓝色区域为安全区域，保证文案等主要视觉内容在安全区域内，以免被 iPhone X 等机型的刘海遮挡。 Banner 展示处无文章标题，文案内容应当融合在设计当中。 原则上禁止使用白色/黑色背景图片做 Banner，不推荐浅色背景图片。</a>
      </div>
      <div style="width: 138px;height: 45px;border-radius: 8px 8px 8px 8px;border: 1px solid #50525C;display: flex; justify-content: center; 
        flex-direction: column;align-items: center;margin-top: 35px;">
        <a style="width: 138px;height: 45px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;" href="img/brand/imToken-Banner-Design.zip">下载</a>
      </div>
    </div>
  </div>
  <br>
  <br>
</template>

<script>
export default {
  name: "brandIndex",
}
</script>

<style>

</style>