<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1100px;margin-top: 50px;height: 438px;background: #FAFAFA;border-radius: 24px 24px 24px 24px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken 品牌指南</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">品牌素材下载 & 使用规范</a>
      </div>
      <van-image style="margin-top: 35px;margin-left: 125px;"
                 src="img/pc/brand/brand_dt.png"></van-image>
    </div>
    <div style="width: 100%;margin-top: 60px;height: 621px;background: #468BDF;display: flex; justify-content: center; align-items: center;">
      <van-image src="img/pc/brand/brand_dt2.png"></van-image>
      <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 125px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken 品牌概览</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #FFFFFF;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;">
          <a>imToken，一款安全放心、简单易用的数字钱包，轻松访问</a>
          <br>
          <a>和使用比特币、以太坊、波场等 35+ 条主流区块链网络，</a>
          <br>
          <a>同时支持硬件钱包，币币兑换和 DApp 浏览器等功能，为</a>
          <br>
          <a>千万用户提供可信赖的非托管钱包服务。</a>
          <br>
          <a href="img/brand/imToken-brand.zip">
            <div style="width: 144px;height: 48px;border-radius: 8px 8px 8px 8px;border: 1px solid #FFFFFF;display: flex; justify-content: center; align-items: center;margin-top: 25px;">
              <span style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #FFFFFF;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">下载</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div style="width: 1100px;margin-top: 60px;display: flex; justify-content: center; align-items: center;">
      <van-image src="img/pc/brand/brand_dt3.png"></van-image>
      <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 88px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #1B1C1F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken 品牌概览</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;">
          <a>imToken Fans 是 imToken 的社区品牌。该品牌旨在凝聚</a>
          <br>
          <a>imToken 旗下产品的全球用户, 建立与用户紧密联系的生态</a>
          <br>
          <a>圈, 提升其对区块链以及去中心化钱包的整体认知水平。2019</a>
          <br>
          <a>年 3 月上线了 imToken Fans 论坛，为 imToken 近千万用</a>
          <br>
          <a>户提供「绿色」的数字钱包交流渠道。</a>
          <div style="margin-top: 16px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;"
               href="https://imtoken.fans">了解更多 ></a>
          </div>
          <a href="img/brand/imTokenFans-brand.zip">
            <div style="width: 144px;height: 48px;border-radius: 8px 8px 8px 8px;border: 1px solid #50525C;display: flex; justify-content: center; align-items: center;margin-top: 25px;">
              <span style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">下载</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div style="width: 1100px;margin-top: 200px;display: flex;">
      <van-image src="img/pc/brand/brand_dt4.png"></van-image>
      <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 88px;">
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #1B1C1F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">市场运营活动素材</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #767B8A;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-left: 25px;">兼容 imToken 2.5 /1.0</a>
        </div>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;">
          <a>蓝色区域为安全区域，保证文案等主要视觉内容在安全区域</a>
          <br>
          <a>内，以免被 iPhone X 等机型的刘海遮挡。</a>
          <br>
          <a>Banner 展示处无文章标题，文案内容应当融合在设计当中。</a>
          <br>
          <a>原则上禁止使用白色/黑色背景图片做 Banner，不推荐浅色背</a>
          <br>
          <a>景图片。</a>
        </div>
        <a href="img/brand/imToken-Banner-Design.zip">
          <div style="width: 144px;height: 48px;border-radius: 8px 8px 8px 8px;border: 1px solid #50525C;display: flex; justify-content: center; align-items: center;margin-top: 25px;">
            <span style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">下载</span>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 65px;">
    <div style="width: 1100px;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
        </div>
        <van-button type="primary"
                    color="#468BE0"
                    style="width: 137px;height: 47px;margin-top: 20px;"
                    to="download">
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
        </van-button>
      </div>
      <div style="margin-right: 10px">
        <van-image src="img/pc/home/pc_dt4.svg"></van-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "brandPcIndex",
}
</script>

<style>
</style>