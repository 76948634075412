<template>
  <div style="max-width: 100%;margin-top: 80px;display: flex; flex-direction: column; align-items: center;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">公链</a>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
   </div>
   <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
   <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_ytf.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">以太坊</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_btb.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">比特币</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_eos.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">EOS</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_atom.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">ATOM</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_trx.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">TRX</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_ltc.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">LTC</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_bch.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">BCH</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_ckb.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">CKB</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_dot.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">DOT</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_ksm.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">KSM</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_fil.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">FIL</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenu/walletMenu_xtz.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">XTZ</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
export default {
  name: "walletMenuIndex",
}
</script>

<style>

</style>