<template>
  <div style="margin-top: 80px;max-width: 100%;">
    <div style="background-color: #F5F7FA;height: 50px;max-width: 100%;">
      <div style="padding: 0 20px; display: flex;align-items: center;height: 50px;">
        <van-image src="img/laba.png"></van-image><a style="margin-left: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 12px;
        color: #787A8B;line-height: 30px;text-align: center;font-style: normal;text-transform: none;">imToken 推出信用卡服务，预先注册已开启!</a>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;margin-bottom: 10px;">
    <div style="margin-top: 49px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">让每个人平等自由地享受</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">有意义的数位生活</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;">imToken，一款安全方向、简单易用的数字</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
      line-height: 30px;text-align: center;font-style: normal;text-transform: none;">钱包，超千万用户信赖</a>
      <van-button class="buttonPc"
                  id="installButtonPc"
                  type="primary"
                  color="#2A80EA"
                  style="margin-top: 20px;width: 132px;height: 46px;"
                  to="download">
        <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
        text-align: left;font-style: normal;text-transform: none;">立即下载</a>
      </van-button>
    </div>
  </div>
  <div style="width: 100%;display: flex; align-items: center;">
    <van-image style="margin-top: 30px;"
               src="img/pc/home/banner.png"></van-image>
  </div>
  <div style="margin-top: 49px;background-color: #2A80EA;height: 677px;max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 90%;padding: 0 15px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #FFFFFF;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">imToken</a>
      <br>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #FFFFFF;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">为全球超过150个国家的千万用户提供安全、可信赖的非托管钱包服务</a>
    </div>
    <div style="margin-top: 36px;width: 90%;padding: 0 15px;">
      <div style="background-color: #5997e2;height: 140px;border-top-left-radius: 5px; border-top-right-radius: 5px;">
        <div style="padding: 40px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
          text-align: center;font-style: normal;text-transform: none;">7周年</a>
          <br>
          <a style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
          text-align: center;font-style: normal;text-transform: none;">成立于2016年</a>
        </div>
      </div>
      <div style="margin-top: 2px;background-color: #5997e2;height: 140px;">
        <div style="padding: 40px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
          text-align: center;font-style: normal;text-transform: none;">1500万</a>
          <br>
          <a style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
          text-align: center;font-style: normal;text-transform: none;">用户正在使用</a>
        </div>
      </div>
      <div style="margin-top: 2px; background-color: #5997e2; height: 140px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
        <div style="padding: 40px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
          text-align: center;font-style: normal;text-transform: none;">150+</a>
          <br>
          <a style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
          text-align: center;font-style: normal;text-transform: none;">覆盖国家和地区</a>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 90%;height: 350px;margin: auto;">
    <van-image style="margin-top: 67px;height: 232px;width: 325px;"
               src="img/pc/home/wallet.svg"></van-image>
  </div>
  <div style="width: 90%;padding: 0 25px;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 25px;color: #181D1E;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;">多链多网络</a>
    <br>
    <br>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
    line-height: 27px;text-align: left;font-style: normal;text-transform: none;">轻松访问BTC、ETH、BNB、TRX、DOT、MATIC、
      LTC、BCH、ATOM、OP、FIL、ARB、EOS、KSM、XTZ、CKB</a>
  </div>
  <div style="width: 90%;padding: 0 25px;">
    <div style="margin-top: 50px;display: flex;">
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
      text-align: left;font-style: normal;text-transform: none;">35+</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">已支援网络</a>
      </div>
      <div style="margin-left: 30px;margin-right: 30px;border-left: 2px solid #E2E6EA;height: 60px;"></div>
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
        text-align: left;font-style: normal;text-transform: none;">80万+</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
        text-align: left;font-style: normal;text-transform: none;">已收录代币</a>
      </div>
    </div>
  </div>
  <div style="width: 90%;height: 350px;margin: auto;margin-top: 50px;">
    <van-image style="margin-top: 67px;height: 232px;width: 325px;"
               src="img/pc/home/imkey.svg"></van-image>
  </div>
  <div style="width: 90%;padding: 0 25px;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 25px;color: #181D1E;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;">离线生成和储存私钥</a>
    <br>
    <br>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
    line-height: 27px;text-align: left;font-style: normal;text-transform: none;">搭配imKey Pro硬体钱包，离线签名更安心</a>
  </div>
  <div style="width: 90%;padding: 0 25px;">
    <div style="margin-top: 50px;display: flex;">
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
      text-align: left;font-style: normal;text-transform: none;">￥688</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">当前售价</a>
      </div>
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
        text-align: left;font-style: normal;text-transform: none;"></a>
        <br>
        <div style="display: flex;align-items: center;">
          <div style="margin-left: 30px;margin-right: 30px;border-left: 2px solid #E2E6EA;height: 20px;"></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
        text-align: left;font-style: normal;text-transform: none;"
             href="https://shop42404838.m.youzan.com/v2/feature/d5bMdxB3Ab?dc_ps=3252957778326674432.200001">立即购买 ></a>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 60px;width: 100%;height: 665px;background-color: #F6F8FB;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 25px;color: #181D1E;line-height: 30px;
    text-align: center;font-style: normal;text-transform: none;">生态伙伴</a>
    <div style="display: flex; justify-content: space-between; width: 80%;margin-top: 45px;"><van-image src="img/hb1.png"></van-image><van-image src="img/hb2.png"></van-image></div>
    <div style="display: flex; justify-content: space-between; width: 80%;margin-top: 45px;"><van-image src="img/hb3.png"></van-image><van-image src="img/hb4.png"></van-image></div>
    <div style="display: flex; justify-content: space-between; width: 80%;margin-top: 45px;"><van-image src="img/hb5.png"></van-image><van-image src="img/hb6.png"></van-image></div>
    <div style="display: flex; justify-content: space-between; width: 80%;margin-top: 45px;"><van-image src="img/hb7.png"></van-image><van-image src="img/hb8.png"></van-image></div>
    <van-image src="img/hb9.png"
               style="margin-top: 45px;"></van-image>
  </div>
  <div style="width: 90%;padding: 0 25px;">
    <div style="margin-top: 76px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 25px;color: #181D1E;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;">联系我们</a>
    </div>
  </div>
  <div style="width: 90%;padding: 0 25px;">
    <div style="display: flex;align-items: flex-start;margin-top: 53px;">
      <van-image src="img/lx1.png"></van-image>
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">官方论坛</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">https://imtoken.fans</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #C6CADA;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">开放的数位钱包使用者互助平台</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 40px;">
      <van-image src="img/lx2.png"></van-image>
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">提交回馈</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">support@token.im</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #C6CADA;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">产品使用遇到问题？尝试向客户服务团队寻找答案吧</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 53px;">
      <van-image src="img/lx3.png"></van-image>
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">商业合作</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">bd@token.im</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #C6CADA;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">媒体及商业合作联系</a>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeIndex",
}
</script>

<style>
</style>
