<template>
  <div style="max-width: 100%;">
    <div style="width: 90%;height: 480px;margin: auto;">
      <div style="margin-top: 130px;width: 412;height: 292px;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 26px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">关于我们</a>
        <div style="margin-top: 5px;"><a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 19px;
        color: #787A8B;line-height: 36px;text-align: left;font-style: normal;text-transform: none;">区块链技术创新的践行者与挑战者</a></div>
        <van-image style="margin-top: 50px;" src="img/about/about_dt.svg"></van-image>
      </div>
    </div>
  </div>
  <div style="margin-top: 49px;background-color: #087FB8;height: 677px;max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 90%;padding: 0 15px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #FFFFFF;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">imToken</a>
      <br>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #FFFFFF;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">为全球超过 150 个国家的千万用户提供安全、可信赖的非托管钱包服务</a>
    </div>
    <div style="margin-top: 36px;width: 90%;padding: 0 15px;">
      <div style="background-color: #308CC0;height: 140px;border-top-left-radius: 5px; border-top-right-radius: 5px;">
        <div style="padding: 40px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
          text-align: center;font-style: normal;text-transform: none;">7周年</a>
          <br>
          <a style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
          text-align: center;font-style: normal;text-transform: none;">成立于2016年</a>
        </div>
      </div>
      <div style="margin-top: 2px;background-color: #308CC0;height: 140px;">
        <div style="padding: 40px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
          text-align: center;font-style: normal;text-transform: none;">1500万</a>
          <br>
          <a style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
          text-align: center;font-style: normal;text-transform: none;">用户正在使用</a>
        </div>
      </div>
      <div style="margin-top: 2px; background-color: #308CC0; height: 140px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
        <div style="padding: 40px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
          text-align: center;font-style: normal;text-transform: none;">150+</a>
          <br>
          <a style="margin-top: 15px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
          text-align: center;font-style: normal;text-transform: none;">覆盖国家和地区</a>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 60px;display: flex; justify-content: center; flex-direction: column;padding: 0 20px;">
    <div style="display: flex; align-items: center; ">
      <p style="margin-left: 10px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;line-height: 38px;
    text-align: center;font-style: normal;text-transform: none;">关于公司</p>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 35px;height: 35px;" src="img/about/about_xt1.svg"></van-image>
      <div style="margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">愿景</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">致力于打造最为安全放心、简单易用的数字钱包，让每个人平等自由地享受有意义的数字生活</a>
      </div>
    </div>
    <div style="display: flex;align-items: flex-start;margin-top: 22px;">
      <van-image style="width: 35px;height: 35px;" src="img/about/about_xt2.svg"></van-image>
      <div style="margin-left: 20px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
        text-align: left;font-style: normal;text-transform: none;">价值观</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">因为坚信，所以坚持，我们致力于成为：简单质朴的追随者、良好意愿的先行者、坚韧不拔的挑战者</a>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;margin-top: 90px;">
    <van-image src="img/about/about_dt2.svg"></van-image>
    <van-image style="margin-top: 20px;" src="img/about/about_dt3.svg"></van-image>
  </div>
  <div style="width: 100%;background: #F6F9FB;height: 1329px;margin-top: 90px;display: flex; flex-direction: column; align-items: center;justify-content: center;">
    <div style="height: 1150px; width: 90%;">
      <div class="page" data-uia-timeline-skin="3" data-uia-timeline-adapter-skin-3="uia-card-skin-#1">
        <div class="uia-timeline">
          <div class="uia-timeline__container">
            <div class="uia-timeline__line"></div>
            <div class="uia-timeline__annual-sections">
              <span class="uia-timeline__year" aria-hidden="true">2016</span>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-1">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>5月</a>
                            <br>
                            <a>公司成立</a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-2">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>11.11</a>
                            <br>
                            <a>imToken 1.0上线</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="uia-timeline__annual-sections">
              <span class="uia-timeline__year" aria-hidden="true">2018</span>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-3">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>2.14</a>
                            <br>
                            <a>imToken 用户数达百万</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-3">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>5.27</a>
                            <br>
                            <a>获IDG千万美金投资</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-3">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>7.31</a>
                            <br>
                            <a>imToken 2.0正式发布</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-3">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>10.24</a>
                            <br>
                            <a>imToken核心代码开源</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="uia-timeline__annual-sections">
              <span class="uia-timeline__year" aria-hidden="true">2019</span>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-1">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>7.31</a>
                            <br>
                            <a>Tokenlon正式上线（投资孵化项目）</a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="uia-timeline__annual-sections">
              <span class="uia-timeline__year" aria-hidden="true">2020</span>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-1">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>12.22</a>
                            <br>
                            <a>Tokenlon 5.0正式上线（投资孵化项目）</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="uia-timeline__annual-sections">
              <span class="uia-timeline__year" aria-hidden="true">2021</span>
              <div class="uia-timeline__groups">
                <section class="uia-timeline__group" aria-labelledby="timeline-demo-1-heading-1">
                  <div class="uia-timeline__point uia-card" data-uia-card-skin="1" data-uia-card-mod="1">
                    <div class="uia-card__container">
                      <div class="uia-card__body">
                        <div class="uia-card__description">
                          <div style="margin: 15px 10px;">
                            <a>3.31</a>
                            <br>
                            <a>获启明创投领投的三千万美金融资</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="max-width: 100%;">
    <div style="width: 90%;height: 350px;margin: auto;">
      <div style="margin-top: 107px;width: 412;height: 292px;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 26px;color: #1A1C1E;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">加入我们</a>
        <div style="width: 137px;height: 47px;border-radius: 6px 6px 6px 6px;border: 1px solid #000000;
        display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 20px;">
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #43454F;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即查看职位</a>
        </div>
        <van-image style="margin-top: 40px;" src="img/about/about_dt4.svg"></van-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutIndex",
}
</script>

<style>
.timeline {
            position: relative;
            width: 2px; /* 时间线的宽度 */
            background-color: black; /* 时间线的颜色 */
            height: 300px; /* 时间线的高度 */
            float: left; /* 让时间线在左边 */
            margin-right: 20px; /* 调整时间线距离右侧内容的距离 */
        }
        .event {
            position: relative;
            margin-bottom: 20px; /* 调整事件之间的垂直间距 */
        }
        .line {
            position: absolute;
            left: 0;
            top: 5px; /* 调整指向线的位置 */
            width: 2%; /* 指向线的长度 */
            border-top: 2px solid black; /* 指向线的样式 */
        }
        .event-content {
            margin-left: 20px; /* 调整事件到时间线右侧的距离 */
        }

/*
=====
UIA-TIMELINE
=====
*/

.uia-timeline__container{
  display: var(--uia-timeline-display, grid);
}

.uia-timeline__groups{
  display: var(--uia-timeline-groups-display, grid);;
  gap: var(--uia-timeline-groups-gap, 1rem);
}


/*
SKIN 3
*/

[data-uia-timeline-skin="3"]{
  --_uia-timeline-line-color_default: #222;
  --_uia-timeline-space: var(--uia-timeline-space, 1rem);
  --_uia-timeline-line-thickness: var(--uia-timeline-line-thickness, 2px);
  --_uia-timeline-point-line-position: var(--uia-timeline-point-line-position, 1rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__container{
  position: relative;
  gap: var(--uia-timeline-annual-sections-gap, 2.5rem);
}

[data-uia-timeline-skin="3"] .uia-timeline__line{
  inline-size: var(--_uia-timeline-line-thickness);
  block-size: 100%;
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

[data-uia-timeline-skin="3"] .uia-timeline__annual-sections{
  display: grid;
  gap: 2rem;
}

[data-uia-timeline-skin="3"] .uia-timeline__groups{
  padding-inline-start: var(--_uia-timeline-space);
}

[data-uia-timeline-skin="3"] .uia-timeline__group{
  position: relative;
  isolation: isolate;
}

[data-uia-timeline-skin="3"] .uia-timeline__point{
  background-color: var(--uia-timeline-point-background-color, #fff);
}

[data-uia-timeline-skin="3"] .uia-timeline__point::before{
  content: "";
  inline-size: 100%;
  block-size: var(--_uia-timeline-line-thickness);
  background-color: var(--uia-timeline-line-color, var(--_uia-timeline-line-color_default));

  position: absolute;
  inset-block-start: var(--_uia-timeline-point-line-position);
  inset-inline-start: calc(-1 * var(--_uia-timeline-space));
  z-index: -1;
}

[data-uia-timeline-skin="3"] .uia-timeline__year{
  inline-size: fit-content;
  padding: var(--uia-timeline-year-padding, .25rem .75rem);
  background-color: var(--uia-timeline-year-background-color, var(--_uia-timeline-line-color_default));
  color: var(--uia-timeline-year-color, #f0f0f0);
}

[data-uia-timeline-adapter-skin-3="uia-card-skin-#1"]{
  --uia-card-padding: var(--uia-timeline-point-padding, 1.5rem 1.5rem 1.25rem);
  --uia-card-border-thickness:  var(--uia-timeline-point-border-thickness, 3px);
	--uia-card-border-color: var(--uia-timeline-point-border-color, var(--_uia-timeline-line-color_default));		
  --uia-card-background-color: var(--uia-timeline-point-background-color);  
}

/*
=====
RESET
=====
*/

:where(.ra-link){
  display: var(--ra-link-display, inline-flex);
}

:where(.ra-link[href]){
  color: var(--ra-link-color, inherit);
  text-decoration: var(--ra-link-text-decoration, none);
}

:where(.ra-heading){
  margin-block-start: var(--ra-heading-margin-block-start, 0);
  margin-block-end: var(--ra-heading-margin-block-end, 0);
}

/*
=====
HELPERS
=====
*/

.ha-screen-reader{
  width: var(--ha-screen-reader-width, 1px);
  height: var(--ha-screen-reader-height, 1px);
  padding: var(--ha-screen-reader-padding, 0);
  border: var(--ha-screen-reader-border, none);

  position: var(--ha-screen-reader-position, absolute);
  clip-path: var(--ha-screen-reader-clip-path, rect(1px, 1px, 1px, 1px));
  overflow: var(--ha-screen-reader-overflow, hidden);
}

/*
=====
UIA-CARD
=====
*/

[data-uia-card-skin="1"] .uia-card__container{
  display: grid;
  gap: var(--uia-card-content-gap, .5rem);

  background-color: var(--uia-card-background-color);
  border-radius: var(--uia-card-border-radius, 2px);
}

[data-uia-card-skin="1"] .uia-card__intro{
  display: grid;
  gap: var(--uia-card-intro-gap, 1rem);
}

[data-uia-card-skin="1"] .uia-card__time{
  grid-row: 1 / 1;
  inline-size: fit-content;
  padding: var(--uia-card-time-padding, .25rem 1.25rem .25rem);
  background-color: var(--uia-card-time-background-color, #f0f0f0);

  font-weight: var(--uia-card-time-font-weight, 700);
  font-size: var(--uia-card-time-font-size, .75rem);
  text-transform: var(--uia-card-time-text-transform, uppercase);
  color: var(--uia-card-time-color, currentColor);
}

[data-uia-card-skin="1"][data-uia-card-mod="1"] .uia-card__container{
	border-inline-start:	var(--uia-card-border-thickness, 2px) var(--uia-card-border-style, solid) var(--uia-card-border-color, currentColor);
	box-shadow: var(--uia-card-box-shadow, 0 1px 3px 0 rgba(0, 0, 0, .5), 0 1px 2px 0 rgba(0, 0, 0, .5));
}

/*
=====
DEMO
=====
*/

:root{
  --uia-timeline-line-color: #4557bb;
  --uia-timeline-dot-color: #4557bb;
  --uia-timeline-arrow-color: #4557bb;
  --uia-timeline-line-thickness: 3px;
  --uia-timeline-point-border-color: #4557bb;
  --uia-timeline-group-padding: 1.5rem 1.5rem 1.25rem;
}

[data-uia-timeline-skin="3"]{
	--uia-timeline-year-background-color: #4557bb;
}

.page{
  max-inline-size: 80ch;
  padding: 0 1rem 0;
  margin-inline: auto;
}

</style>