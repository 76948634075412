<template>
  <div style="max-width: 100%;">
    <div style="width: 90%;height: 480px;margin: auto;">
      <div style="margin-top: 130px;width: 412;height: 292px;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #000000;line-height: 28px;
        text-align: left;font-style: normal;text-transform: none;">探索 DApp 从这里开始</a>
        <div style="margin-top: 10px;"><a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
        text-align: left;font-style: normal;text-transform: none;">DApp是一种基于智能合约的去中心化应用</a></div>
        <div style="margin-top: 10px;"><a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
        text-align: left;font-style: normal;text-transform: none;">程式(Decentralized App)</a></div>
        <div style="margin-top: 10px;"><a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
        text-align: left;font-style: normal;text-transform: none;">了解更多 ></a></div>
        <van-image style="margin-top: 50px;"
                   src="img/dapp/dappdt.png"></van-image>
      </div>
    </div>
  </div>
  <div style="margin-top: 40px;width: 100%;height: 514px;background: #0293C3;display: flex; flex-direction: column; align-items: center;">
    <a style="margin-top: 110px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #FFFFFF;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;">开放的DApp浏览器</a>
    <br>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #94C8E2;
    line-height: 25px;text-align: center;font-style: normal;text-transform: none;">支援 ETH, EOS, TRON DApp</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #94C8E2;
    line-height: 25px;text-align: center;font-style: normal;text-transform: none;">输入DApp网址可体验任意DApp</a>
    <van-image style="height: 279px;margin-top: 19px;"
               src="img/dapp/dappdt2.png"></van-image>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center;height: 868px;background: #F6F8FB;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 60px;">第三方合作DApp</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
    line-height: 30px;text-align: center;font-style: normal;text-transform: none;">imToken已上架150+ 优质DApp</a>
    <div style="display: flex; align-items: center; justify-content: center;margin-top: 60px;">
      <div>
        <div style="display: flex; flex; flex-direction: column; align-items: center;">
          <van-image src="img/dapp/Maker.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">Maker</a>
        </div>
      </div>
      <div style="margin-left: 34px;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/dapp/Compound.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">Compound</a>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;margin-top: 35px;">
      <div>
        <div style="display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/dapp/HyperDragons.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">HyperDragons</a>
        </div>
      </div>
      <div style="margin-left: 34px;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <van-image src="img/dapp/HyperSnakes.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">HyperSnakes</a>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center;margin-top: 35px;">
      <div>
        <div style="display: flex; flex; flex-direction: column; align-items: center;">
          <van-image src="img/dapp/LocalCryptos.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">LocalCryptos</a>
        </div>
      </div>
      <div style="margin-left: 34px;">
        <div style="display: flex;  flex-direction: column; align-items: center;">
          <van-image src="img/dapp/CoinSwitch.png"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">CoinSwitch</a>
        </div>
      </div>
    </div>
    <div style="margin-top: 35px;">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <van-image src="img/dapp/GodsUnchained.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;
          line-height: 30px;text-align: center;font-style: normal;text-transform: none;">Gods Unchained</a>
      </div>
    </div>
  </div>
  <div style="width: 100%;height:475px;display: flex; flex-direction: column; align-items: center;">
    <div style="width: 90%;margin-top: 64px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">联系我们</a>
      <div style="display: flex;align-items: flex-start;margin-top: 38px;">
        <van-image src="img/lx1.png"></van-image>
        <div style="margin-left: 12px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">整合DApp</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">根据文件开始建构你的应用吧</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #777B8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">查看文件 ></a>
        </div>
      </div>
      <div style="display: flex;align-items: flex-start;margin-top: 40px;">
        <van-image src="img/lx2.png"></van-image>
        <div style="margin-left: 12px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">上线DApp</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">提交表单进行申请吧</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #777B8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">填写表单 ></a>
        </div>
      </div>
      <div style="display: flex;align-items: flex-start;margin-top: 53px;">
        <van-image src="img/lx3.png"></van-image>
        <div style="margin-left: 12px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">商业合作</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">欢迎透过信箱与我们取得联系</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #777B8B;
          line-height: 15px;text-align: left;font-style: normal;text-transform: none;">bd@token.im ></a>
          <br>
          <br>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
</template>

<script>
export default {
  name: "dappIndex",
}
</script>

<style>
</style>