<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载imToken 钱包。体验快速、便宜且安全的以太坊</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">L2网路</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuLayer2Pc/layer2_dt.png"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goZksyncWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">zkSync</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goArbitrumWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Arbitrum</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goOptimismWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Optimism</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 198px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 Layer2 扩容方案 Optimism</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <van-image style="margin-top: 40px;"
                     src="img/pc/walletMenuLayer2Pc/optimism_pc_dt2.svg"></van-image>
          <a style="margin-top: 10px;">为了更好地阅读本文，你需要先了解以太坊及其转账基本概念。</a>
          <div style="margin-top: 14px;">
            <a>阅读<a style="color: #1B8FFF;cursor: pointer"
                 @click="goEthereumWallet">这篇文章</a>的「以太坊简介」和「以太坊转账」了解。</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Optimism 简介</a>
          <div style="margin-top: 25px;"><a>由于以太坊主网（即 Layer1）上交易严重拥堵，交易费用居高不下，开发者提出了 <a style="color: #1B8FFF;">Layer2</a> 以改善以太坊网络性能，提升交易处理速度。</a></div>
          <div style="margin-top: 25px;"><a>Optimism 是一款 <a style="color: #1B8FFF;">Layer2</a> 扩容方案，通过采用单轮交互型设计的 Optimistic Rollup 方案，以实现对以太坊网络的扩容目标。</a></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">工作原理</a>
          <a style="margin-top: 10px;">Optimism 基于 Optimistic Rollup 打造，通过单轮交互型欺诈证明保证同步到 Layer1 的数据是有效的。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Rollup：Layer2 方案之一，也叫卷叠，通过将以太坊主网上交易的计算和存储转移至 Layer2 处理...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Optimistic Rollup：Optimistic Rollup 字面理解是乐观主义的 Rollup，它乐观地默认被同步到以...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">单轮交互型欺诈证明：Layer2 的验证人将压缩后的数据同步到 Layer1，同时质押保证金，如果有人...</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">极低的交易费用</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">无需信任</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">通过加密技术保障资金拥有以太坊主网级别的安全性</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">用户代币自持</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">EVM 兼容</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">了解更多</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Layer2 能否解决以太坊拥堵问题？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Vitalik：Rollup 不完全指南（精选）</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Optimism 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">使用</a>
          <a style="margin-top: 10px;">进入 ETH 钱包首页，点击页面上方的「Ethereum」，在弹窗页面选择 「Optimism」即可切换至 Optimism 钱包进行使用。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuLayer2Pc/optimism_pc_dt3.svg"></van-image>
          <div style="margin-top: 14px;">
            <a>详细教程：<a style="color: #1B8FFF;cursor: pointer"
                 class="scrollToTopLink">如何在 imToken 中使用 Optimism 钱包？</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">其他支持 zkSync 的钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://metamask.io/">MetaMask</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.tokenpocket.pro/">TokenPocket</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Layer2</a>
          <a style="margin-top: 10px;">为了解决以太坊的可拓展性问题，开发者提出了两个方向的解决方案，Layer2 和分片 [ 以太坊升级（原名 ETH 2.0）的一个重要阶段 ] 。Layer2 字面意思是二层网络，相对的，当前的以太坊主网也称为一层网络（Layer1），二层网络也就是基于当前以太坊主网的一个系统。</a>
          <a style="margin-top: 10px;">Layer2 方案主要是从减轻 Layer1 负担的角度出发，将 Layer1 的大量计算需求搬到 Layer2 上。</a>
          <a style="margin-top: 10px;">主流 Layer2 方案有 ZK Rollup、Optimistic Rollup、Plasma、State Channels（状态通道）、Validium 等。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Optimistic Rollup</a>
          <a style="margin-top: 10px;">Rollup 中文叫做卷叠，是一种以太坊扩容方案的统称。Rollup 通过将链上计算转移至链下（二层网络），但同时将每笔交易的部分数据保留在链上达到提升以太坊网络性能的目的。</a>
          <a style="margin-top: 10px;">Rollup 方案主要分为两种：ZK Rollup 和 Optimistic Rollup。ZK Rollup 是采用零知识证明的 Rollup 方案，但由于 ZK Rollup 存在无法良好支持智能合约，生成零知识证明耗时较长的问题，2019 年 6 月，Optimistic Rollup 方案被提出。这个方案中去除了零知识证明，增加了惩罚机制，来达到保障数据安全性（略低于 ZK Rollup 方案）的同时又可以更方便地支持通用性智能合约的目的。</a>
          <a style="margin-top: 10px;">Optimistic Rollup 方案采用了 OVM（Optimistic 虚拟机），OVM 是功能完备、与 EVM（以太坊虚拟机）基本兼容的环境。通过 OVM，将 DApp 移植到 Layer2 变得简单，而且也使得项目方无需部署各种 Layer2 扩容方案，而只需接入 OVM，就可以使用其中的各类 Layer2 结构。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">开发团队 Optimism 基金会</a>
          <a style="margin-top: 10px;">Optimism 基金会是一家旨在产生公共利益并以可持续的方式运作的非营利性公司。希望通过开发开源软件，帮助用户公平获取互联网上的公共物品。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Optimism 浏览器</a>
          <a style="margin-top: 10px;">Optimism 区块浏览器是一个查询代币、交易、账户地址等信息的网站。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://optimistic.etherscan.io/">Optimistic Etherscan</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Optimism 生态</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Uniswap</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Hop Exchange</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">1inch</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Synthetix</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Kwenta</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Perpetual Protocol</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Chainlink</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">......</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-top: 10px;">
            <a>Optimism 官网：<a style="color: #1B8FFF;"
                 href="https://optimism.io/">https://optimism.io/</a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "optimismWallet",
  methods: {
    goEthereumWallet () {
      this.$router.push({ path: '/ethereum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goZksyncWallet () {
      this.$router.push({ path: '/zksync-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goArbitrumWallet () {
      this.$router.push({ path: '/arbitrum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goOptimismWallet () {
      this.$router.push({ path: '/optimism-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>