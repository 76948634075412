<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM 兼容链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。体验更多元化的以太坊生态</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuEvmPc/evm_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM兼容链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goBscWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BSC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goHecoWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">HECO</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goFtmWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FTM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goPolygonWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Polygon</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goGnosisWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Gnosis</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 290px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 EVM 兼容链 BSC</a>
        <van-image style="margin-top: 40px;"
                   src="img/pc/walletMenuEvmPc/bsc-wallet_dt2.svg"></van-image>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">BSC 简介</a>
          <a style="margin-top: 10px;">BSC 是加密货币交易所币安开发的采用股权证明机制 PoSA（Proof of Staked Authority）的区块链，是 BNB 智能链 BNB Smart Chain（原名为币安智能链 Binance Smart Chain）的缩写。</a>
          <a style="margin-top: 10px;">BSC 支持智能合约，并兼容 EVM，这个特性使开发者可以方便快捷地在 BSC 上部署智能合约和构建 DApp。与以太坊相比，BSC 的矿工费更低，交易结算速度更快。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">工作原理</a>
          <a style="margin-top: 10px;">BSC 采用股权证明机制 PoSA（Proof of Staked Authority），使其原生代币 BNB 的持有者通过投票选出 41 个验证者，其中 21 个进行链上的交易验证与区块生产，另 20 个作为候选验证者。</a>
          <a style="margin-top: 10px;">BSC 每 3 秒生产 1 个区块，当验证者提出一个有效区块，他将获得该区块中所有交易的矿工费作为奖励，但不同于比特币的矿工费机制，BSC 验证者没有区块奖励。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">EVM 兼容</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">采用股权证明机制 PoSA（Proof of Staked Authority）</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">区块时间约为 3 秒，交易结算速度快</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">了解更多</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://academy.binance.com/zh/articles/an-introduction-to-binance-smart-chain-bsc">什么是币安智能链？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div>
            <a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">imToken 与币安智能链（BSC）建立长期合作关系</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div>
            <a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">BSC、HECO 等 EVM 兼容链与公链是什么关系</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://support.binance.org/support/solutions/articles/67000659470-binance-smart-chain-white-paper">币安智能链白皮书</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">BSC 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包</a>
          <a style="margin-top: 10px;">安全小贴士：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何安全下载 imToken</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何验证 imToken App 的真伪</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">使用</a>
          <a style="margin-top: 10px;">进入 ETH 钱包的钱包首页，点击页面上方的「Ethereum」，在弹窗页面选择 「BNB Smart Chain」即可切换至 BSC 钱包进行转账、收款等操作并体验它的生态应用。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuEvmPc/bsc-wallet_dt3.svg"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">其他支持 BSC 的钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">MetaMask</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">SafePal</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">TokenPocket</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Trust Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Math Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">BSC 诞生背景</a>
          <a style="margin-top: 10px;">2019 年 4 月，加密货币交易所币安推出了币安链 Binance Chain。币安链采用 Tendermint 共识机制，吞吐量高，交易结算速度快，但不支持智能合约功能，可扩展性弱。</a>
          <a style="margin-top: 10px;">为解决该问题，币安在 2020 年 9 月推出了基于 PoSA 共识机制的币安智能链 BSC（Binance Smart Chain）。BSC 支持智能合约且兼容 EVM，开发者可以方便快捷地在链上部署智能合约和构建 DApp。</a>
          <a style="margin-top: 10px;">2022 年 2 月，币安将币安链和币安智能链更名。币安智能链（Binance Smart Chain）更名为 BNB 智能链（BNB Smart Chain），币安链（Binance Chain）则更名为 BNB 信标链（BNB Beacon Chain），两条链共同组成了一个新的区块链生态系统 BNB 链（BNB Chain）。</a>
          <div style="margin-top: 10px;">
            <a>了解更多：<a href="https://www.binance.com/zh-CN/support/announcement/854415cf3d214371a7b60cf01ead0918"
                 style="color: #1B8FFF;">关于 BNB Chain：币安智能链（BSC）的演变</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">开发团队币安</a>
          <a style="margin-top: 10px;">币安是目前世界上最大的数字货币交易所之一，由加拿大华人企业家赵长鹏于 2017 年创立。币安以「为整个区块链生态系统提供基础设施服务」为使命，以「让金钱在全球范围内自由流动」为愿景。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">EVM 兼容</a>
          <a style="margin-top: 10px;">EVM 是 Ethereum Virtual Machine 以太坊虚拟机的简称，EVM 之于以太坊，就像 CPU 之于计算机。EVM 允许在区块链上验证和执行代码，为代码在每台设备上以相同方式运行提供保障。</a>
          <a style="margin-top: 10px;">EVM 兼容指创造了一个类似 EVM 的代码执行环境，便于以太坊开发者将智能合约迁移至兼容链，而不必为其再从头开始编写智能合约的代码。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">BNB 价格</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://coinmarketcap.com/currencies/binance-coin/">CoinMarketCap: Cryptocurrency Market Capitalizations</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.coingecko.com/en/coins/binance-coin">CoinGecko</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.blockchain.com/prices/BNB?timeSpan=1&scale=0&style=line">Blockchain.com</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.feixiaohao.co/currencies/binance-coin/">非小号｜专注数字货币行业大数据分析</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">BSC 区块浏览器</a>
          <a style="margin-top: 10px;">BSC 区块浏览器是一个查询 BSC 区块、交易、代币、地址等信息的网站，实时同步更新 BSC 所有节点信息。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://bscscan.com/">BscScan</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.feixiaohao.co/currencies/binance-coin/">https://explorer.bitquery.io/bsc</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">BSC 官网：<a style="color: #1B8FFF;"
                 href="https://www.bnbchain.world/en/smartChain">https://www.bnbchain.world/en/smartChain</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">BSC 介绍：<a style="color: #1B8FFF;"
                 href="https://docs.binance.org/smart-chain/guides/bsc-intro.html">https://docs.binance.org/smart-chain/guides/bsc-intro.html</a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bscWallet",
  methods: {
    goBscWallet () {
      this.$router.push({ path: '/bsc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goHecoWallet () {
      this.$router.push({ path: '/heco-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goFtmWallet () {
      this.$router.push({ path: '/ftm-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goPolygonWallet () {
      this.$router.push({ path: '/polygon-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goGnosisWallet () {
      this.$router.push({ path: '/gnosis-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>