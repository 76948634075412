<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1100px;margin-top: 50px;height: 438px;background: #F1F4FD;border-radius: 24px 24px 24px 24px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">质押</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
      line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">一键委托代币，享有链上协议激励</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;
      line-height: 16px;text-align: left;font-style: normal;text-transform: none;margin-top: 26px;">使用imToken委托 ></a>
      </div>
      <van-image style="margin-top: 35px;margin-left: 125px;"
                 src="img/pc/staking/staking_dt.svg"></van-image>
    </div>
    <div style="width: 100%;height: 784px;background: #5E78E5;margin-top: 44px;display: flex; flex-direction: column; align-items: center;">
      <div style="margin: 50px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #FFFFFF;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;">现已支持</a>
      </div>
      <div style="display: flex; justify-content: center; align-items: center;">
        <van-image src="img/staking/stakinglb1.png"></van-image>
        <van-image style="margin-left: 150px;"
                   src="img/staking/stakinglb2.png"></van-image>
        <van-image style="margin-left: 150px;"
                   src="img/staking/stakinglb3.png"></van-image>
      </div>
      <div style="display: flex; justify-content: center; align-items: center;margin-top: 26px;">
        <van-image src="img/staking/stakinglb4.png"></van-image>
        <van-image style="margin-left: 150px;"
                   src="img/staking/stakinglb5.png"></van-image>
      </div>
    </div>
    <div style="width: 100%;margin-top: 70px;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Staking资料</a>
      <div style="display: flex; justify-content: center; align-items: center;margin-top: 45px;">
        <div style="display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">10,426,275</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken累计委托ATOMs</a>
          <van-image style="margin-top: 37px;"
                     src="img/pc/staking/staking_dt2.svg"></van-image>
        </div>
        <div style="margin-left: 358px;display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">0.35%</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken累计启用Cosmos钱包全网占比</a>
          <van-image style="margin-top: 37px;"
                     src="img/pc/staking/staking_dt3.svg"></van-image>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 403px;background: #F6F8FC;display: flex; flex-direction: column; align-items: center;justify-content: center;margin-top: 64px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">如何使用imToken参与Staking</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
    line-height: 25px;text-align: left;font-style: normal;text-transform: none;margin-top: 24px;">Staking 是PoS 共识机制的重要组成元件，允许用户透过质押代币参与挖矿与投票，并获得链上激励。</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;
    line-height: 25px;text-align: left;font-style: normal;text-transform: none;">了解更多 ></a>
      <div style="display: flex; align-items: center; margin-top: 64px;">
        <div style="display: flex;flex-direction: column;align-items: center;">
          <div><van-image src="img/pc/staking/staking_xt1.svg"></van-image></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">兑换代币</a>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-left: 310px;">
          <div><van-image src="img/pc/staking/staking_xt1.svg"></van-image></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">兑换代币</a>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-left: 310px;">
          <div><van-image src="img/pc/staking/staking_xt1.svg"></van-image></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">兑换代币</a>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 368px;display: flex; flex-direction: column; align-items: center;justify-content: center;margin-top: 64px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">为什么选择imToken</a>
      <div style="display: flex; align-items: center; margin-top: 64px;">
        <div style="display: flex;flex-direction: column;align-items: center;">
          <div><van-image src="img/pc/staking/staking_xt4.svg"></van-image></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">方便</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #787A8B;
        line-height: 26px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">一键质押，轻松透过imToken 查</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #787A8B;
        line-height: 26px;text-align: center;font-style: normal;text-transform: none;">看你的质押状态</a>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-left: 124px;">
          <div><van-image src="img/pc/staking/staking_xt5.svg"></van-image></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">安全</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #787A8B;
        line-height: 26px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">支持硬体钱包imKey，为资产安全</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #787A8B;
        line-height: 26px;text-align: center;font-style: normal;text-transform: none;">保驾护航</a>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-left: 124px;">
          <div><van-image src="img/pc/staking/staking_xt6.svg"></van-image></div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">更好的Staking</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #787A8B;
        line-height: 26px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">参与公链治理，为社区发展添砖加</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #787A8B;
        line-height: 26px;text-align: center;font-style: normal;text-transform: none;">瓦</a>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 278px;background: #F6F8FC;display: flex; flex-direction: column; align-items: center;justify-content: center;margin-top: 64px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">合作伙伴</a>
      <div style="display: flex; align-items: center; margin-top: 64px;">
        <van-image src="img/staking/staking_db7.png"></van-image>
        <van-image style="margin-left: 310px;"
                   src="img/staking/staking_db8.png"></van-image>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "stakingPcIndex",
}
</script>

<style>
</style>