<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM 兼容链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。体验更多元化的以太坊生态</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuEvmPc/evm_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM兼容链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goBscWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BSC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goHecoWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">HECO</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goFtmWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FTM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goPolygonWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Polygon</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goGnosisWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Gnosis</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 290px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">什么是 EVM 兼容链？</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="margin-top: 40px;">2021 年，以太坊网络上结算了超 6 万亿美元的交易，支持了 DeFi、NFT、游戏等区块链应用的蓬勃发展。
            但由于以太坊每秒只能处理约 15 笔交易，激增的交易需求导致其网络长期拥堵，交易费用居高不下。
          </a>
          <a style="margin-top: 14px;">由此一些开发者开始构建交易成本更低，结算速度更快的全新区块链，并提供兼容 EVM 的特性，
            以此更好地承接从以太坊生态外溢的需求和吸引新用户，这些链都可称作 EVM 兼容链。</a>
          <a style="margin-top: 11px;">EVM：是 Ethereum Virtual Machine 以太坊虚拟机的简称，EVM 之于以太坊，就像 CPU 之于计算机。EVM 允许在区块链上验证和执行代码，
            为代码在每台设备上以相同方式运行提供保障。</a>
          <a style="margin-top: 11px;">EVM 兼容链提供了一个类似 EVM 的代码执行环境，便于以太坊开发者将智能合约迁移至兼容链，而不必为其再从头开始编写智能合约的代码。</a>
          <a style="margin-top: 9px;">主流的 EVM 兼容链有
            <span style="cursor: pointer;color: #1B8FFF"
                  @click="goBscWallet">BSC</span>、<span style="cursor: pointer;color: #1B8FFF"
                  @click="goPolygonWallet">Polygon</span>、<span style="cursor: pointer;color: #1B8FFF"
                  @click="goHecoWallet">HECO</span> 等。</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "walletMenuEvmPcIndex",
  methods: {
    goBscWallet () {
      this.$router.push({ path: '/bsc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goHecoWallet () {
      this.$router.push({ path: '/heco-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goFtmWallet () {
      this.$router.push({ path: '/ftm-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goPolygonWallet () {
      this.$router.push({ path: '/polygon-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goGnosisWallet () {
      this.$router.push({ path: '/gnosis-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style>
</style>