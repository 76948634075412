<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM 兼容链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。体验更多元化的以太坊生态</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuEvmPc/evm_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM兼容链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goBscWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BSC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goHecoWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">HECO</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goFtmWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FTM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goPolygonWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Polygon</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goGnosisWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuEvmPc/evm_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Gnosis</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 290px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 EVM 兼容链 Fantom</a>
        <van-image style="margin-top: 40px;"
                   src="img/pc/walletMenuEvmPc/ftm-wallet_dt2.svg"></van-image>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Fantom 简介</a>
          <a style="margin-top: 10px;">Fantom 是韩国计算机科学家 Ahn Byung Ik 基于有向无环图 DAG 技术开发的高性能公链。Fantom 采用 Lachesis（一种 aBFT 算法）共识机制，致力于解决现存区块链技术的可拓展性和处理时间问题。</a>
          <a style="margin-top: 10px;">Fantom 支持智能合约，并兼容 EVM，这个特性使开发者可以方便快捷地在 Fantom 链上部署智能合约和构建 DApp。与以太坊相比，Fantom 的矿工费更低，交易结算速度更快。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">工作原理</a>
          <a style="margin-top: 10px;">Fantom 采用的共识机制 Lachesis 可以适配所有的分布式账本，这让 Fantom Opera 主网具备兼容 EVM 的特性。同时，Fantom 的模块化设计也使其足够灵活，开发人员可以在几分钟内将他们现有的基于以太坊的 DApp 移植到 Fantom Opera 主网上，大幅提升性能并降低成本。</a>
          <a style="margin-top: 10px;">此外，基于 Fantom 搭建的网络彼此独立，可自定义其代币、经济机制和治理模型，同时由于都采用了 Lachesis 共识机制，这些网络可以相互通信并从 Fantom 的底层技术中获益。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">EVM 兼容</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">采用 Lachesis 共识机制</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">交易结算速度快，手续费低</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">模块化</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">可定制化</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">了解更多</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://fantom.foundation/intro-to-fantom/">什么是 Fantom？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://fantom.foundation/research/wp_fantom_v1.6.pdf">Fantom 白皮书</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Fantom 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包。</a>
          <a style="margin-top: 10px;">安全小贴士：</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何安全下载 imToken</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">如何验证 imToken App 的真伪</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">使用</a>
          <a style="margin-top: 10px;">进入 ETH 钱包的资产页，点击页面上方的「Ethereum」，在弹窗页面选择「Fantom」即可切换至 Fantom 钱包进行转账、收款等操作并体验它的生态应用。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuEvmPc/ftm-wallet_dt3.svg"></van-image>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">其他支持 HECO 的钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">fWallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">MetaMask</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Math Wallet</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Edge</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">……</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Fantom 开发团队</a>
          <a style="margin-top: 10px;">韩国计算机科学家 Ahn Byung Ik 博士在 2018 年创立了基于 DAG 技术的高性能公链 Fantom，之后创始人 Ahn 离开了 Fantom，由位于澳大利亚悉尼的 Michael Kong 担任 Fantom 的首席执行官和首席信息官。Yearn 创始人 Andre Cronje 也在 2018 年 9 月份作为技术顾问加入开发团队，担任 Fantom 的 DeFi 架构师。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">有向无环图 DAG</a>
          <a style="margin-top: 10px;">在数学，特别是图论和计算机科学中，有向无环图指的是一个无回路的有向图。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Lachesis 共识机制</a>
          <a style="margin-top: 10px;">Lachesis 是 Fantom 采用的一种 aBFT（异步拜占庭容错算法）共识机制，能够在一个无准入门槛的开放环境中将节点扩展到世界各地，以实现去中心化。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">EVM 兼容</a>
          <a style="margin-top: 10px;">EVM 是 Ethereum Virtual Machine 以太坊虚拟机的简称，EVM 之于以太坊，就像 CPU 之于计算机。EVM 允许在区块链上验证和执行代码，为代码在每台设备上以相同方式运行提供保障。</a>
          <a style="margin-top: 10px;">EVM 兼容指创造了一个类似 EVM 的代码执行环境，便于以太坊开发者将智能合约迁移至兼容链，而不必为其再从头开始编写智能合约的代码。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">FTM 基本信息</a>
          <a style="margin-top: 10px;">FTM：Fantom 公链的原生代币</a>
          <a style="margin-top: 10px;">发行时间：2018.06.29</a>
          <a style="margin-top: 10px;">共识机制：Lachesis</a>
          <div style="margin-top: 10px;">
            <a>发行 / 流通总量：<a href="https://coinmarketcap.com/currencies/fantom/"
                 style="color: #1B8FFF;">https://coinmarketcap.com/currencies/fantom/</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">FTM 价格</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://coinmarketcap.com/currencies/binance-coin/">CoinMarketCap: Cryptocurrency Market Capitalizations</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.coingecko.com/en/coins/binance-coin">CoinGecko</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.blockchain.com/prices/BNB?timeSpan=1&scale=0&style=line">Blockchain.com</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://www.feixiaohao.co/currencies/binance-coin/">非小号｜专注数字货币行业大数据分析</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Fantom 区块浏览器</a>
          <a style="margin-top: 10px;">Fantom 区块浏览器是一个查询 Fantom 区块、交易、代币、地址等信息的网站。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://ftmscan.com/">FTMScan</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://explorer.fantom.network/">Fantom Explorer</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Fantom 官网：<a style="color: #1B8FFF;"
                 href="https://fantom.foundation/">https://fantom.foundation/</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">FTM 代币指南：<a style="color: #1B8FFF;"
                 href="https://fantom.foundation/blog/the-ultimate-guide-to-the-ftm-token/">https://fantom.foundation/blog/the-ultimate-guide-to-the-ftm-token/</a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ftmWallet",
  methods: {
    goBscWallet () {
      this.$router.push({ path: '/bsc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goHecoWallet () {
      this.$router.push({ path: '/heco-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goFtmWallet () {
      this.$router.push({ path: '/ftm-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goPolygonWallet () {
      this.$router.push({ path: '/polygon-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goGnosisWallet () {
      this.$router.push({ path: '/gnosis-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>