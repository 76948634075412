<template>
  <div style="margin-top: 80px;max-width: 100%;">
    <div style="width: 100%;height: 225px;margin: auto;background: linear-gradient( 89deg, #1080CE 0%, #42ABDE 100%);">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <div style="margin-top: 69px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #FFFFFF;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">imToken API 文档</a>
          <div style="margin-top: 57px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #FFFFFF;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">欢迎来到imToken开发者文档</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;margin-top: 33px;">
    <div style="width: 90%;">
      <div style="height: 237px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;">
        <div style="padding: 45px;">
          <van-image src="img/docs/docs_logo1.svg"></van-image>
          <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;line-height: 24px;
          font-style: normal;text-transform: none;">DApp</p>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #697781;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">帮助DApp开发者接入imToken</a>
          <br>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #697781;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">DApp</a>
        </div>
      </div>
      <div style="margin-top: 15px;height: 237px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;">
        <div style="padding: 45px;">
          <van-image src="img/docs/docs_logo2.svg"></van-image>
          <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;line-height: 24px;
          font-style: normal;text-transform: none;">Tokenlon-入职</p>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #697781;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">Tokenlon做市商对接文档</a>
        </div>
      </div>
      <div style="margin-top: 15px;height: 237px;height: 237px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;">
        <div style="padding: 45px;">
          <van-image src="img/docs/docs_logo3.svg"></van-image>
          <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;line-height: 24px;
          font-style: normal;text-transform: none;">Tokenlon-MMSK</p>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #697781;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">连接imToken Server与做市商服务的中间代理，用于降低两端的通信成本。</a>
        </div>
      </div>
      <div style="margin-top: 15px;height: 237px;height: 237px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;">
        <div style="padding: 45px;">
          <van-image src="img/docs/docs_logo4.svg"></van-image>
          <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;line-height: 24px;
          font-style: normal;text-transform: none;">Tokenlon-MMProxy</p>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #697781;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">基于市场的代币存储库，使用 Solidity 编写的以太坊智能合约 (MMProxy)</a>
        </div>
      </div>
      <div style="margin-top: 15px;height: 237px;height: 237px;background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;">
        <div style="padding: 45px;">
          <van-image src="img/docs/docs_logo5.svg"></van-image>
          <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #43454F;line-height: 24px;
          font-style: normal;text-transform: none;">Tokenlon-Jssdk</p>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #697781;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">提供与Tokenlon交易API对接的JS工具</a>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;margin-top: 99px;">
    <div style="width: 90%;">
      <div style="display: flex;align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #697781;
        line-height: 24px;text-align: center;text-align: center;text-transform: none;">@Consenlabs</a>
        <div style="margin-left: 31px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #43454F;
              line-height: 24px;text-align: center;font-style: normal;Terms">Terms</a>
        </div>
        <van-image style="margin-left: 27px;" src="img/docs/docs_xt1.svg"></van-image>
        <div style="margin-left: 8px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #43454F;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">中文</a>
        </div>
        <van-image src="img/docs/docs_xt2.svg"></van-image>
      </div>
      <div style="margin-top: 33px;display: flex;align-items: center;">
        <van-image src="img/docs/docs_xt3.svg"></van-image>
        <van-image style="margin-left: 22px;" src="img/docs/docs_xt4.svg"></van-image>
        <van-image style="margin-left: 22px;" src="img/docs/docs_xt5.svg"></van-image>
      </div>
    </div>
  </div>
  <br>
</template>

<script>
export default {
  name: "docsIndex",
}
</script>

<style>

</style>