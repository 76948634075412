<template>
  <div style="max-width: 100%;display: flex; flex-direction: column; align-items: center;">
    <div style="width: 90%;margin-top: 80px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #3A414B;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">常见问题</a>
      <br>
      <br>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A404A;
      line-height: 16px;text-align: left;font-style: normal;text-transform: none;">ETH、TRX、BTC 等钱包常见问题</a>
    </div>
    <div style="width: 90%;margin: 60px 0;">
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #3A414B;
        line-height: 21px;text-align: left;font-style: normal;text-transform: none;">TRX钱包</a>
        <br>
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">TRX 钱包转账失败或提示没有带宽怎么办 ？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">如何激活TRC钱包地址？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">为什么无法在TRX钱包首页添加和隐藏代币？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">如何获得宽带与能量？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">为什么我的宽带一直都是0？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">冻结TRX获取了宽带和能量，为什么还会转账失败？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #0B86C3;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">查看所有10篇文章</a>
        </div>
      </div>
    </div>
    <div style="width: 90%;">
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #3A414B;
        line-height: 21px;text-align: left;font-style: normal;text-transform: none;">热点问题</a>
        <br>
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">如何在imTkoen获得测试币?</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">如何从火币提币到钱包地址？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">从交易所提币到钱包，提示“地址无效”怎么办？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">导入助记词，提示不正确或无效怎么办？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">转账成功但对方没收到怎么办？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #3A404A;
          line-height: 19px;text-align: left;font-style: normal;text-transform: none;">为什么有些代币没有价格？</a>
        </div>
        <hr style="margin-top: 15px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #0B86C3;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">查看所有10篇文章</a>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
</template>

<script>
export default {
  name: "faqIndex",
}
</script>

<style>

</style>