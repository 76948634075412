<template>
  <div style="max-width: 100%;margin-top:80px;display: flex; justify-content: center; flex-direction: column;
  height: 197px;background: linear-gradient( 180deg, #038DC7 0%, #0870BA 100%);align-items: center;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 28px;color: #FFFFFF;line-height: 33px;
    text-align: left;font-style: normal;text-transform: none;">我们能帮您什么？</a>
    <div style="margin-top: 28px;width: 90%;background: #FFFFFF;height: 39px;display: flex; align-items: center;border-radius: 40px 40px 40px 40px;">
        <van-image style="padding: 0 10px;" src="img/support/supportss.svg"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #7F7E7E;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">搜索</a>
      </div>
  </div>
  <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 1028px;width: 100%;">
    <div style="width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">新手指南</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">快速入门/初阶教程/视频教程</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">常见问题</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">ETH、TRX、BTC等钱包常见问题</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">imToken Card</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">产品介绍/预注册/常见问题</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">Layer2&EVM</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">概念介绍/使用教程</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">以太坊质押</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">imToken以太坊质押/非托管/验证器</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">区块链知识</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">名词解释/区块浏览器/DApp/NFT</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">质押挖矿</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">质押挖矿/持比生息/PoS</a>
      </div>
    </div>
    <div style="margin-top: 28px;width: 90%;border-radius: 4px 4px 4px 4px;border: 1px solid #0B86C1;height: 94px;">
      <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 94px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">公告中心</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #0B86C2;
      line-height: 19px;text-align: left;font-style: normal;text-transform: none;">最新动态/官方发声/近期热点</a>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;height: 463px;width: 100%;">
    <div style="width: 90%;">
      <h3 style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 17px;color: #3A414B;line-height: 20px;
      text-align: left;font-style: normal;text-transform: none;">被推荐的文章</h3>
    </div>
    <ul style="margin-top: 24px;width: 90%;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414B;
    line-height: 16px;text-align: left;font-style: normal;text-transform: none;">
      <li>
        <a>如何确保代币安全？</a>
        <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
      </li>
      <li style="margin-top: 14px;">
        <a>如何安全下载imToken</a>
        <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
      </li>
      <li style="margin-top: 14px;">
        <a>想要低成本转账Tron USDT？别错过这份能量指南！</a>
        <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
      </li>
      <li style="margin-top: 14px;">
        <a>imToken年度报告| 致2023的每一次连接</a>
        <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
      </li>
      <li style="margin-top: 14px;">
        <a>imToken推出信用卡服务，预注册已开启！</a>
        <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
      </li>
      <li style="margin-top: 14px;">
        <a>探索无界互联：imToken的强大连接能力</a>
        <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
      </li>
      <li style="margin-top: 14px;">
        <a>imToken2.14.0，轻松连接更多插件&硬件钱包，交易更安全便捷</a>
      </li>
    </ul>
  </div>
  <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;width: 100%;">
    <div style="width: 90%;">
      <hr style="margin-top: 14px;border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
    </div>
  </div>
  <div style="display: flex; justify-content: center; flex-direction: column;align-items: center;width: 100%;height: 929px;">
    <h2 style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #3A414B;
    line-height: 23px;text-align: left;font-style: normal;text-transform: none;">最近的活动</h2>
    <div style="width: 90%;">
      <ul>
        <li style="padding: 20px 0;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 17px;color: #3A414B;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">通告</a>
          <br>
          <div style="margin-top: 29px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">imToken的多元化账户管理</a>
          </div>
          <br>
          <div style="display: flex; align-items: center; font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #72808D;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;">
            <a>文章创建于4天前</a>
            <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
          </div>
        </li>
        <hr style="border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <li style="padding: 20px 0;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 17px;color: #3A414B;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">官港博客</a>
          <br>
          <div style="margin-top: 29px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">质押平台走入分水岭：下一步是去中心化？</a>
          </div>
          <br>
          <div style="display: flex; align-items: center; font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #72808D;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;">
            <a>文章创建于5天前</a>
            <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
          </div>
        </li>
        <hr style="border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <li style="padding: 20px 0;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 17px;color: #3A414B;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">官港博客</a>
          <br>
          <div style="margin-top: 29px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">揭秘邪恶佣人攻击，警惕现实世界的安全漏洞 |imToken 钱包安全月报24期</a>
          </div>
          <br>
          <div style="display: flex; align-items: center; font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #72808D;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;">
            <a>文章创建于5天前</a>
            <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
          </div>
        </li>
        <hr style="border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <li style="padding: 20px 0;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 17px;color: #3A414B;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">功能介绍</a>
          <br>
          <div style="margin-top: 29px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">什么是派生路径？</a>
          </div>
          <br>
          <div style="display: flex; align-items: center; font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #72808D;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;">
            <a>文章创建于5天前</a>
            <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
          </div>
        </li>
        <hr style="border-radius: 0px 0px 0px 0px;border: 1px solid #E3E2E1;">
        <li style="padding: 20px 0;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 17px;color: #3A414B;
          line-height: 20px;text-align: left;font-style: normal;text-transform: none;">功能介绍</a>
          <br>
          <div style="margin-top: 29px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #3A414A;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">快速上手imToken新版本</a>
          </div>
          <br>
          <div style="display: flex; align-items: center; font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #72808D;
          line-height: 14px;text-align: left;font-style: normal;text-transform: none;">
            <a>文章创建于5天前</a>
            <van-image style="margin-left: 18px;" src="img/support/supportdx.svg"></van-image><a>0</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br>
  <br>
  <br>
</template>

<script>
export default {
  name: "supportIndex",
}
</script>

<style>

</style>