<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载imToken 钱包。体验快速、便宜且安全的以太坊</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">L2网路</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuLayer2Pc/layer2_dt.png"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goZksyncWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">zkSync</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goArbitrumWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Arbitrum</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goOptimismWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Optimism</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 198px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一文了解 Layer2 扩容方案 Arbitrum One</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <van-image style="margin-top: 40px;"
                     src="img/pc/walletMenuLayer2Pc/arbitrum_pc_dt1.svg"></van-image>
          <a style="margin-top: 10px;">为了更好地阅读本文，你需要先了解以太坊及其转账基本概念。</a>
          <div style="margin-top: 14px;">
            <a>阅读<a style="color: #1B8FFF;cursor: pointer"
                 @click="goEthereumWallet">这篇文章</a>的「以太坊简介」和「以太坊转账」了解。</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Arbitrum One 简介</a>
          <a style="margin-top: 25px;">由于以太坊主网（即 Layer1）上交易严重拥堵，交易费用居高不下，开发者提出了 Layer2 以改善以太坊网络性能，提升交易处理速度。</a>
          <div style="margin-top: 10px;">
            <a>Arbitrum One 是 Offchain Labs 推出的一款 Layer2 扩容方案，通过采用多轮交互型设计的 Optimistic Rollup 方案，以实现对以太坊网络的扩容目标。除了 Arbitrum One，Offchain Labs 还推出了基于<a style="color: #1B8FFF;cursor: pointer"
                 href="https://developer.offchainlabs.com/docs/AnyTrust">AnyTrust</a>技术的全新 Layer2 网络 Arbitrum Nova。</a>
          </div>
          <div style="margin-top: 10px;">
            <a>本文将着重介绍 Arbitrum One，如果你对 Arbitrum Nova 感兴趣，可点此<a style="color: #1B8FFF;cursor: pointer"
                 class="scrollToTopLink">了解更多</a>。</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">工作原理</a>
          <a style="margin-top: 10px;">Arbitrum One 基于 Optimistic Rollup 打造，通过多轮交互型欺诈性证明保证同步到 Layer1 的数据是有效的。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Rollup：Layer2 方案之一，也叫卷叠，通过将以太坊主网上交易的计算和存储转移至 Layer2 处理...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Optimistic Rollup：Optimistic Rollup 字面理解是乐观主义的 Rollup，它乐观地默认被同步到以...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">多轮交互型欺诈性证明：</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 60px;">
            <div style="width: 5px;height: 5px;border-radius: 50%;border: 1px solid #BFC5CD;"></div><a style="margin-left: 10px;">Layer2 的验证人将压缩后的数据同步到 Layer1，同时质押保证金，如果有人对该 Rollup 区块的...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 60px;">
            <div style="width: 5px;height: 5px;border-radius: 50%;border: 1px solid #BFC5CD;"></div><a style="margin-left: 10px;">验证人和挑战者在链下通过二分法的方式不断拆分存在争议的步骤，直至将争议范围缩减到一个...</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 60px;">
            <div style="width: 5px;height: 5px;border-radius: 50%;border: 1px solid #BFC5CD;"></div><a style="margin-left: 10px;">通过多轮交互型设计，Arbitrum One 可以以较低的成本在链上解决争议。</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">特点</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">极低的交易费用</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">无需信任</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">通过加密技术保障资金拥有以太坊主网级别的安全性</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">用户资产自持</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">VM 兼容</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">了解更多</a>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">相比其他 Layer2 Rollup 方案，Arbitrum 好在哪里？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Layer 2 能否解决以太坊拥堵问题？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">Vitalik：Rollup 不完全指南（精选）</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">Arbitrum One 钱包</a>
          <a style="margin-top: 10px;">以 imToken 为例，首先下载安装 imToken 并创建 / 导入钱包</a>
          <a style="margin-top: 10px;">进入 ETH 钱包的代币页，点击页面上方「Ethereum」，在弹出页面选择「Arbitrum」即可切换至 Arbitrum One 钱包进行转账、收款等操作并体验它的生态应用。</a>
          <van-image style="margin-top: 10px;"
                     src="img/pc/walletMenuLayer2Pc/arbitrum_pc_dt2.svg"></van-image>
          <div style="margin-top: 14px;">
            <a>详细教程：<a style="color: #1B8FFF;cursor: pointer"
                 class="scrollToTopLink">如何在 imToken 中体验 Arbitrum？</a></a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Layer2</a>
          <a style="margin-top: 10px;">为了解决以太坊的可拓展性问题，开发者提出了两个方向的解决方案，Layer2 和分片 [ 以太坊升级（原名 ETH 2.0）的一个重要阶段 ] 。Layer2 字面意思是二层网络，相对的，当前的以太坊主网也称为一层网络（Layer 1），二层网络也就是基于当前以太坊主网的一个系统。</a>
          <a style="margin-top: 10px;">Layer2 方案主要是从减轻 Layer1 负担的角度出发，将 Layer1 的大量计算需求搬到 Layer2 上。</a>
          <a style="margin-top: 10px;">主流 Layer2 方案有 ZK Rollup、Optimistic Rollup、Plasma、State Channels（状态通道）、Validium 等。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Optimistic Rollup</a>
          <a style="margin-top: 10px;">Rollup 方案主要分为两种：ZK Rollup 和 Optimistic Rollup。ZK Rollup 是采用零知识证明的 Rollup 方案，但由于 ZK Rollup 存在无法良好支持智能合约，生成零知识证明耗时较长的问题，2019 年 6 月，Optimistic Rollup 方案被提出。这个方案中去除了零知识证明，增加了惩罚机制，来达到保障数据安全性（略低于 ZK Rollup 方案）的同时又可以更方便地支持通用性智能合约的目的。</a>
          <a style="margin-top: 10px;">Optimistic Rollup 方案采用了 OVM（Optimistic 虚拟机），OVM 是功能完备、与 EVM（以太坊虚拟机）基本兼容的环境。通过 OVM，将 DApp 移植到 Layer2 变得简单，而且也使得项目方无需部署各种 Layer2 扩容方案，而只需接入 OVM，就可以使用其中的各类 Layer2 结构。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">开发团队 Offchain Labs</a>
          <a style="margin-top: 10px;">Arbitrum One 的开发团队 Offchain Labs 是一家专注于企业级应用的区块链初创公司，总部位于纽约。</a>
          <a style="margin-top: 10px;">Offchain Labs 的联合创始人 Ed Felten 是普林斯顿大学的计算机科学教授和奥巴马白宫前首席技术官。他在 2018 年秋天和其他三位联合创始人创办了 Offchain Labs。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Arbitrum One 浏览器</a>
          <a style="margin-top: 10px;">Arbitrum One 区块浏览器是一个查询 Arbitrum One 区块、交易、账户地址等信息的网站。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://explorer.arbitrum.io/">Arbitrum One Chain Explorer</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://arbiscan.io/">Arbiscan（Arbitrum One Explorer）</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Arbitrum One 生态</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Aave</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Balancer</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Chainlink</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">DeBank</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Curve</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Etherscan</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">imToken</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Infura</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">DODO</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Celer Bridge</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 35px;">
            <a style="margin-left: 10px;">......</a>
          </div>

          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">更多资料</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Arbitrum One 官网：<a style="color: #1B8FFF;"
                 href="https://arbitrum.io/">https://arbitrum.io/</a></a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Offchain Labs 官网：<a style="color: #1B8FFF;"
                 href="https://offchainlabs.com/">https://offchainlabs.com/</a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "arbitrumWallet",
  methods: {
    goEthereumWallet () {
      this.$router.push({ path: '/ethereum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goZksyncWallet () {
      this.$router.push({ path: '/zksync-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goArbitrumWallet () {
      this.$router.push({ path: '/arbitrum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
    goOptimismWallet () {
      this.$router.push({ path: '/optimism-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>