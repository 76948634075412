<template>
  <div style="max-width: 100%;margin-top: 80px;display: flex; flex-direction: column; align-items: center;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
   </div>
   <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
   <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenuLayer2/walletMenuLayer2_zkSync.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">zkSync</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenuLayer2/walletMenuLayer2_Arbitrum.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">Arbitrum</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
    <hr style="width: 100%;border-radius: 0px 0px 0px 0px;border: 1px solid #F3F6FB;">
    <div style="width: 92%;">
      <div style="display: flex; width: 100%; ">
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;">
            <van-image style="width: 24px;height: 24px;" src="img/walletMenuLayer2/walletMenuLayer2_Optimism.svg"></van-image>
            <div style="margin-left: 9px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">Optimism</a>
            </div>
          </div>
        </div>
        <div style="flex: 1; text-align: right;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #969799;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "walletMenuLayer2Index",
}
</script>

<style>

</style>