<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载imToken 钱包。体验快速、便宜且安全的以太坊</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">L2网路</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenuLayer2Pc/layer2_dt.png"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goZksyncWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">zkSync</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goArbitrumWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Arbitrum</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goOptimismWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenuLayer2Pc/layer2_xt_pc3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">Optimism</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 198px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">什么是Layer2？</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a style="margin-top: 40px;">自以太坊2015 年诞生以来，其生态日益丰富，如DeFi、NFT、游戏等快速发展。但与此同时，以太坊网络的可拓展性无法满足快速
            增长的需求，每秒只能处理约15 笔交易，导致交易费用居高不下，许多应用无法大规模普及。
          </a>
          <a style="margin-top: 14px;">为了解决该问题，开发者提出了两个方向的解决方案，Layer2 和分片[ 以太坊升级（原名ETH 2.0）的一个重要阶段]。</a>
          <a style="margin-top: 11px;">Layer2 字面意思是二层网络，相对的，当前的以太坊主网称为一层网络（Layer1）。 Layer2 通过将Layer1 上的大量计算需求转移到Layer2 上来减轻Layer1 负担，从而实现提升以太坊网络的可拓展性。</a>
          <a style="margin-top: 9px;">常见Layer2 项目有
            <span style="cursor: pointer;color: #1B8FFF"
                  @click="goArbitrumWallet">Arbitrum</span>、<span style="cursor: pointer;color: #1B8FFF"
                  @click="goZksyncWallet">zkSync</span>、<span style="cursor: pointer;color: #1B8FFF"
                  @click="goOptimismWallet">Optimism</span>等。</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "walletMenuLayer2PcIndex",
  methods: {
    goZksyncWallet () {
      this.$router.push({ path: '/zksync-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goArbitrumWallet () {
      this.$router.push({ path: '/arbitrum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goOptimismWallet () {
      this.$router.push({ path: '/optimism-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style>
</style>