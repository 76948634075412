<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1100px;margin-top: 50px;height: 438px;background: #F3FAFC;;border-radius: 24px 24px 24px 24px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">多链多网络</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">安全访问你的链上代币</a>
      </div>
      <van-image style="margin-top: 35px;margin-left: 125px;"
                 src="img/pc/wallet/wallet_dt.svg"></van-image>
    </div>
    <div style="width: 1100px;margin-top: 50px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">多链多网络</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
        line-height: 25px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">轻松使用链上代币BTC、ETH、EOS、ATOM、BCH、TRX、LTC、CKB、DOT、KSM、FIL、XTZ</a>
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;height: 550px;width: 1100px;margin-top: 45px;padding: 30px;">
          <div style="display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_ytf.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">以太坊</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援所有以太坊代币(ERC20,ERC721代币)</a>
                </div>
              </div>
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_btb.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">比特币</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援USDT OMNI，以及HD钱包和隔离见证</a>
                </div>
              </div>
            </div>
          </div>
          <hr style="margin-top:20px;border: 1px solid #F0F0F9;">
          <div style="display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_eos.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">EOS</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援EOS 代币、资源管理、以及投票</a>
                </div>
              </div>
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_yz.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">原子</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援ATOM 代币，以及质押挖矿和投票</a>
                </div>
              </div>
            </div>
          </div>
          <hr style="margin-top:20px;border: 1px solid #F0F0F9;">
          <div style="display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_trx.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">TRX</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援TRX, TRC10, TRC20 代币，以及资源管理</a>
                </div>
              </div>
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_ltc.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">LTC</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援LTC, 以及转账收款</a>
                </div>
              </div>
            </div>
          </div>
          <hr style="margin-top:20px;border: 1px solid #F0F0F9;">
          <div style="display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_sw.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">生物安全信息交换所</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援BCH，以及转帐收款</a>
                </div>
              </div>
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_ckb.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">CKB</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支援CKB，以及Nervos DAO（即将上线）</a>
                </div>
              </div>
            </div>
          </div>
          <hr style="margin-top:20px;border: 1px solid #F0F0F9;">
          <div style="display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_d.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">点</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支持DOT 代币、质押和投票</a>
                </div>
              </div>
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_ksm.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">凯斯曼</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支持 KSM 代币、质押和投票</a>
                </div>
              </div>
            </div>
          </div>
          <hr style="margin-top:20px;border: 1px solid #F0F0F9;">
          <div style="display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_z.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">在</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支持发送/接收FIL</a>
                </div>
              </div>
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex;width: 520px;">
                <div>
                  <van-image src="img/wallet/wallet_xtz.png"></van-image>
                </div>
                <div style="margin-left: 13px;">
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;line-height: 38px;
                  text-align: center;font-style: normal;text-transform: none;">XTZ</a>
                  <br>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 10px;color: #787A8B;line-height: 0px;
                  text-align: center;font-style: normal;text-transform: none;">支持XTZ代币、质押和投票</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 70px;">支援主流稳定币USDT, USDC, DAI, PAX, TUSD,USDX</a>
        <div style="background: #FFFFFF;box-shadow: 0px 8px 22px 0px rgba(0,0,0,0.1);border-radius: 8px 8px 8px 8px;height: 209px;width: 1100px;margin-top: 70px;padding: 30px;">
          <div style="display: flex; align-items: center; justify-content: center;">
            <div style="width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_tdb.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">泰达币</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="margin-left: 16px;width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_mgny.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">美国农业部</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_kd.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">快点</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="margin-left: 16px;width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_usdp.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">USDP</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_tusd.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">TUSD</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="margin-left: 16px;width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_myzs.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">美元指数</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_bszz.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">巴士总站</p>
            </div>
            <div style="height: 80px;border: 1px solid #F0F0F9;"></div>
            <div style="margin-left: 16px;width: 132px;height: 161px;
            display: flex; align-items: center; justify-content: center; flex-direction: column;">
              <van-image src="img/wallet/wallet_hsd.png"></van-image>
              <p style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;
              color: #43454F;line-height: 24px;text-align: center;font-style: normal;text-transform: none;">哈士德</p>
            </div>
          </div>
        </div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;margin-top: 70px;">不止当前，我们仍在探索更多...</a>
        <div style="margin-top: 75px;background: #F6F8FB;height: 457px;width: 1100px;display: flex; justify-content: center; flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">安全使用</a>
          <div style="display: flex; align-items: center;justify-content: center;margin-top: 50px;">
            <div style="width: 311px;display: flex; align-items: center; flex-direction: column;">
              <van-image src="img/pc/wallet/wallet_xt1.png"></van-image>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">用户安全至上</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">建构全方位风险控制系统</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">强化用户钱包的安全性</a>
            </div>
            <div style="width: 311px;display: flex; align-items: center; flex-direction: column;margin-left: 50px;">
              <van-image src="img/pc/wallet/wallet_xt2.png"></van-image>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">离线签名</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">支援硬体钱包& 冷钱包</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">避免私钥触网，降低被盗风险</a>
            </div>
            <div style="width: 311px;display: flex; align-items: center; flex-direction: column;margin-left: 50px;">
              <van-image src="img/pc/wallet/wallet_xt3.png"></van-image>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">第三方安全审计</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">与慢雾、PeckShield、Cure53、知道创</a>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">宇等知名安全公司合作</a>
            </div>
          </div>
        </div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 70px;">轻松转账</a>
        <div style="display: flex; align-items: center;justify-content: center;margin-top: 50px;">
          <div style="width: 311px;display: flex; align-items: center; flex-direction: column;">
            <van-image src="img/pc/wallet/wallet_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">生物辨识快速验证</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">享受更安全方便的转帐体验</a>
          </div>
          <div style="width: 311px;display: flex; align-items: center; flex-direction: column;margin-left: 50px;">
            <van-image src="img/pc/wallet/wallet_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">一键转账加速</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">自动计算最优惠的矿工费</a>
          </div>
          <div style="width: 311px;display: flex; align-items: center; flex-direction: column;margin-left: 50px;">
            <van-image src="img/pc/wallet/wallet_xt6.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
              line-height: 38px;text-align: left;font-style: normal;text-transform: none;">多链域名</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #787A8B;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">支持.eth、.bit等多链域名</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 180px;">
    <div style="width: 1100px;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
        </div>
        <van-button type="primary"
                    color="#468BE0"
                    style="width: 137px;height: 47px;margin-top: 20px;"
                    to="download">
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
        </van-button>
      </div>
      <div style="margin-right: 10px">
        <van-image src="img/pc/home/pc_dt4.svg"></van-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "walletPcIndex",
}
</script>

<style>
</style>