<!-- NotFoundComponent.vue -->
<template>
  <div style="display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <h1>404 Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {
  name: "notFoundIndex",
}
</script>

