<template>
  <div style="max-width: 100%;">
    <div style="width: 90%;height: 240px;margin: auto;">
      <div style="margin-top: 150px;width: 412;height: 292px;display: flex; flex-direction: column; align-items: center;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #000000;line-height: 28px;
        text-align: left;font-style: normal;text-transform: none;">安全中心</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
        text-align: left;font-style: normal;text-transform: none;margin-top: 5px;">十分钟降低 90% 资产被盗风险</a>
        <van-image style="margin-top: 39px;"
                   src="img/security/securitydt.png"></van-image>
      </div>
    </div>
  </div>
  <div style="margin-top: 39px;max-width: 100%;height: 271px;background-image: url('img/security/securitybj.svg');">
    <div style="padding: 30px 15px;height: 271px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #000000;line-height: 28px;
      text-align: left;font-style: normal;text-transform: none;">风险排除</a>
      <br>
      <div style="margin-top: 5px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
      text-align: left;font-style: normal;text-transform: none;">手把手带你自查和排除风险</a>
      </div>
      <div style="width: 100%;height: 114px;border: 1px solid #EEF0F0;margin-top: 23px;display: flex; justify-content: space-between;align-items: center;padding: 0 15px;border-radius: 7px;">
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #4B4E57;line-height: 19px;
          text-align: left;font-style: normal;text-transform: none;">下载了假imToken怎么办？</a>
          <div style="width: 228px;margin-top: 10px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8A;line-height: 14px;
          text-align: left;font-style: normal;text-transform: none;">通过非官网渠道下载imToken均存在安全隐患，将面临资产被盗的风险。</a>
          </div>
        </div>
        <div style="margin-right: 30px;"><a style="font-size: 15px;font-weight: bold;width: 7px;height: 12px;color: #1891FF;">></a></div>
      </div>
    </div>
  </div>
  <div style="padding: 0 15px;margin-top: 20px;">
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #000000;line-height: 28px;
    text-align: left;font-style: normal;text-transform: none;">联系我们</a>
    <br>
    <div style="margin-top: 5px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8A;line-height: 19px;
    text-align: left;font-style: normal;text-transform: none;">遇到问题？快速联系我们</a>
    </div>
    <div style="width: 100%;height: 114px;border: 1px solid #EEF0F0;margin-top: 23px;padding: 0 15px;border-radius: 7px;
    display: flex; flex-direction: column;justify-content: center; align-items: center;">
      <div style="border-radius: 7px;border: 1px solid #468BDF;width: 99%;height: 42px;display: flex; align-items: center; justify-content: center;">
        <van-image src="img/security/securityyj.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #468BDF;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">邮件反馈</a>
      </div>
      <a style="font-family: PingFang HK, PingFang HK; font-weight: 400; font-size: 15px; color: #787A8A; line-height: 18px; text-align: left; 
      font-style: normal; text-transform: none;margin-top: 15px;">预计在24小时内回复，在线时间：10：00-18：00</a>
    </div>
  </div>
  <br>
  <br>
  <br>
</template>

<script>
export default {
  name: "securityIndex",
}
</script>

<style>
</style>