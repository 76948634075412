import { createRouter, createWebHistory } from 'vue-router'

import app_Downloads from '@/components/App_Downloads'
import homeIndex from '@/components/homeIndex'
import securityIndex from '@/components/securityIndex'
import walletIndex from '@/components/walletIndex'
import supportIndex from '@/components/supportIndex'
import dappIndex from '@/components/dappIndex'
import stakingIndex from '@/components/stakingIndex'
import blogIndex from '@/components/blogIndex'
import aboutIndex from '@/components/aboutIndex'
import faqIndex from '@/components/faqIndex'
import walletMenuIndex from '@/components/walletMenuIndex'
import docsIndex from '@/components/docsIndex'
import brandIndex from '@/components/brandIndex'
import walletMenuEvmIndex from '@/components/walletMenuEvmIndex'
import walletMenuLayer2Index from '@/components/walletMenuLayer2Index'
import contactIndex from '@/components/contactIndex'
import notFoundIndex from '@/components/notFoundIndex'

//Pc
import homePcIndex from '@/componentsPc/homePcIndex'
import supportPcIndex from '@/componentsPc/supportPcIndex'
import downloadsPcIndex from '@/componentsPc/downloadsPcIndex'
import blogPcIndex from '@/componentsPc/blogPcIndex'
import stakingPcIndex from '@/componentsPc/stakingPcIndex'
import walletPcIndex from '@/componentsPc/walletPcIndex'
import dappPcIndex from '@/componentsPc/dappPcIndex'
import walletMenuLayer2PcIndex from '@/componentsPc/walletMenuLayer2PcIndex'
import docsPcIndex from '@/componentsPc/docsPcIndex'
import aboutPcIndex from '@/componentsPc/aboutPcIndex'
import brandPcIndex from '@/componentsPc/brandPcIndex'
import securityPcIndex from '@/componentsPc/securityPcIndex'
import faqPcIndex from '@/componentsPc/faqPcIndex'
import walletMenuEvmPcIndex from '@/componentsPc/walletMenuEvmPcIndex'
import walletMenuPcIndex from '@/componentsPc/walletMenuPcIndex'
import notFoundPcIndex from '@/componentsPc/notFoundPcIndex'

//公链子页面
import ethereumWallet from '@/componentsPc/walletPc/ethereum-wallet'
import bitcoinWallet from '@/componentsPc/walletPc/bitcoin-wallet'
import eosWallet from '@/componentsPc/walletPc/eos-wallet'
import cosmosWallet from '@/componentsPc/walletPc/cosmos-wallet'
import trxWallet from '@/componentsPc/walletPc/trx-wallet'
import ltcWallet from '@/componentsPc/walletPc/ltc-wallet'
import bchWallet from '@/componentsPc/walletPc/bch-wallet'
import ckbWallet from '@/componentsPc/walletPc/ckb-wallet'
import polkadotWallet from '@/componentsPc/walletPc/polkadot-wallet'
import kusamaWallet from '@/componentsPc/walletPc/kusama-wallet'
import filWallet from '@/componentsPc/walletPc/fil-wallet'
import xtzWallet from '@/componentsPc/walletPc/xtz-wallet'

//Layer2子页面
import zksyncWallet from '@/componentsPc/zksyncWalletPc/zksync-wallet'
import arbitrumWallet from '@/componentsPc/zksyncWalletPc/arbitrum-wallet'
import optimismWallet from '@/componentsPc/zksyncWalletPc/optimism-wallet'

//EVM子页面
import bscWallet from '@/componentsPc/evmWalletPc/bsc-wallet'
import hecoWallet from '@/componentsPc/evmWalletPc/heco-wallet'
import ftmWallet from '@/componentsPc/evmWalletPc/ftm-wallet'
import polygonWallet from '@/componentsPc/evmWalletPc/polygon-wallet'
import gnosisWallet from '@/componentsPc/evmWalletPc/gnosis-wallet'

let routes
if (isMobileDevice()) {
  routes = [
    {
      path: '/download',
      component: app_Downloads,
    },
    {
      path: '/',
      component: homeIndex,
    },
    {
      path: '/security',
      component: securityIndex,
    },
    {
      path: '/wallet',
      component: walletIndex,
    },
    {
      path: '/support',
      component: supportIndex,
    },
    {
      path: '/dapp',
      component: dappIndex,
    },
    {
      path: '/staking',
      component: stakingIndex,
    },
    {
      path: '/blog',
      component: blogIndex,
    },
    {
      path: '/about',
      component: aboutIndex,
    },
    {
      path: '/faq',
      component: faqIndex,
    },
    {
      path: '/public-chain-wallet-menu',
      component: walletMenuIndex,
    },
    {
      path: '/docs',
      component: docsIndex,
    },
    {
      path: '/brand',
      component: brandIndex,
    },
    {
      path: '/evm-chain-wallet-menu',
      component: walletMenuEvmIndex,
    },
    {
      path: '/layer2-wallet-menu',
      component: walletMenuLayer2Index,
    },
    {
      path: '/contact',
      component: contactIndex,
    },
    {
      path: '/:catchAll(.*)',
      component: notFoundIndex,
    },
  ]
} else {
  routes = [
    {
      path: '/',
      component: homePcIndex,
    },
    {
      path: '/support',
      component: supportPcIndex,
    },
    {
      path: '/download',
      component: downloadsPcIndex,
    },
    {
      path: '/blog',
      component: blogPcIndex,
    },
    {
      path: '/staking',
      component: stakingPcIndex,
    },
    {
      path: '/wallet',
      component: walletPcIndex,
    },
    {
      path: '/dapp',
      component: dappPcIndex,
    },
    {
      path: '/layer2-wallet-menu',
      component: walletMenuLayer2PcIndex,
    },
    {
      path: '/docs',
      component: docsPcIndex,
    },
    {
      path: '/about',
      component: aboutPcIndex,
    },
    {
      path: '/brand',
      component: brandPcIndex,
    },
    {
      path: '/security',
      component: securityPcIndex,
    },
    {
      path: '/faq',
      component: faqPcIndex,
    },
    {
      path: '/evm-chain-wallet-menu',
      component: walletMenuEvmPcIndex,
    },
    {
      path: '/public-chain-wallet-menu',
      component: walletMenuPcIndex,
    },
    {
      path: '/ethereum-wallet',
      component: ethereumWallet,
    },
    {
      path: '/bitcoin-wallet',
      component: bitcoinWallet,
    },
    {
      path: '/eos-wallet',
      component: eosWallet,
    },
    {
      path: '/cosmos-wallet',
      component: cosmosWallet,
    },
    {
      path: '/trx-wallet',
      component: trxWallet,
    },
    {
      path: '/ltc-wallet',
      component: ltcWallet,
    },
    {
      path: '/bch-wallet',
      component: bchWallet,
    },
    {
      path: '/ckb-wallet',
      component: ckbWallet,
    },
    {
      path: '/polkadot-wallet',
      component: polkadotWallet,
    },
    {
      path: '/kusama-wallet',
      component: kusamaWallet,
    },
    {
      path: '/fil-wallet',
      component: filWallet,
    },
    {
      path: '/xtz-wallet',
      component: xtzWallet,
    },
    {
      path: '/zksync-wallet',
      component: zksyncWallet,
    },
    {
      path: '/arbitrum-wallet',
      component: arbitrumWallet,
    },
    {
      path: '/optimism-wallet',
      component: optimismWallet,
    },
    {
      path: '/bsc-wallet',
      component: bscWallet,
    },
    {
      path: '/heco-wallet',
      component: hecoWallet,
    },
    {
      path: '/ftm-wallet',
      component: ftmWallet,
    },
    {
      path: '/polygon-wallet',
      component: polygonWallet,
    },
    {
      path: '/gnosis-wallet',
      component: gnosisWallet,
    },
    {
      path: '/:catchAll(.*)',
      component: notFoundPcIndex,
    },
    // Add desktop-specific routes here...
  ]
}
window.scrollTo(0, 0)
const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes,
})

function isMobileDevice() {
  // 根据 User Agent 或其他条件判断是否为移动设备
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export default router
