<template>
  <div style="max-width: 100%;">
    <div style="background-color: #F5F7FA;height: 50px;max-width: 100%;display: flex; justify-content: center; align-items: center;flex-direction: column;">
      <div style="padding: 0 40px; display: flex;align-items: center;height: 50px;width: 1165px;">
        <van-image src="img/laba.png"></van-image><a style="margin-left: 15px;font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 12px;
        color: #787A8B;line-height: 30px;text-align: center;font-style: normal;text-transform: none;">imToken 年度报告｜致 2023 的每一次连接</a>
      </div>
    </div>
    <div style="max-width: 100%;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 55px;">
      <div style="width: 1100px;height: 438px;display: flex;align-items: center;background: #FAFAFA;border-radius: 24px 24px 24px 24px;">
        <div style="margin-left: 80px;">
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;line-height: 38px;text-align: left;
          font-style: normal;text-transform: none;">
            <a>让每个人平等自由地享受有意义的</a>
            <br>
            <a>数字生活</a>
          </div>
          <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;line-height: 36px;text-align: left;
          font-style: normal;text-transform: none;margin-top: 20px;">
            <a>imToken，一款安全放心、简单易用的数字</a>
            <br>
            <a>钱包，超千万用户信赖</a>
          </div>
          <div style="margin-top: 15px;margin-left: 10px;">
            <van-button type="primary"
                        color="#468BE0"
                        style="width: 132px;height: 46px;"
                        to="download">
              <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">立即下载</a>
            </van-button>
          </div>
        </div>
        <van-image style="margin-left: 18px;width: 500px;height: 500px;"
                   src="img/pc/home/banner.png"></van-image>
      </div>
    </div>
    <div style="height: 430px;background: #468bdf;margin-top: 120px;display: flex; justify-content: center; align-items: center;flex-direction: column;">
      <div style="width: 1246px;height: 430px;display: flex; justify-content: center; align-items: center;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #FFFFFF;line-height: 38px;
        text-align: center;font-style: normal;text-transform: none;">imToken</a>
        <div style="margin-top: 15px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #FFFFFF;line-height: 28px;
          text-align: left;font-style: normal;text-transform: none;">为全球超过150个国家的千万用户提供安全、可信赖的非托管钱包服务</a>
        </div>
        <div style="display: flex;align-items: center;margin-top: 40px;">
          <div style="border-radius: 8px 8px 8px 8px;background: #5997e2;width: 372px;height: 179px;
          display: flex; justify-content: center; align-items: center;flex-direction: column;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">7 周年</a>
            <div style="margin-top: 17px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
              text-align: center;font-style: normal;text-transform: none;">成立于2016年</a>
            </div>
          </div>
          <div style="border-radius: 8px 8px 8px 8px;background: #5997e2;width: 372px;height: 179px;
          display: flex; justify-content: center; align-items: center;flex-direction: column;margin-left: 17px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">1500万</a>
            <div style="margin-top: 17px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
              text-align: center;font-style: normal;text-transform: none;">用户正在使用</a>
            </div>
          </div>
          <div style="border-radius: 8px 8px 8px 8px;background: #5997e2;width: 372px;height: 179px;
          display: flex; justify-content: center; align-items: center;flex-direction: column;margin-left: 17px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 32px;color: #FFFFFF;line-height: 30px;
            text-align: center;font-style: normal;text-transform: none;">150+</a>
            <div style="margin-top: 17px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #AECBF2;line-height: 28px;
              text-align: center;font-style: normal;text-transform: none;">覆盖国家和地区</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;display: flex; justify-content: center; align-items: center;flex-direction: column;">
      <div style="width: 1100px;height: 430px;display: flex; justify-content: center; align-items: center;flex-direction: column;padding: 90px 0px">
        <div style="display: flex;align-items: center;">
          <div style="margin-left: 80px;"><van-image src="img/pc/home/wallet.svg"></van-image></div>
          <div style="margin-left: 140px;margin-right: 85px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #181D1E;
            line-height: 30px;text-align: center;font-style: normal;text-transform: none;">多链多网络</a>
            <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #777B8B;line-height: 27px;
            text-align: left;font-style: normal;text-transform: none;margin-top: 16px;width: 460px;">
              <a>轻松访问BTC、ETH、BNB、TRX、DOT、MATIC、</a>
              <br>
              <a>LTC、BCH、ATOM、OP、FIL、ARB、EOS、</a>
              <br>
              <a>KSM、XTZ、CKB</a>
            </div>
            <div style="margin-top: 45px;display: flex;">
              <div>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
              text-align: left;font-style: normal;text-transform: none;">35+</a>
                <br>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
              text-align: left;font-style: normal;text-transform: none;">已支援网络</a>
              </div>
              <div style="margin-left: 30px;margin-right: 30px;border-left: 2px solid #E2E6EA;height: 60px;"></div>
              <div>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
                text-align: left;font-style: normal;text-transform: none;">80万+</a>
                <br>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
                text-align: left;font-style: normal;text-transform: none;">已收录代币</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 1100px;height: 430px;display: flex; justify-content: center; align-items: center;flex-direction: column;padding: 90px 0px">
        <div style="display: flex;align-items: center;">
          <div style="margin-left: 80px;"><van-image src="img/pc/home/imkey.svg"></van-image></div>
          <div style="margin-left: 140px;margin-right: 85px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #181D1E;
            line-height: 30px;text-align: center;font-style: normal;text-transform: none;">离线生成和储存私钥</a>
            <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #777B8B;line-height: 27px;
            text-align: left;font-style: normal;text-transform: none;margin-top: 16px;width: 460px;">
              <a>搭配imKey Pro硬体钱包，离线签名更安心</a>
            </div>
            <div style="margin-top: 50px;display: flex;">
              <div>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
              text-align: left;font-style: normal;text-transform: none;">￥688</a>
                <br>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
              text-align: left;font-style: normal;text-transform: none;">当前售价</a>
              </div>
              <div>
                <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 25px;color: #458CDF;line-height: 30px;
                text-align: left;font-style: normal;text-transform: none;"></a>
                <br>
                <div style="display: flex;align-items: center;">
                  <div style="margin-left: 30px;margin-right: 30px;border-left: 2px solid #E2E6EA;height: 20px;"></div>
                  <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #777B8B;line-height: 28px;
                text-align: left;font-style: normal;text-transform: none;"
                     href="https://shop42404838.m.youzan.com/v2/feature/d5bMdxB3Ab?dc_ps=3252957778326674432.200001">立即购买 ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;height: 330px;background: #F6F8FB;display: flex; justify-content: center; align-items: center;flex-direction: column;">
        <div style="width: 1100px;display: flex; justify-content: center; align-items: center;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;line-height: 38px;
          text-align: center;font-style: normal;text-transform: none;">生态伙伴</a>
          <div style="display: flex;justify-content: space-between;width: 100%;margin-top: 50px;">
            <van-image src="img/pc/home/pc_hb1.svg"></van-image>
            <van-image src="img/pc/home/pc_hb2.svg"></van-image>
            <van-image src="img/pc/home/pc_hb3.svg"></van-image>
            <van-image src="img/pc/home/pc_hb4.svg"></van-image>
            <van-image src="img/pc/home/pc_hb5.svg"></van-image>
          </div>
          <div style="display: flex;justify-content: space-between;width: 100%;margin-top: 50px;">
            <van-image src="img/pc/home/pc_hb6.svg"></van-image>
            <van-image src="img/pc/home/pc_hb7.svg"></van-image>
            <van-image src="img/pc/home/pc_hb8.svg"></van-image>
            <van-image src="img/pc/home/pc_hb9.svg"></van-image>
          </div>
        </div>
      </div>
      <div style="width: 100%;height: 330px;display: flex; justify-content: center; align-items: center;flex-direction: column;">
        <div style="width: 1100px;display: flex; justify-content: center; align-items: center;flex-direction: column;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;line-height: 38px;
          text-align: center;font-style: normal;text-transform: none;">联系我们</a>
          <div style="display: flex;margin-top: 50px;width: 1100px;">
            <div style="display: flex; justify-content: center; align-items: center;flex-direction: column;">
              <van-image src="img/pc/home/pc_xt1.svg"></van-image>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #43454F;line-height: 38px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 24px;"><a>官方论坛</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>https://imtoken.fans</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>开放的数字钱包用户互助平台</a></div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center;flex-direction: column;margin-left: 270px;">
              <van-image src="img/pc/home/pc_xt2.svg"></van-image>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #43454F;line-height: 38px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 24px;"><a>提交反馈</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>support@token.im</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>产品使用遇到问题？尝试向用户支</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>持团队寻找答案吧</a></div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center;flex-direction: column;margin-left: 270px;">
              <van-image src="img/pc/home/pc_xt3.svg"></van-image>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #43454F;line-height: 38px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 24px;"><a>商业合作</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>bd@token.im</a></div>
              <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #777B8B;line-height: 16px;
              text-align: left;font-style: normal;text-transform: none;margin-top: 10px;"><a>媒体及商务合作联系</a></div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;">
        <div style="width: 1150px;display: flex;align-items: center;justify-content: space-between;">
          <div>
            <div>
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
            </div>
            <van-button type="primary"
                        color="#468BE0"
                        style="width: 137px;height: 47px;margin-top: 20px;"
                        to="download">
              <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
            </van-button>
          </div>
          <div style="margin-right: 10px">
            <van-image src="img/pc/home/pc_dt4.svg"></van-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "homePcIndex"
}
</script>
<style>
</style>