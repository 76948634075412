<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1000px;display: flex;align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">公链</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;color: #787A8B;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">下载 imToken 钱包。多链多网络，访问你的链上代币</a>
        <van-button style="width: 137px;height: 47px;margin-top: 20px;"
                    color="#468bdf"
                    type="primary"
                    to="download"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
      </div>
      <van-image style="margin-top: 35px;margin-left: 105px;"
                 src="img/pc/walletMenupc/public_chains_banner.svg"></van-image>
    </div>
    <div style="width: 1000px;display: flex;margin-top: 60px;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">公链</a>
        <div style="margin-top: 20px;width: 208px;">
          <div style="display: flex;align-items: center;cursor: pointer;"
               @click="goEthereumWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt1.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">ETH</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goBitcoinWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt2.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BTC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goEosWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt3.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">EOS</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goCosmosWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt4.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">ATOM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goTrxWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt5.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">TRX</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goLtcWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt6.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">LTC</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goBchWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt7.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">BCH</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goCkbWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt8.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">CKB</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goPolkadotWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt9.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">DOT</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="gokusamaWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt10.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">KSM</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;"
               @click="goFilWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt11.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">FIL</a>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;cursor: pointer;background: #EDF3FC;border-radius: 7px 7px 7px 7px;"
               @click="goXtzWallet">
            <van-image style="width: 32px;height: 32px;margin-left: 12px;"
                       src="img/pc/walletMenupc/walletMenu_pc_xt12.png"></van-image>
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #787A8B;
            line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-left: 5px;">XTZ</a>
          </div>
        </div>
      </div>
      <div style="border: 1px solid #EDEFEF;height: 620px;width: 0px;"></div>
      <div style="margin-left: 48px;display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">XTZ（Tezos）钱包</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">入门</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">Tezos 简介</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #787A8B;margin-top: 15px;
        line-height: 26px;text-align: left;font-style: normal;text-transform: none;display: flex;flex-direction: column;">
          <a>Tezos 是新一代的区块链技术，它允许持币者通过投票的方式来进行技术升级与迭代。项目中的绝大多数代码与算法都可以进行自我修正，从而很大程度上避免了传统区块链升级系统时出现的硬分叉问题。</a>
          <a style="margin-top: 9px;">Filecoin 不等同于 IPFS，它们是两个独立的补充协议，均由 Protocol Labs 创建。IPFS（星际文件系统）是一种点对点的分布式文件系统，旨在将所有计算设备连接到相同的文件系统。</a>
          <a style="margin-top: 9px;">Tezos 也引进了非常先进的形式化验证技术，这项技术极大程度上减少了智能合约编写过程中可能出现的漏洞。同时更先进的 LPoS 挖矿算法允许持币者通过委托或自我运行节点的方式来维护区块链安全，以及获得奖励。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">Tezos 代币情况</a>
          <a>Tezos 的代币称号为 XTZ，符号为ꜩ，总量约 7.6 亿。其中 20% 留给基金会和团队，其余 80% 用于 ICO。Tezos ICO 采取不设硬顶的方式，XTZ 的总量和投资总额有一定的关系，最终投资额锁定在 2.3 亿美元，由此得出的 XTZ 总量。</a>
          <a>XTZ 年增发约 5.5%，增发的奖励全部分发给 Tezos 的验证人。目前，超过 70% 的 XTZ 用于 Stake，Stake 可以抵消增发造成的稀释，在目前并没有太多应用的场景下，非稀释性通胀给系统带来的安全程度是很高的。</a>
          <a>XTZ 的年增发不会长期停留在 5.5%，白皮书中有提到过增发率会无限趋近于 1%，减少增发率是一个线性过程，这有助于合理平衡系统。Tezos 认为，过于大的通货膨胀不利于系统发展。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 15px;">Tezos 钱包</a>
          <a style="margin-top: 9px;">钱包是一种应用程序，可以便捷地保存和传送 XTZ，同时可以通过钱包与 Tezos 网络上构建的应用程序进行交互。</a>
          <a style="margin-top: 9px;">🔸 钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">
              <a style="color: #1B8FFF;cursor: pointer"
                 @click="goToHome">移动端钱包 imToken</a> - 为全球千万用户提供非托管钱包服务</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">
              <a style="color: #1B8FFF;"
                 href="https://docs.filecoin.io/get-started/lotus/send-and-receive-fil/#creating-a-wallet"
                 target="_blank">Lotus Wallet</a> - 可以接收和发送 FIL 的网页版钱包</a>
          </div>
          <a style="margin-top: 9px;">🔸 创建钱包之前，请学习如何备份钱包</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">
              什么是助记词？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">
              什么是明文私钥？</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;cursor: pointer"
               class="scrollToTopLink">
              如何备份助记词？</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">XTZ 买卖</a>
          <a style="margin-top: 9px;">获取 Tezos 最简单的方式就是购买。 市面上有许多可以购买 Tezos 的数字货币交易所，但用户需要根据所处地址和付款方式选择合适的交易所。</a>
          <div style="margin-top: 9px;">
            <a>在购买 Tezos 之前，尝试在<a style="color: #1B8FFF;cursor: pointer"
                 href="https://www.zhihu.com/question/267270670">这里</a>了解购买方式 & 风险等信息，便于你更安全快捷的完成购买。</a>
          </div>
          <a style="margin-top: 9px;">🔸 支持购买 XTZ 的交易所</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Coinbase</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Binance</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Huobi</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">...</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">使用</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">XTZ 交易</a>
          <a style="margin-top: 9px;">你也许希望通过代币兑换获得 Tezos。市面上大多交易所都支持 Tezos 交易，你可以根据需要选择去中心化交易所或中心化交易所。</a>
          <div style="margin-top: 9px;"><a>🔸 中心化交易所</a></div>
          <a style="margin-top: 9px;">由交易所托管用户代币 ，提供数字货币流动性供应，具有学习成本低、撮合效率高等特点。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Binance</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">Huobi</a>
          </div>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;">...</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">Tezos 共识机制</a>
          <a style="margin-top: 9px;">Tezos 采用了 Liquid 股权证明机制（LPoS），此共识算法的目的是维护一个模块化系统，该系统可以无缝地促进有效的链上治理。同样，最终目标是在不分裂社区的情况下维护网络并解决争端。</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">Tezos 区块浏览器</a>
          <a style="margin-top: 9px;">Tezos 区块浏览器是一个查询区块、交易、钱包等信息的网站，实时同步更新 Tezos 网络所有节点信息。</a>
          <div style="display: flex;align-items: center;margin-left: 30px;margin-top: 10px;">
            <div style="width: 5px;height: 5px;background: #BFC5CD;border-radius: 50%;"></div><a style="margin-left: 10px;color: #1B8FFF;"
               href="https://tzstats.com/">Tezos 区块浏览器</a>
          </div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 28px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 40px;">学习</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;margin-top: 25px;">Tezos 项目背景</a>
          <a style="margin-top: 9px;">Tezos（XTZ）是计算机科学家 Arthur Breitman 创立起来的。</a>
          <a style="margin-top: 9px;">与中本聪一样，Breitman 以笔名出版了 Tezos 白皮书，他在 2014 年将其称为“ LM Goodman”。随后，他创立了 Dynamic Ledger Solutions，并将 Tezos 源代码的知识产权所有权转让给了公司。</a>
          <a style="margin-top: 9px;">Breitman 和他的妻子凯瑟琳·布雷特曼（Kathleen Breitman）试图为 Tezos 的发展筹集资金。在未能吸引私人投资者之后，他们决定改为发行 ICO。</a>
          <a style="margin-top: 9px;">2017 年 7 月，这对夫妇筹集了 2.32 亿美元，这是当时最大的 ICO 之一。随后，他们成立了Tezos 基金会，并任命了 Crypto Valley 生态系统的创始人 John Gevers 担任总裁。</a>
          <a style="margin-top: 9px;">不幸的是，Breitman 和 Gevers 陷入了困境，最终导致了 SEC 的集体诉讼，Gevers 退出了Tezos 基金会。</a>
          <a style="margin-top: 9px;">Tezos 是迄今为止最成功的加密项目之一，区块链平台能够承受 2018 年的加密货币冬季并表现强劲。在 2019 年 10 月至 2020 年 2 月期间，XTZ 的价格也上涨了两倍多。</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "xtzWallet",
  methods: {
    goEthereumWallet () {
      this.$router.push({ path: '/ethereum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBitcoinWallet () {
      this.$router.push({ path: '/bitcoin-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goEosWallet () {
      this.$router.push({ path: '/eos-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goCosmosWallet () {
      this.$router.push({ path: '/cosmos-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goTrxWallet () {
      this.$router.push({ path: '/trx-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goLtcWallet () {
      this.$router.push({ path: '/ltc-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBchWallet () {
      this.$router.push({ path: '/bch-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goCkbWallet () {
      this.$router.push({ path: '/ckb-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goPolkadotWallet () {
      this.$router.push({ path: '/polkadot-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    gokusamaWallet () {
      this.$router.push({ path: '/kusama-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goFilWallet () {
      this.$router.push({ path: '/fil-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goXtzWallet () {
      this.$router.push({ path: '/xtz-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goToHome () {
      this.$router.push({ path: '/' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
  }
}
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    // 检查点击的元素是否是具有类名"scrollToTopLink"的链接
    if (event.target.classList.contains('scrollToTopLink')) {
      event.preventDefault(); // 阻止默认行为，即阻止链接的跳转
      window.scrollTo(0, 0); // 将页面滚动到顶部
    }
  });
});
</script>

<style>
</style>