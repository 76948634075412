<template>
  <!--<HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div id="app1">
    <div style="background-color: #ffffff;height: 80px;max-width: 100%;box-sizing: border-box; display: flex; justify-content: center; 
  align-items: center;position: fixed;top: 0; width: 100vw; z-index: 999;">
      <div style="display: flex; width: 100%; padding: 0 14px;">
        <div style="flex: 1;">
          <van-image @click="goToHome"
                     src="img/pc/imTokenLogo.svg"></van-image>
        </div>
        <div style="flex: 1; text-align: right;">
          <van-image @click="togglePopup"
                     v-if="showSantiaogangImg"
                     src="img/santiaogang.png"></van-image>
          <van-image @click="closePopup"
                     v-if="showCloseImg"
                     src="img/close.png"></van-image>
        </div>
      </div>
    </div>
    <div v-if="showPopup">
      <!-- 这里放置弹窗内容 -->
      <div style="margin-top: 80px;max-width: 100%;">
        <div style="width: 100%;height: 284px;padding: 0 25px;display: flex; flex-direction: column; justify-content: space-between;">
          <div @click="goApp_Downloads">
            <van-button style="width: 100%;height: 56px;"
                        color="#468bdf"
                        type="primary"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">立即下载</a></van-button>
          </div>
          <div @click="goSecurityIndex">
            <van-button style="width: 100%;height: 56px;"
                        color="#ecf3fc"
                        type="primary"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #6b8be4;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">安全中心</a></van-button>
          </div>
          <div @click="goSupportIndex">
            <van-button style="width: 100%;height: 56px;"
                        color="#ecf3fc"
                        type="primary"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #6b8be4;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">帮助中心</a></van-button>
          </div>
          <div @click="goBlogIndex">
            <van-button style="width: 100%;height: 56px;"
                        color="#ecf3fc"
                        type="primary"><a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #6b8be4;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;">博客</a></van-button>
          </div>
        </div>
        <van-collapse v-model="activeNames"
                      style="margin-top: 20px;">
          <van-collapse-item name="1"
                             size="large">
            <template v-slot:title>
              <div style="display: flex; align-items: center;">功能 <van-image style="margin-left: 5px;"
                           src="img/xin.png"></van-image></div>
            </template>
            <div style="margin-left: 33px;">
              <p @click="goWalletIndex">多链钱包</p>
              <div>
                <a href="https://shop42404838.m.youzan.com/v2/showcase/homepage?alias=4aFwAdfHSM"
                   style="width: 21px;color: #969799">硬件钱包</a>
              </div>
              <div style="margin-top: 14px;">
                <a href="https://mest.io/?code=IMTOKEN"
                   style="width: 21px;color: #969799">观察钱包</a>
              </div>
              <p @click="goDappIndex">DApp 浏览器</p>
              <p>信用卡</p>
            </div>
          </van-collapse-item>
          <van-collapse-item title="多链"
                             name="2"
                             size="large">
            <div style="margin-left: 33px;">
              <p @click="goWalletMenuIndex">公链</p>
              <p @click="goWalletMenuLayer2Index">Layer2</p>
              <p @click="goWalletMenuEvmIndex">EVM 兼容链</p>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div style="margin-top: 15px;">
          <a style="margin-left: 15px;font-size: 15px;line-height: 20px;
          text-align: left;font-style: normal;text-transform: none;"
             @click="goStakingIndex">质押</a>
        </div>
        <van-collapse v-model="activeNames2"
                      style="margin-top: 20px;"
                      size="large">
          <van-collapse-item title="公司"
                             name="3">
            <div style="margin-left: 33px;">
              <p @click="goAboutIndex">关于我们</p>
              <div>
                <a href="https://careers.token.im/cn/home"
                   style="width: 21px;color: #969799">加入我们</a>
              </div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="资源"
                             name="4"
                             size="large">
            <div style="margin-left: 33px;">
              <p @click="goDocsIndex">API</p>
              <div>
                <a href="https://github.com/consenlabs/token-profile"
                   style="width: 21px;color: #969799">提交代币</a>
              </div>
              <p @click="goBrandIndex">品牌</p>
            </div>
          </van-collapse-item>
          <van-collapse-item title="帮助中心"
                             name="5"
                             size="large">
            <div style="margin-left: 33px;">
              <p @click="goSupportIndex">帮助中心</p>
              <p @click="goContactIndex">联系我们</p>
              <p @click="goFaqIndex">常见问题</p>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div v-if="showView">
      <router-view></router-view>
    </div>
  </div>
  <div id="app2">
    <div style="max-width: 100%;display: flex; justify-content: center; align-items: center;flex-direction: column;">
      <div style="width: 100%;display: flex; justify-content: center;padding: 14px;background-color: #fff7e5;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;line-height: 30px;
        text-align: center;font-style: normal;text-transform: none;">为匹配用户属地国家的监管政策，imToken 已限制中国境内用户访问和使用部分功能</a>
      </div>
      <div style="width: 1165px;height: 80px;padding: 0 40px;display: flex;align-items: center;">
        <div style="display: flex;align-items: center;justify-content: space-between;width: 1019px;font-family: PingFang HK, PingFang HK;font-weight: 400;
        font-size: 14px;color: #787A8B;line-height: 30px;text-align: center;font-style: normal;text-transform: none;margin-left: 68px;">
          <van-image @click="goToHome"
                     style="cursor: pointer;object-fit: contain;margin-right: 68px;"
                     src="img/pc/imTokenLogo.svg"></van-image>
          <div style="margin-right: auto;display: flex;align-items: center;">
            <div class="dropdown">
              <div style="display: flex;align-items: center;width: 64px;cursor: pointer">
                <a>功能</a>
                <van-image style="margin-left: 5px;"
                           class="rotate-on-hover"
                           src="img/pc/fanzhuan.png"></van-image>
              </div>
              <div class="dropdown-content">
                <div style="display: flex;cursor: pointer"
                     @click="goWalletIndex">
                  <div style="margin-left: 10px;margin-top: 8px;">
                    <van-image src="img/pc/xl1.png"></van-image>
                  </div>
                  <div style="margin-left: 20px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">多链钱包</a>
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">轻松访问和使用你的链上代币</a>
                  </div>
                </div>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
                <a style="cursor: pointer"
                   href="https://shop42404838.m.youzan.com/v2/feature/d5bMdxB3Ab?dc_ps=3252957778326674432.200001?locale=zh-CN">
                  <div style="display: flex;">
                    <div style="margin-left: 10px;margin-top: 8px;">
                      <van-image src="img/pc/xl2.png"></van-image>
                    </div>
                    <div style="margin-left: 20px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">硬件钱包</a>
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">属于你的第一个硬件钱包</a>
                    </div>
                  </div>
                </a>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
                <a style="cursor: pointer"
                   href="https://mest.io/?code=IMTOKEN?locale=zh-CN">
                  <div style="display: flex;">
                    <div style="margin-left: 10px;margin-top: 8px;">
                      <van-image src="img/pc/xl3.png"></van-image>
                    </div>
                    <div style="margin-left: 20px;">
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">观察钱包</a>
                      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">管理多钱包和追踪Smart Money</a>
                    </div>
                  </div>
                </a>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
                <div style="display: flex;cursor: pointer"
                     @click="goDappIndex">
                  <div style="margin-left: 10px;margin-top: 8px;">
                    <van-image src="img/pc/xl4.png"></van-image>
                  </div>
                  <div style="margin-left: 20px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">DApp浏览器</a>
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">支持ETH，EOS，TRON应用</a>
                  </div>
                </div>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
                <div style="display: flex;">
                  <div style="margin-left: 10px;margin-top: 8px;">
                    <van-image src="img/pc/xl5.png"></van-image>
                  </div>
                  <div style="margin-left: 20px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">信用卡</a>
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">imToken Card是由DCS发行的联名卡</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown2">
              <div style="display: flex;align-items: center;width: 64px;margin-left: 50px;cursor: pointer">
                <a>多链</a>
                <van-image style="margin-left: 5px;"
                           class="rotate-on-hover2"
                           src="img/pc/fanzhuan.png"></van-image>
              </div>
              <div class="dropdown-content2">
                <div style="display: flex;cursor: pointer"
                     @click="goWalletMenuIndex">
                  <div style="margin-left: 10px;margin-top: 8px;">
                    <van-image src="img/pc/xl6.png"></van-image>
                  </div>
                  <div style="margin-left: 20px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">公链</a>
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">使用钱包便捷访问你的链上代币</a>
                  </div>
                </div>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
                <div style="display: flex;cursor: pointer"
                     @click="goWalletMenuLayer2Index">
                  <div style="margin-left: 10px;margin-top: 8px;">
                    <van-image src="img/pc/xl7.png"></van-image>
                  </div>
                  <div style="margin-left: 20px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Layer2</a>
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">享受更高速交易体验</a>
                  </div>
                </div>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
                <div style="display: flex;cursor: pointer"
                     @click="goWalletMenuEvmIndex">
                  <div style="margin-left: 10px;margin-top: 8px;">
                    <van-image src="img/pc/xl8.png"></van-image>
                  </div>
                  <div style="margin-left: 20px;">
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 16px;color: #43454F;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">EVM兼容链</a>
                    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 15px;color: #7E8090;
                    line-height: 38px;text-align: left;font-style: normal;text-transform: none;">体验多元化以太坊生态交互选择</a>
                  </div>
                </div>
                <hr style="width: 301px;height: 0px;border: 1px solid #E0E5E9;">
              </div>
            </div>
            <div style="display: flex;align-items: center;width: 64px;margin-left: 50px;cursor: pointer"
                 @click="goStakingIndex">
              <a>质押</a>
            </div>
          </div>
          <div style="margin-left: auto;display: flex;align-items: center;">
            <div @click="goBlogIndex"
                 style="cursor: pointer"><a>博客</a></div>
            <div @click="goSupportIndex"
                 style="margin-left: 50px;cursor: pointer"><a>帮助中心</a></div>
            <div style="margin-left: 50px;">
              <van-button type="primary"
                          color="#2A80EA"
                          style="width: 88px;height: 34px;"
                          to="download">
                <a style="font-family: Inter, Inter;font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 16px;
                text-align: left;font-style: normal;text-transform: none;">立即下载</a>
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showView">
      <router-view></router-view>
    </div>
    <div style="width: 100%;margin-top: 40px;display: flex; justify-content: center; align-items: center;">
      <div style="margin-top: 66px;height: 300px;width: 1100px;display: flex;">
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;">
          <div style="margin-bottom: 14px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">功能</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goWalletIndex">
            <a>多链钱包</a>
          </div>
          <a style="color: #787A8B;cursor: pointer"
             href="https://shop42404838.m.youzan.com/v2/feature/d5bMdxB3Ab?dc_ps=3252957778326674432.200001?locale=zh-CN">
            <div style="margin-bottom: 14px;">
              <a>硬体钱包</a>
            </div>
          </a>
          <a style="color: #787A8B;cursor: pointer"
             href="https://mest.io/?code=IMTOKEN?locale=zh-CN">
            <div style="margin-bottom: 14px;">
              <a>观察钱包</a>
            </div>
          </a>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goDappIndex">
            <a>DApp浏览器</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goStakingIndex">
            <a>质押</a>
          </div>
          <div style="margin-bottom: 14px;">
            <a>信用卡</a>
          </div>
        </div>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;margin-left: 180px;">
          <div style="margin-bottom: 14px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">多链</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goBitcoinWallet">
            <a>比特币</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goEthereumWallet">
            <a>以太坊</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goCosmosWallet">
            <a>宇宙</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goEosWallet">
            <a>EOS</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goTrxWallet">
            <a>TRX</a>
          </div>
          <div style="margin-bottom: 14px;">
            <a>更多的</a>
          </div>
        </div>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;margin-left: 180px;">
          <div style="margin-bottom: 14px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">公司</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goAboutIndex">
            <a>关于我们</a>
          </div>
          <a href="https://careers.token.im/cn/home"
             style="color: #787A8B">
            <div style="margin-bottom: 14px;">
              <a>加入我们</a>
            </div>
          </a>
        </div>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;margin-left: 180px;">
          <div style="margin-bottom: 14px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">资源</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goDocsIndex">
            <a>API</a>
          </div>
          <a style="color: #787A8B;cursor: pointer"
             href="https://mest.io/?code=IMTOKEN?locale=zh-CN">
            <div style="margin-bottom: 14px;">
              <a>提交代币</a>
            </div>
          </a>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goBrandIndex">
            <a>品牌</a>
          </div>
        </div>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #787A8B;line-height: 16px;
        text-align: left;font-style: normal;text-transform: none;margin-left: 180px;">
          <div style="margin-bottom: 14px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 600;font-size: 14px;color: #787A8B;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">用户支援</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goSecurityIndex">
            <a>安全中心</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goSupportIndex">
            <a>帮助中心</a>
          </div>
          <div style="margin-bottom: 14px;cursor: pointer"
               @click="goFaqIndex">
            <a>常见问题</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
export default {
  data () {
    return {
      showSantiaogangImg: true,
      showView: true,
      showCloseImg: false,
      showPopup: false,
      activeNames: ['1'],
      activeNames2: ['1']
    };
  },
  methods: {
    goToHome () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goApp_Downloads () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/download' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goSecurityIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/security' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goWalletIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goSupportIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/support' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goDappIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/dapp' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goStakingIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/staking' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBlogIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/blog' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goAboutIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/about' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goFaqIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/faq' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goWalletMenuIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/public-chain-wallet-menu' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goDocsIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/docs' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBrandIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/brand' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goWalletMenuLayer2Index () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/layer2-wallet-menu' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goWalletMenuEvmIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/evm-chain-wallet-menu' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goContactIndex () {
      // 在这里实现页面跳转到首页的逻辑
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/contact' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goBitcoinWallet () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/bitcoin-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goEthereumWallet () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/ethereum-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goCosmosWallet () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/cosmos-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goEosWallet () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/eos-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    goTrxWallet () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      this.$router.push({ path: '/trx-wallet' }); // 假设使用了 Vue Router，并且首页的路径为 '/'
      window.scrollTo(0, 0);
    },
    togglePopup () {
      this.showSantiaogangImg = false;
      this.showCloseImg = true;
      this.showPopup = true;
      this.showView = false;
      // 处理弹窗显示的额外逻辑
    },
    closePopup () {
      this.showSantiaogangImg = true;
      this.showCloseImg = false;
      this.showPopup = false;
      this.showView = true;
      // 处理关闭弹窗的额外逻辑
    }
  },
}
function isMobileDevice () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

function toggleDivDisplay () {
  if (isMobileDevice()) {
    document.getElementById('app1').style.display = 'block'
    document.getElementById('app2').style.display = 'none'
  } else {
    document.getElementById('app1').style.display = 'none'
    document.getElementById('app2').style.display = 'block'
  }
}

window.onresize = toggleDivDisplay
window.onload = toggleDivDisplay
</script>

<style>
/* 下拉菜单容器 */
.dropdown {
  position: relative;
  display: inline-block;
}

/* 下拉内容隐藏 */
.dropdown-content {
  display: none;
  position: absolute;
  background: #ffffff;
  width: 404px;
  height: 484px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
  padding: 10px;
}

/* 下拉内容显示 */
.dropdown:hover .dropdown-content {
  display: block;
}

/* 下拉菜单链接样式 */
.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

/* 鼠标悬停时链接背景色 */
.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .rotate-on-hover {
  transform: rotate(180deg);
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

/* 下拉内容隐藏 */
.dropdown-content2 {
  display: none;
  position: absolute;
  background: #ffffff;
  width: 404px;
  height: 301px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
  padding: 10px;
}

/* 下拉内容显示 */
.dropdown2:hover .dropdown-content2 {
  display: block;
}

/* 下拉菜单链接样式 */
.dropdown-content2 a {
  color: black;
  text-decoration: none;
  display: block;
}

/* 鼠标悬停时链接背景色 */
.dropdown-content2 div:hover {
  background-color: #f1f1f1;
}

.dropdown2:hover .rotate-on-hover2 {
  transform: rotate(180deg);
}
</style>
