<template>
  <div style="margin-top: 80px;max-width: 100%;">
    <div style="width: 100%;display: flex; flex-direction: column; align-items: center;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #000000;
      line-height: 24px;text-align: center;font-style: normal;text-transform: none;">联系我们</a>
      <div style="margin-top: 46px;width: 90%;display: flex; flex-direction: column; align-items: center;">
        <div style="width: 100%;height: 209px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;border: 1px solid #E5EAED;
        display: flex; flex-direction: column; align-items: center;">
          <van-image style="margin-top: 14px;" src="img/contact/contact_dt.svg"></van-image>
          <div style="margin-top: 5px;font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 12px;color: #787A8B;
          line-height: 24px;text-align: center;font-style: normal;text-transform: none;">
            <a>使用 imToken 产品遇到问题，请通过帮助中心提交</a>
            <br>
            <a>反馈，我们的用户支持团队会尽快回复你。</a>
          </div>
          <van-button class="buttonPc" id="installButtonPc" type="primary" color="#2A80EA" style="margin-top: 20px;width: 185px;height: 45px;">
              <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #FFFFFF;
              line-height: 24px;text-align: center;font-style: normal;text-transform: none;">用户支持</a>
          </van-button>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%;display: flex; flex-direction: column; align-items: center;margin-top: 40px;">
    <div style="width: 90%;">
      <p style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #43454F;
      line-height: 38px;text-align: left;font-style: normal;text-transform: none;">Email</p>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #11293B;
      line-height: 24px;text-align: left;font-style: normal;text-transform: none;">商务合作：</a>
      <br>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #098DE6;
      line-height: 24px;text-align: left;font-style: normal;text-transform: none;">bd@token.im</a>
      <div style="margin-top: 31px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #11293B;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">用户支持：</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #098DE6;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">support@token.im</a>
      </div>
      <div style="margin-top: 31px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #11293B;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">加入我们：</a>
        <br>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #098DE6;
        line-height: 24px;text-align: left;font-style: normal;text-transform: none;">hr@token.im</a>
      </div>
    </div>
  </div>
  <br>
  <br>
</template>

<script>
export default {
  name: "contactIndex",
}
</script>

<style>

</style>