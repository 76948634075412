<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1100px;margin-top: 50px;height: 438px;background: #FAFAFA;border-radius: 24px 24px 24px 24px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 27px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">关于我们</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">区块链技术创新的践行者与挑战者</a>
      </div>
      <van-image style="margin-top: 35px;margin-left: 125px;"
                 src="img/pc/about/about_dt.png"></van-image>
    </div>
    <div style="background: #0C7FB9;height: 430px;width: 100%;margin-top: 50px;display: flex; justify-content: center; align-items: center;flex-direction: column;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">imToken</a>
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 18px;color: #FFFFFF;
      line-height: 22px;text-align: left;font-style: normal;text-transform: none;margin-top: 16px;">为全球超过 150 个国家的千万用户提供安全、可信赖的非托管钱包服务</a>
      <div style="display: flex; align-items: center;margin-top: 43px;">
        <div style="background: #308CC0;border-radius: 11px 11px 11px 11px;border: 1px solid #60A4CD;width: 377px;height: 180px;display: flex;flex-direction: column;justify-content: center;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">7周年</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #9EC5E0;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">始于2016年</a>
        </div>
        <div style="background: #308CC0;border-radius: 11px 11px 11px 11px;border: 1px solid #60A4CD;width: 377px;height: 180px;
        display: flex;flex-direction: column;justify-content: center;margin-left: 10px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">1500万+</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #9EC5E0;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">用户正在使用</a>
        </div>
        <div style="background: #308CC0;border-radius: 11px 11px 11px 11px;border: 1px solid #60A4CD;width: 377px;height: 180px;
        display: flex;flex-direction: column;justify-content: center;margin-left: 10px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #FFFFFF;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;">150+</a>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 18px;color: #9EC5E0;
          line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 5px;">覆盖国家和地区</a>
        </div>
      </div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 70px;">关于公司</a>
    <div style="display: flex; align-items: center;margin-top: 43px;">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <van-image style="margin-top: 40px;"
                   src="img/pc/about/about_xt1.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #43444F;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">愿景</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #9EA7AE;line-height: 24px;
        text-align: center;font-style: normal;text-transform: none;">
          <a>致力于打造最为安全放心、简单易</a>
          <br>
          <a>用的数字钱包，让每个人平等自由</a>
          <br>
          <a>地享受有意义的数字生活</a>
        </div>
      </div>
      <div style="display: flex;flex-direction: column;align-items: center;margin-left: 300px;">
        <van-image style="margin-top: 40px;"
                   src="img/pc/about/about_xt1.png"></van-image>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #43444F;
        line-height: 36px;text-align: left;font-style: normal;text-transform: none;">价值观</a>
        <div style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 17px;color: #9EA7AE;line-height: 24px;
        text-align: center;font-style: normal;text-transform: none;">
          <a>因为坚信，所以坚持，我们致力于</a>
          <br>
          <a>成为：简单质朴的追随者、良好意</a>
          <br>
          <a>愿的先行者、坚韧不拔的挑战者</a>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center;margin-top: 130px;">
      <van-image src="img/pc/about/about_dt2.png"></van-image>
      <van-image style="margin-left: 50px;"
                 src="img/pc/about/about_dt3.png"></van-image>
    </div>
    <div style="background: #F6F9FB;height: 630px;display: flex; justify-content: center; align-items: center;flex-direction: column;width: 100%;margin-top: 100px;">
      <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
      line-height: 38px;text-align: center;font-style: normal;text-transform: none;">大事记</a>
      <van-image style="margin-top: 50px;"
                 src="img/pc/about/about_dt4.png"></van-image>
    </div>
    <div style="display: flex; align-items: center;margin-top: 110px;">
      <div>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 32px;color: #1A1C1E;line-height: 36px;
        text-align: left;font-style: normal;text-transform: none;">加入我们</a>
        <a href="https://careers.token.im/cn/home"
           target="_blank">
          <div style="width: 137px;height: 47px;border-radius: 6px 6px 6px 6px;border: 1px solid #000000;display: flex;align-items: center;justify-content: center;margin-top: 24px;">
            <span style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #43454F;
              line-height: 20px;text-align: left;font-style: normal;text-transform: none;">立即查看职位</span>
          </div>
        </a>
      </div>
      <van-image style="margin-left: 210px;"
                 src="img/pc/about/about_dt5.png"></van-image>
    </div>
  </div>
  <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 44px;">
    <div style="width: 1100px;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
        </div>
        <van-button type="primary"
                    color="#468BE0"
                    style="width: 137px;height: 47px;margin-top: 20px;"
                    to="download">
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
        </van-button>
      </div>
      <div style="margin-right: 10px">
        <van-image src="img/pc/home/pc_dt4.svg"></van-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutPcIndex",
}
</script>

<style>
</style>