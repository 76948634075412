<template>
  <div style="max-width: 100%;box-sizing: border-box;display: flex; justify-content: center; align-items: center;flex-direction: column;">
    <div style="width: 1100px;margin-top: 50px;height: 438px;background: #F5FAFF;border-radius: 24px 24px 24px 24px;display: flex; justify-content: center; align-items: center;">
      <div style="display: flex;flex-direction: column;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #43454F;
        line-height: 38px;text-align: left;font-style: normal;text-transform: none;">安全中心</a>
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 22px;color: #787A8B;
        line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 17px;">十分钟降低 90% 资产被盗风险</a>
      </div>
      <van-image style="margin-top: 35px;margin-left: 125px;"
                 src="img/pc/security/security_dt.svg"></van-image>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #1B1C1F;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 100px;">市场运营活动素材</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
    line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">手把手带你自查和排除风险</a>
    <div style="width: 996px;height: 114px;background: #FAFAFA;border: 1px solid #EEEFF0;margin-top: 50px;display: flex; justify-content: space-between;align-items: center;padding: 0 15px;border-radius: 7px;">
      <div style="margin-left: 23px;">
        <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #1B1C1F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">下载了假imToken怎么办？</a>
        <div style="width: 600px;margin-top: 10px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">通过非官网渠道下载imToken均存在安全隐患，将面临资产被盗的风险。</a>
        </div>
      </div>
      <div style="margin-right: 30px;"><a style="font-size: 15px;font-weight: bold;width: 7px;height: 12px;color: #1891FF;">></a></div>
    </div>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 32px;color: #1B1C1F;
    line-height: 38px;text-align: center;font-style: normal;text-transform: none;margin-top: 100px;">联系我们</a>
    <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
    line-height: 30px;text-align: left;font-style: normal;text-transform: none;margin-top: 10px;">遇到问题？快速联系我们</a>
    <div style="width: 996px;height: 114px;background: #FAFAFA;border: 1px solid #EEEFF0;margin-top: 50px;display: flex; justify-content: space-between;align-items: center;padding: 0 15px;border-radius: 7px;">
      <div style="margin-left: 23px;display: flex; justify-content: space-between;align-items: center;">
        <van-image src="img/pc/security/security_xt1.png"></van-image>
        <div style="margin-left: 23px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 500;font-size: 20px;color: #1B1C1F;
        line-height: 38px;text-align: center;font-style: normal;text-transform: none;">邮件反馈</a>
          <div style="width: 600px;margin-top: 10px;">
            <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 16px;color: #767B8A;
          line-height: 30px;text-align: left;font-style: normal;text-transform: none;">预计在24小时内回复，在线时间：10：00-18：00</a>
          </div>
        </div>
      </div>
      <div style="margin-right: 30px;">
        <van-button type="primary"
                    color="#468BDF"
                    style="width: 149px;height: 46px;">
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 14px;color: #FFFFFF;
          line-height: 16px;text-align: left;font-style: normal;text-transform: none;">邮件反馈</a>
        </van-button>
      </div>
    </div>
  </div>
  <div style="width: 100%;height: 200px;background: #F6F9FB;display: flex; justify-content: center; align-items: center;flex-direction: column;margin-top: 130px;">
    <div style="width: 1100px;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <div>
          <a style="font-family: PingFang HK, PingFang HK;font-weight: 400;font-size: 24px;color: #787A8B;
          line-height: 36px;text-align: left;font-style: normal;text-transform: none;">准备好探索imToken了吗？</a>
        </div>
        <van-button type="primary"
                    color="#468BE0"
                    style="width: 137px;height: 47px;margin-top: 20px;"
                    to="download">
          <a style="font-family: Inter, Inter;font-weight: bold;font-size: 17px;color: #FFFFFF;line-height: 20px;text-align: left;
                font-style: normal;text-transform: none;">马上体验</a>
        </van-button>
      </div>
      <div style="margin-right: 10px">
        <van-image src="img/pc/home/pc_dt4.svg"></van-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "securityPcIndex",
}
</script>

<style>
</style>